import React, { Component, Fragment } from 'react';
//import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import { _ } from '../../controllers/languageController';
import ApiController, { callbackWrapper } from '../../controllers/apiController';
import NotificationController from '../../controllers/notificationController';
import AuthenticationController from '../../controllers/authenticationController';
import LoadingItemComponent from '../_components/loadingItemComponent';
import ViewAccountComponent from '../_components/admin/viewAccountComponent';
import UserSearchComponent from '../_components/admin/userSearchComponent';
import SystemStatsComponent from '../_components/admin/systemStatsComponent';
import ListProfilesComponent from '../_components/admin/listProfilesComponent';
import CenteredComponent from '../_components/centeredComponent';
import swal from 'sweetalert';  // https://sweetalert.js.org/guides/
//import { Redirect } from 'react-router-dom';

export default class AdminIndexPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            personList: null,
            pharmacyList: null,
            selectedPersonAccountId: null,
            selectedPharmacyAccountId: null
        };
    }

/*     
    // default values for possibly missing props
    static defaultProps = {
        userType: "person",  // type of user to search for (person or pharmacy) - default to person
        mostRecentDays: 14 // count of days which qualify for initial most recent user list
    };
 */

    // CS: make sure user is really logged on as admin
    componentDidMount() {

        // if not admin - bail out and redirect back to root
        if (!AuthenticationController.isAdmin()) this.props.history.push("/");

        // need to set loading to false or infinite waiting
        this.setState({ loading: false});
    }

    /* 
        initiates the deletion of an account (person or pharmacy) 
        by asking for confirmation first 
    */
    deleteAccount = (e, accountId) =>
    {
        e.preventDefault();
        //console.log("Id =" + accountId);

        swal("Sind sie sicher?", {
            icon: 'warning',
            title: 'Account Löschen',
            buttons: ["Abbruch", "Ja, löschen!"],
            dangerMode: true  /* sets focus on cancel */
        })
          .then((value) => {
            //console.log("swal value = " + value);
            if ( value === true)
            {
                /* swal("Der Eintrag wurde erfolgreich gelöscht!", {icon: "success",}); */
                this.confirmedDeleteAccount(accountId);
            }
          });
    }

    /* 
        after user confirmed this routine will physically delete the account (person or pharmacy)
    */
   confirmedDeleteAccount = (accountId) =>
    {
        ApiController.adminDeleteAccount(accountId, callbackWrapper((response) => {

            if (response.data.error) {
                NotificationController.pushError(_(response.data.error));
                return;
            }

            /* reload data for this page again */
            this.loadData();

            NotificationController.pushSuccess(_("success.someAccountDeleted"));

            this.props.history.push("/");
        }).bind(this));
    }

    /*
        displays (readonly) the account details of the supplied person
    */
    onClickViewPersonAccount = (e, accountId) =>
    {
        e.preventDefault();
        this.setState({selectedPersonAccountId: accountId, selectedPharmacyAccountId:null });
        return false;
    }
    /*
        displays (readonly) the account details of the supplied person
    */
    onClickViewPharmacyAccount = (e, accountId) =>
    {
        e.preventDefault();
        this.setState({selectedPersonAccountId: null, selectedPharmacyAccountId:accountId });
        return false;
    }

    showSelectedAccountDetails = () => 
    {
        if ( this.state.selectedPersonAccountId !== null)
        {
            //return (<h1>Person selected</h1>);
            return (<ViewAccountComponent key={this.state.selectedPersonAccountId} isPerson={true} accountID={this.state.selectedPersonAccountId} />);
        }
        else if (this.state.selectedPharmacyAccountId !== null)
        {
            //return (<h1>Pharmacy selected</h1>);
            return (<ViewAccountComponent key={this.state.selectedPharmacyAccountId} isPerson={false} accountID={this.state.selectedPharmacyAccountId} />);
        }

        return null;
    }

    render() {
        if (this.state.loading) {
            return (<LoadingItemComponent />);
        }
        return (
            <Fragment>

                <CenteredComponent size={9} className="animated fadeIn">
                    
                    <SystemStatsComponent accordionTitle="Statistiken" showOpen={false}></SystemStatsComponent>

                    <UserSearchComponent userType="person" accordionTitle="Personal" mostRecentDays={window['runtimeConfig'].adminShowMostRecentDays} showOpen={true}></UserSearchComponent>
                    <UserSearchComponent userType="pharmacy" accordionTitle="Jobangebote" mostRecentDays={window['runtimeConfig'].adminShowMostRecentDays} showOpen={true}></UserSearchComponent>

                    {/* <ListProfilesComponent userType="pharmacy" accordionTitle="Apotheken Profile" mostRecentDays={14}></ListProfilesComponent> */}
                </CenteredComponent>

            </Fragment>
        );

    }

}