import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone'
import { _ } from '../../../controllers/languageController';
import ApiController, { callbackWrapper } from '../../../controllers/apiController';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ConfigController from '../../../controllers/configController';
import SelectInputComponent, { optionFilter } from '../selectInputComponent';
import NotificationController from '../../../controllers/notificationController';

class UploadDocumentComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            file: undefined,
            type: undefined,
            submitted: false
        };
    }

    accept(file) {
        if (Array.isArray(file)) {
            file = file[0]; // nur 1 File
        }

        this.setState({ file: file });
    }

    cancelUpload() {
        this.setState({ file: undefined, type: undefined, submitted: false });
    }

    upload() {
        this.setState({ submitted: true });
        if (!this.state.type) {
            return;
        }

        NotificationController.pushInfo(_("upload.started"));
        ApiController.uploadFile(this.state.type.id, this.state.file, callbackWrapper(this.callback.bind(this)));
        this.setState({ file: undefined, type: undefined, submitted: false });
    }

    callback(response) {
        if (response.data.error) {
            if (response.data.error.message === "error.validationFailed") {

                let result = `${_(response.data.error.message)}:\n`;
                for (let item in response.data.error.data) {
                    result += `${_(response.data.error.data[item])}\n`;
                }

                NotificationController.pushError(result, _("upload.failed"));
                return;
            }

            NotificationController.pushError(_(response.data.error.message), _("upload.failed"));
            return;
        }

        if (this.props.reload) {
            this.props.reload();
        }

        NotificationController.pushSuccess(_("upload.success"));
    }

    retrieveDocumentTypes() {
        return ConfigController.retrieveDocumentTypes();
    }

    selectDocumentType(item) {
        this.setState({ type: item });
    }

    render() {
        return [(
            <Modal className="modal-dialog-centered modal-lg" isOpen={!!this.state.file} toggle={this.cancelUpload.bind(this)}>
                <ModalHeader toggle={this.cancelUpload.bind(this)}>{_("text.upload")}</ModalHeader>
                <ModalBody className="py-0">
                    <SelectInputComponent onSelect={this.selectDocumentType.bind(this)}
                        onFilter={optionFilter(this.retrieveDocumentTypes())}
                        submitted={this.state.submitted}
                        placeholder={_("placeholder.documentTypes")} />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.cancelUpload.bind(this)}>{_("cancel")}</Button>
                    <Button color="success" onClick={this.upload.bind(this)}>{_("confirm")}</Button>
                </ModalFooter>
            </Modal>
        ), (
            <Dropzone onDrop={this.accept.bind(this)}>
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div className="py-4 upload" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p className="m-auto">{_("text.dragOrSelectFileToUpload")}</p>
                        </div>
                    </section>
                )}
            </Dropzone>
        )];
    }

}

export default withRouter(UploadDocumentComponent);