import React, { Component } from 'react';
import AuthenticationController from '../../../controllers/authenticationController';
import ApiController, { callbackWrapper } from '../../../controllers/apiController';
import { Formik, Field } from 'formik';
import * as Yup from "yup";
import { withRouter } from 'react-router-dom';
import { Form, FormGroup, Button } from 'reactstrap';
import { _ } from '../../../controllers/languageController';
import { InputForm } from '../inputFormComponent';
import LoadingItemComponent from '../loadingItemComponent';
import SelectInputComponent, { postalAreaFilter, optionFilter } from '../selectInputComponent';
import NotificationController from '../../../controllers/notificationController';

class EditAccountComponent extends Component {

    original = {phoneCountryCode:null, phone:null};
    lastSmsNumber = null; // will receive the last used SMS phone number for resending the code
    lastSmsMessage = null; // will receive the last message sent to user for resending
    sentSmsCode = null; // will receive the random number sent via SMS to verify phone number changes

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            content: null,  // will receive the loaded model
            requiresSmsConfirmation: false,  // set to true if UI is supposed to wait for SMS number confirmation
            isResendButtonDisabled: false   // is SMS resend button disabled for a minute?
        };
    }

    componentWillMount() {
        this.load();
    }

    load() {
        if (AuthenticationController.isPerson()) {
            ApiController.retrieveAccountPerson(callbackWrapper(this.loadAccountCallback).bind(this));
        } else {
            ApiController.retrieveAccountPharmacy(callbackWrapper(this.loadAccountCallback).bind(this));
        }
    }

    loadAccountCallback = (response) => {
        this.setState({ content: response.data.result });
        this.original.phoneCountryCode = this.state.content.account.phoneCountryCode;
        this.original.phone = this.state.content.account.phone;
        ApiController.retrievePostalArea(response.data.result.account.postalAreaID, callbackWrapper(this.loadPostalAreaCallback).bind(this));
    }

    loadPostalAreaCallback = (response) => {
        this.setState({
            postalArea: {
                id: response.data.result.id,
                item: `${response.data.result.code} ${response.data.result.name}`
            }, loading: false
        });
    }

    // GENDERS
    retrieveGenders() {
        return [{ id: 0, item: _("gender.other") }, { id: 1, item: _("gender.female") }, { id: 2, item: _("gender.male") }];
    }

    retrieveGender(id) {
        return this.retrieveGenders().filter(x => x.id == id)[0];
    }

    selectGender(gender) {
        let content = Object.assign({}, this.state.content);
        content.gender = gender.id;

        this.setState({ content: content });
    }


    // PHONE-COUNTRY-CODE
    selectPhoneCountryCode(item) {
        let content = Object.assign({}, this.state.content);
        content.account.phoneCountryCode = item.id;

        this.setState({ content: content, requiresSmsConfirmation: false });
    }

    // disable SMS code element if user changed the phone number so he needs to resubmit first
    onChangePhoneNumber(item) {
        this.setState({ requiresSmsConfirmation: false });
    }

    selectArea(area) {
        this.setState({
            postalArea: area, postalAreaUpdate: true
        });
    }

    resendSmsCode(e) {

        if ( !this.lastSmsNumber || !this.lastSmsMessage) return; // should be set with last used sms phone number (incl. country code)
        NotificationController.sendSMS(this.lastSmsNumber, this.lastSmsMessage);

        // now, disable the resend button for 1 minute
        this.setState({ isResendButtonDisabled: true });
    
        // **** here's the timeout ****
        setTimeout(() => this.setState({ isResendButtonDisabled: false }), 60000);        
    }
        
    onSubmit(fields) {

        const newPhoneCountryCode = this.state.content.account.phoneCountryCode;    // has been set in state during change
        var newPhone = fields.phone.replace(/[^0-9]/g, '');  // comes from input field, strip all non-numeric and special characters // .replace(/[^a-zA-Z0-9]/g, '');
        // remove leading zero's
        if (newPhone.charAt(0)=='0') newPhone = newPhone.substr(1);

        //
        // SEND SMS CODE (random 4 digit number)
        // If user changed phone number (or country code).
        //
        if (!newPhoneCountryCode || !newPhone) return; // make sure both fields are set, reject submit if not
        const hasNumberChanged = newPhoneCountryCode != this.original.phoneCountryCode || newPhone != this.original.phone;

        // if number has changed and it is not an ADMIN IMPERSONATION verify with SMS code
        if ( hasNumberChanged && !AuthenticationController.isAdminImpersonating())
        {
            if ( !this.state.requiresSmsConfirmation){
                const randomNumber = NotificationController.generateRandomNumber(4); // 4 digits
                this.sentSmsCode = randomNumber;    // remember sent SMS code to be confirmed
                var message = `${_('notification.randomNumberSMS')} ${randomNumber}`;
                var tel = `+${newPhoneCountryCode}${newPhone}`

                this.lastSmsNumber = tel;    // remember last used sms number for resending the code
                this.lastSmsMessage = message;
                NotificationController.sendSMS(tel, message);
                this.setState({requiresSmsConfirmation: true}); // state change to show confirmation field
                // console.log(`Sent random number: ${randomNumber}`)
                return;
            }
            else{
                // console.log(`Confirmation already turned on, expecting code '${this.sentSmsCode}'. Here are all fields.`)
                if ( fields['confirmationCode'] != this.sentSmsCode){
                    this.setState({enteredInvalidSmsCode: true}); // state change to show confirmation field
                    // console.log(`Expected '${this.sentSmsCode}' but entered '${fields['confirmationCode']}'`);
                    return;
                }
            }
        }
        // console.groupEnd('**onSubmit**')

        this.sentSmsCode = null;
        this.setState({ loading: true, fields: fields, requiresSmsConfirmation: false, enteredInvalidSmsCode:false });
        ApiController.updateAccount({
            phoneCountryCode: this.state.content.account.phoneCountryCode,
            phone: newPhone,
            postalAreaID: this.state.postalArea.id
        }, callbackWrapper(this.updateAccountCallback.bind(this)).bind(this));
    }

    updateAccountCallback(response) {
        if (response.data.error) {
            NotificationController.pushError(_(response.data.error.message), _("error.update"));
        } else {
            if (AuthenticationController.isPerson()) {
                ApiController.updatePersonAccount({
                    firstname: this.state.fields.firstname,
                    lastname: this.state.fields.lastname,
                    gender: this.state.content.gender
                }, callbackWrapper(this.updateCallback.bind(this)).bind(this));
            } else {
                ApiController.updatePharmacyAccount({
                    name: this.state.fields.companyName,
                    address: this.state.fields.address
                }, callbackWrapper(this.updateCallback.bind(this)).bind(this));
            }
        }
    }

    updateCallback(response) {

        if (response.data.error) {
            this.setState({ loading: false });
            NotificationController.pushError(_(response.data.error.message), _("error.update"));
        } else {
            NotificationController.pushSuccess(_("success.update"));
            this.load();
        }
    }

    renderForPerson() {

        const validationSchema = Yup.object().shape({
            firstname: Yup.string()
                .min(2, _("invalid.name"))
                .required(_("no.name")),
            lastname: Yup.string()
                .min(2, _("invalid.name"))
                .required(_("no.name")),
            phone: Yup.string()
                .min(6, _("invalid.phone"))
                .required(_("no.phone"))
            // confirmationCode: Yup.string()
            //     .min(4, _("invalid.smsCode"))
            //     .required(_("no.smsCode"))
        });

        const initialValues = {
            firstname: this.state.content.firstname,
            lastname: this.state.content.lastname,
            email: this.state.content.account.email,
            phone: this.state.content.account.phone,
            confirmationCode: ''
        };

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmit.bind(this)}
                render={(props) => (
                    // <Form noValidate className="col-12 p-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>
                    <Form className="col-12 p-0 edit-account-component" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>

                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.firstname")} name="firstname" component={InputForm} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.lastname")} name="lastname" component={InputForm} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <SelectInputComponent
                                required submitted
                                onSelect={this.selectGender.bind(this)}
                                onFilter={optionFilter(this.retrieveGenders())}
                                default={this.retrieveGender(this.state.content.gender)}
                                placeholder={_("placeholder.gender")} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <SelectInputComponent
                                required submitted
                                onSelect={this.selectArea.bind(this)}
                                onFilter={postalAreaFilter}
                                default={this.state.postalArea}
                                placeholder={_("placeholder.postalArea")} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.email")} name="email" type="email" readOnly="readOnly" component={InputForm} />
                        </FormGroup>

                        <FormGroup className="mb-2 profile-account-tel">
                            <SelectInputComponent
                                    required submitted
                                    onSelect={this.selectPhoneCountryCode.bind(this)}
                                    onFilter={optionFilter(ApiController.getPhoneCountryCodeList())}
                                    default={ApiController.getPhoneCountryCode(this.state.content.account.phoneCountryCode)}
                                    placeholder={_("placeholder.phoneCountryCode")} />
                            <Field placeholder={_("placeholder.phone")} required inputmode="numeric" name="phone" component={InputForm} onInput={this.onChangePhoneNumber.bind(this)}/>
                        </FormGroup>


                        {/* SMS NUMBER CONFIRMATION */}
                        { this.state.requiresSmsConfirmation &&
                            <FormGroup className={'mb-2 sms-confirmation ' + (this.state.enteredInvalidSmsCode ? 'sms-invalid' : '')} test-number={window['runtimeConfig'].smsTestingWithSmsNumber}>
                                <Field placeholder={_("placeholder.phoneSmsConfirmation")} required autoFocus name="confirmationCode" type="text" inputmode="numeric" component={InputForm} />
                                <Button type="button" 
                                    disabled={this.state.isResendButtonDisabled} 
                                    onClick={(e) => this.resendSmsCode(e) } 
                                    className="col-12" >
                                        {this.state.isResendButtonDisabled ? _("placeholder.phoneSmsResendDisabled"): _("placeholder.phoneSmsResend")} 
                                        <i className="fas fa-angle-right"></i>
                                </Button>
                            </FormGroup>
                        }

                        <div className="row">
                            <div className="offset-md-6 col-md-6 text-right">
                                <Button type="submit" className="col-12" color="success">{_("save")} <i className="fas fa-angle-right"></i></Button>
                            </div>
                        </div>
                    </Form>

                )}
            />
        );        
    }

    renderForPharmacy() {
        const validationSchema = Yup.object().shape({
            companyName: Yup.string()
                .min(3, _("invalid.companyName"))
                .required(_("no.companyName")),
            address: Yup.string()
                .min(3, _("invalid.address"))
                .required(_("no.address")),
            phone: Yup.string()
                .min(6, _("invalid.phone"))
                .required(_("no.phone")),
        });

        const initialValues = {
            companyName: this.state.content.name,
            address: this.state.content.address,
            email: this.state.content.account.email,
            phone: this.state.content.account.phone,
            confirmationCode: ''
        };

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmit.bind(this)}
                render={(props) => (
                    <Form noValidate className="col-12 p-0 edit-account-component" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>
                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.companyName")} name="companyName" component={InputForm} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.address")} name="address" component={InputForm} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <SelectInputComponent
                                required submitted
                                onSelect={this.selectArea.bind(this)}
                                onFilter={postalAreaFilter}
                                default={this.state.postalArea}
                                placeholder={_("placeholder.postalArea")} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.email")} name="email" type="email" readOnly="readOnly" component={InputForm} />
                        </FormGroup>

                        <FormGroup className="mb-2 profile-account-tel">
                            <SelectInputComponent
                                    required submitted
                                    onSelect={this.selectPhoneCountryCode.bind(this)}
                                    onFilter={optionFilter(ApiController.getPhoneCountryCodeList())}
                                    default={ApiController.getPhoneCountryCode(this.state.content.account.phoneCountryCode)}
                                    placeholder={_("placeholder.phoneCountryCode")} />
                            <Field placeholder={_("placeholder.phone")} required inputmode="numeric" name="phone" component={InputForm} onInput={this.onChangePhoneNumber.bind(this)}/>
                        </FormGroup>

                        {/* SMS NUMBER CONFIRMATION */}
                        { this.state.requiresSmsConfirmation &&
                            <FormGroup className={'mb-2 sms-confirmation ' + (this.state.enteredInvalidSmsCode ? 'sms-invalid' : '')} test-number={window['runtimeConfig'].smsTestingWithSmsNumber}>
                                <Field placeholder={_("placeholder.phoneSmsConfirmation")} required autoFocus name="confirmationCode" type="text" inputmode="numeric" component={InputForm} />
                                <Button type="button" 
                                    disabled={this.state.isResendButtonDisabled} 
                                    onClick={(e) => this.resendSmsCode(e) } 
                                    className="col-12" >
                                        {this.state.isResendButtonDisabled ? _("placeholder.phoneSmsResendDisabled"): _("placeholder.phoneSmsResend")} 
                                        <i className="fas fa-angle-right"></i>
                                </Button>
                            </FormGroup>
                        }

                        <div className="row">
                            <div className="offset-md-6 col-md-6 text-right">
                                <Button type="submit" className="col-12" color="success">{_("save")} <i className="fas fa-angle-right"></i></Button>
                            </div>
                        </div>
                    </Form>

                )}
            />
        );    
    }


    render() {
        if (this.state.loading) {
            return (<LoadingItemComponent />);
        }

        //
        // CS 15/7/20: created new special render function to seperate the already big and polluted codebases
        // Based on the account type to proper rendering method will be executed
        //
        if (AuthenticationController.isPerson()) return this.renderForPerson();

        return this.renderForPharmacy();
    }

}

export default withRouter(EditAccountComponent);