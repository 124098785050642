import React, { Component } from 'react';
import ApiController, { callbackWrapper } from '../../controllers/apiController';
import CenteredComponent from '../_components/centeredComponent';
import { _ } from '../../controllers/languageController';
import LoadingItemComponent from '../_components/loadingItemComponent';
import AccordionItemComponent from '../_components/accordionItemComponent';
import MatchCompareComponent from '../_components/matching/matchCompareComponent';
import AuthenticationController from '../../controllers/authenticationController';
import ReactToPrint from 'react-to-print';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import MatchCompareDocumentsComponent from '../_components/matching/matchCompareDocumentsComponent';
import SecurityController from '../../controllers/securityController';

// import { useHistory } from "react-router-dom";
import { withRouter } from 'react-router';

export default class MatchingCompare extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            deleteContact: false,
            adminImpersonating: false, // true if admin is currently impersonating this account which means it has more permissions
        };
    }

    componentWillMount() {

        var inImpersonationMode = AuthenticationController.retrieveContent().impersonatingAccountID != null;

        if (!this.props.match.params.profile || !this.props.match.params.otherProfile) {
            this.props.history.push("/");
            return;
        }

        this.setState({ profileId: this.props.match.params.profile, 
            otherProfileId: this.props.match.params.otherProfile,
            adminImpersonating: inImpersonationMode, // true if admin is currently impersonating which shows all details for instance
        });
    }

    componentDidMount() {
        this.load();
    }

    load() {
        ApiController.retrieveMatch(this.state.profileId, this.state.otherProfileId, callbackWrapper(this.callback.bind(this)));
    }

    callback(response) {
        this.setState({ content: response.data.result, loading: false });
    }

    retrieveOtherName() {

        //
        // persons can always see the pharmacies name, pharmacies only if a confirmed contact-relationship has been established
        //
        if (AuthenticationController.isPerson() || this.state.content.contactStatus === 2) {
            return this.state.content.otherProfile.accountDetails.name;
        }
        // render contact inquiry button instead to initiate a contact request
        // return (<button className="btn btn-success" onClick={this.requestContact.bind(this)}>{_("contact.inquiry")}</button>);
        return "";
    }

    retrieveOthersAddress() {

        //
        // persons can always see the pharmacies location, pharmacies only if a confirmed contact-relationship has been established
        //
        if (AuthenticationController.isPerson()) {
            return `${this.state.content.otherProfile.accountDetails.address}, ${this.state.content.otherProfileArea.code} ${this.state.content.otherProfileArea.name} (${this.state.content.otherProfileArea.countryCode.toUpperCase()})`;
        }
        // else if ( this.state.content.contactStatus === 2)
        // {
        //     // Pharmacy only sees Zip, City, Tel, Email of the person IF a contact-relationship has been confirmed
        //     return `${this.state.content.otherProfileArea.code} ${this.state.content.otherProfileArea.name} (${this.state.content.otherProfileArea.countryCode.toUpperCase()})`;
        // }
        return `${this.state.content.profileArea.code} ${this.state.content.profileArea.name} (${this.state.content.profileArea.countryCode.toUpperCase()})`;
    }

    retrieveOthersContactDetails() {

        var phone = this.state.content.otherProfile.accountDetails.phone;
        var phoneCountryCode = this.state.content.otherProfile.accountDetails.phoneCountryCode;
        var email = this.state.content.otherProfile.accountDetails.email;

        //
        // regardless if person or pharmacy, they can only be seen when to contact-relationship has been confirmed by both parties
        //
        if ( this.state.content.contactStatus === 2)
        {
            return (
                <div>
                    {this.state.adminImpersonating && phone != null && phone.trim() != "" && <span className="d-block">{_("placeholder.phone")}: +{phoneCountryCode} {phone}</span>}
                    <span>
                        Email: &nbsp;                    
                        <a href={"mailto:" + email}>{email}</a>
                    </span>
                </div>

            );
        }
        return "";
    }

    retrieveOthersRemarks() {

        var remarks = this.state.content.otherProfile.remarks;
        if ( remarks == null ) remarks = "";

        //
        // regardless if person or pharmacy, they can only be seen when to contact-relationship has been confirmed by both parties
        //
        if ( this.state.content.contactStatus === 2)
        {
            return (
                <div>
                    {remarks.trim() !== "" && 
                        <div>
                            <span className="badge badge-secondary">{_("remarks")}</span>
                            <p>{remarks}</p>
                        </div>
                    }
                </div>

            );
        }
        return "";
    }

    requestContact() {
        this.setState({ loading: true });
        ApiController.requestContact(this.state.content.profile.id,
            this.state.content.otherProfile.id, callbackWrapper(this.load.bind(this)));
    }

    revokeContact() {
        this.setState({ deleteContact: true });
    }

    deleteContact() {
        this.setState({ deleteContact: true });
    }

    acceptContact() {
        this.setState({ loading: true });
        ApiController.acceptContact(this.state.content.profile.id,
            this.state.content.otherProfile.id, callbackWrapper(this.load.bind(this)));
    }

    cancelDeleteContact() {
        this.setState({ deleteContact: false });
    }

    confirmDeleteContact() {
        this.setState({ loading: true, deleteContact: false });
        ApiController.deleteContact(this.state.content.profile.id,
            this.state.content.otherProfile.id, callbackWrapper(this.load.bind(this)));
    }

    render() {
        if (this.state.loading) return (<LoadingItemComponent />);

        return [(
            <div class="print d-none d-print-block p-5 text-center" ref={compareComponent => (this.compare = compareComponent)}>

                {/* {this.state.content.contactStatus === 2 && <img className="d-inline-block w-25 pr-4" src={SecurityController.secureProfilePictureLink(this.state.content.profile.id, this.state.content.profile.id)} onError={i => i.target.remove()} alt="profile-picture" />} */}
                {/* {this.state.content.contactStatus === 2 && <img className="d-block w-25 mx-auto" src={SecurityController.secureProfilePictureLink(this.state.content.profile.id, this.state.content.profile.id)} onError={i => i.target.remove()} alt="profile-picture" />} */}
                <img src={`${process.env.PUBLIC_URL}/assets/images/logo.svg?v=2.1`} className="d-inline-block w-50 pb-2" />
                {/* {this.state.content.contactStatus === 2 && <img className="d-inline-block w-25 pl-4" src={SecurityController.secureProfilePictureLink(this.state.content.profile.id, this.state.content.otherProfile.id)} onError={i => i.target.remove()} alt="profile-picture-other" />} */}
                {this.state.content.contactStatus === 2 && <img className="d-block w-25 mx-auto" src={SecurityController.secureProfilePictureLink(this.state.content.profile.id, this.state.content.otherProfile.id)} onError={i => i.target.remove()} alt="profile-picture-other" />}

                <h1 className="mb-2">{_("text.profileMatches")} {parseFloat("" + (this.state.content.percent * 100)).toFixed(2)}%</h1>
                <h2>{this.retrieveOtherName()}</h2>
                <h4 className="mb-3 offset-lg-2 col-lg-8">{this.state.content.otherProfile.hoursPerWeek} {_("text.hoursPerWeek")}, {this.retrieveOthersAddress()}</h4>
                <span className="d-block">{this.retrieveOthersContactDetails()}</span>
                <span className="d-block">{this.retrieveOthersRemarks()}</span>
                <MatchCompareComponent value={this.state.content} />

            </div>
        ), (
            <Modal className="modal-dialog-centered modal-lg" isOpen={this.state.deleteContact} toggle={this.cancelDeleteContact.bind(this)}>
                <ModalHeader toggle={this.cancelDeleteContact.bind(this)}>{_("text.confirm.text")}</ModalHeader>
                <ModalBody className="py-0">
                    {_("text.confirm.deleteContact")}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.cancelDeleteContact.bind(this)}>{_("cancel")}</Button>
                    <Button color="danger" onClick={this.confirmDeleteContact.bind(this)}>{_("confirm")}</Button>
                </ModalFooter>
            </Modal>
        ), (
            <CenteredComponent size={10} className="animated fadeIn" >

                <h1 className="mb-2">
                    <span>{_("text.profileMatches")} {parseFloat("" + (this.state.content.percent * 100)).toFixed(2)}%</span>
                    {/* Print button */}
                    <span><Button onClick={() => this.print.click()} className="ml-1 mb-1" color="info" size={"sm"}>{_("print")}</Button></span>
                </h1>
                {/* MATCHING DETAILS, hours, name, address */}
                <h4 className="mb-3 offset-lg-2 col-lg-8">
                    <h2>{this.retrieveOtherName()}</h2>
                    {this.state.content.contactStatus === 2 && <img className="d-block w-25 mx-auto" src={SecurityController.secureProfilePictureLink(this.state.content.profile.id, this.state.content.otherProfile.id)} onError={i => i.target.remove()} alt="profile-picture-other" />}
                    <span>{this.state.content.otherProfile.hoursPerWeek} {_("text.hoursPerWeek")}, {this.retrieveOthersAddress()}</span>
                    <span className="d-block">{this.retrieveOthersContactDetails()}</span>
                    <span className="d-block">{this.retrieveOthersRemarks()}</span>
                </h4>

                <AccordionItemComponent title={_("text.profile_")} show>
                    <MatchCompareComponent value={this.state.content} />
                </AccordionItemComponent>

                {this.state.content.contactStatus === 2 &&
                    <AccordionItemComponent title={_("text.documents")} show>
                        <MatchCompareDocumentsComponent profileId={this.state.profileId} otherProfileId={this.state.otherProfileId} />
                    </AccordionItemComponent>
                }

                <div className="text-right">
                    {<button className="btn btn-danger" onClick={this.props.history.goBack}>{_("back")}</button>}
                    {this.state.content.contactStatus == 0 && <button className="btn btn-success" onClick={this.requestContact.bind(this)}>{_("contact.inquiry")}</button>}
                    {this.state.content.contactStatus == 1 && AuthenticationController.isPerson() && <button className="btn btn-success" onClick={this.acceptContact.bind(this)}>{_("contact.accept")}</button>}
                    {this.state.content.contactStatus == 1 && <button className="btn btn-warning" onClick={this.revokeContact.bind(this)}>{_("contact.revoke")}</button>}
                    {this.state.content.contactStatus == 2 && <button className="btn btn-danger" onClick={this.deleteContact.bind(this)}>{_("contact.delete")}</button>}
                    <ReactToPrint
                        trigger={(() => <button ref={printButton => (this.print = printButton)} className="btn btn-info">{_("print")}</button>).bind(this)}
                        content={() => this.compare}
                    />
                </div>
            </CenteredComponent >
        )];
    }

}