import React, { Component } from 'react';

export default class CenteredComponent extends Component {

    render() {
        return (
            <div className={`container ${this.props.className}`}>
                <div className="row justify-content-center">
                    <div className={`offset-lg-${this.props.size - 12} col-lg-${this.props.size}`}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }

}