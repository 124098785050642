import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import { _ } from '../../controllers/languageController';
import CenteredComponent from '../_components/centeredComponent';
import EditAccountComponent from '../_components/profile/editAccountComponent';
import ProfilesComponent from '../_components/profile/profilesComponent';
import AccordionItemComponent from '../_components/accordionItemComponent';
import UploadDocumentComponent from '../_components/profile/uploadDocumentComponent';
import ListDocumentsComponent from '../_components/profile/listDocumentsComponent';
import ChangePasswordComponent from '../_components/profile/changePasswordComponent';
import ChangeNotificationsComponent from '../_components/profile/changeNotificationsComponent';
import ApiController, { callbackWrapper } from '../../controllers/apiController';
import UploadImageComponent from '../_components/notifications/uploadImageComponent';
import NotificationController from '../../controllers/notificationController';
import AuthenticationController from '../../controllers/authenticationController';
import AdminNotesComponent from '../_components/profile/adminNotesComponent';

export default class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileId: Math.random(),
            deleteAccount: false,
            // CS 4/10/21: add user type dependent elements
            isPerson: AuthenticationController.isPerson(),
            isAdminImpersonating: AuthenticationController.isAdminImpersonating()
        };
    }

    reloadFiles() {
        this.setState({ fileId: Math.random() });
    }

    deleteAccount() {
        this.setState({ deleteAccount: true });
    }

    confirmDeleteAccount() {
        ApiController.deleteAccount(callbackWrapper((response) => {
            if (response.data.error) {
                NotificationController.pushError(_(response.data.error));
                return;
            }

            NotificationController.pushSuccess(_("success.accountDeleted"));
            AuthenticationController.logout();
            this.props.history.push("/");
        }).bind(this));
    }

    cancelDeleteAccount() {
        this.setState({ deleteAccount: false });
    }

    filesLoaded(pictureAvailable) {
        this.setState({
            files: true,
            pictureAvailable: pictureAvailable
        });
    }

    // Cs 16/3/22: looked into why popup to upload image sometimes appears and sometimes it doesn't
    showUploadImageNotification() {

        if (this.state.files) {
            if (!this.state.pictureAvailable) {

                let parsedNotification = { date: Date.now(), counter: 0 };
                let imageNotification = localStorage.getItem("imageNotification");
                if (imageNotification) parsedNotification = JSON.parse(imageNotification);

                // CS 16/3/22: show picture upload popup only one times per day for 3 days only
                // CS 16/3/22: fixed logic (if statement below) which was coded incorrectly to make any sense
                if (
                        parsedNotification.counter == 0 ||
                        ( parsedNotification.counter < 3 && (Date.now() - parsedNotification.date) / (24 * 3600 * 1000) > 1 ) 
                    ) 
                {
                    parsedNotification.counter++;
                    localStorage.setItem("imageNotification", JSON.stringify(parsedNotification));
                    return true;
                }

            }
        }

        return false;
    }

    render() {

        return [(
            <UploadImageComponent reload={this.reloadFiles.bind(this)} show={this.showUploadImageNotification()} />
        ), (
            <Modal className="modal-dialog-centered modal-lg" isOpen={this.state.deleteAccount} toggle={this.cancelDeleteAccount.bind(this)}>
                <ModalHeader toggle={this.cancelDeleteAccount.bind(this)}>{_("text.confirm.text")}</ModalHeader>
                <ModalBody className="py-0">
                    {_("text.confirm.deleteAccount")}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.cancelDeleteAccount.bind(this)}>{_("cancel")}</Button>
                    <Button color="danger" onClick={this.confirmDeleteAccount.bind(this)}>{_("confirm")}</Button>
                </ModalFooter>
            </Modal>
        ), (
            <CenteredComponent size={9} className="animated fadeIn">
                <div className="my-3">

                    {/* ADMIN impersonator gets extra ADMIN accordion on top */}
                    {this.state.isAdminImpersonating &&
                        <AccordionItemComponent title={_("text.admin.title")} icon="fa-info-circle">
                            <AdminNotesComponent />
                        </AccordionItemComponent>
                    }
                    <AccordionItemComponent title={_("text.account.data")}>
                        <EditAccountComponent />
                    </AccordionItemComponent>

                    <AccordionItemComponent title={_("text.account.documents")}>
                        <UploadDocumentComponent reload={this.reloadFiles.bind(this)} />
                        <ListDocumentsComponent loaded={this.filesLoaded.bind(this)} key={this.state.fileId} />
                    </AccordionItemComponent>

                    <AccordionItemComponent title={ this.state.isPerson ? _("text.account.jobseeker_profiles") : _("text.account.pharmacy_profiles")} show>
                        <ProfilesComponent />
                    </AccordionItemComponent>

                    <AccordionItemComponent title={_("text.account.newsletter")}>
                        <ChangeNotificationsComponent />
                    </AccordionItemComponent>

                    <AccordionItemComponent title={_("text.account.password")}>
                        <ChangePasswordComponent />
                    </AccordionItemComponent>
                </div>

                <div className="row">
                    <div className="offset-md-6 col-md-6 text-right">
                        <Button className="col-12" color="danger" onClick={this.deleteAccount.bind(this)}><i className="fas fa-user-times"></i> {_("text.account.delete")}</Button>
                    </div>
                </div>
            </CenteredComponent>
        )];
    }

}