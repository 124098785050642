import React, { Component } from 'react';

import { Button } from 'reactstrap';
import NotificationController from '../../controllers/notificationController';
import SecurityController from '../../controllers/securityController';
import { _ } from '../../controllers/languageController';
import ApiController, { callbackWrapper } from '../../controllers/apiController';
import LoadingController from '../../controllers/loadingContoller';
import CenteredComponent from '../_components/centeredComponent';

export default class Activation extends Component {  // email als parameter, vllt auch verschlüsselt

    componentWillMount() {
        this.setState({ email: undefined, accountId: undefined });
        try {
            if (this.props.match.params.token) {
                this.setState(SecurityController.temp_decrypt(this.props.match.params.token));
                return;
            }
        } catch { }
    }

    onSubmit() {
        LoadingController.show();
        ApiController.resendActivationMail(this.state.accountId, callbackWrapper((response) => {
            if (response.data.error) {
                NotificationController.pushError(_(response.data.error.message));
                return;
            }

            NotificationController.pushSuccess(_("success.activationMailResent"));
            this.props.history.push("/");
        }));
    }

    render() {
        if (!this.state.email) {
            this.props.history.push("/");
        }

        return (
            <CenteredComponent size={12} className="animated fadeIn">
                <h1 className="apo-login-header mb-2">{_("text.activationText.header")}</h1>
                <h4 className="mb-3 offset-lg-1 col-lg-10">{_("text.activationText.subtitle")}</h4>

                <h2 className="mb-3">{this.state.email}</h2>
                <h4 className="mb-2 offset-lg-1 col-lg-10">{_("text.activationMailNotReceivedText")}</h4>

                <div className="offset-lg-2 col-lg-8">
                    <div className="row justify-content-center">
                        <Button type="submit" color="success" onClick={this.onSubmit.bind(this)}>{_("text.resendActivation")} <i className="fas fa-angle-right"></i></Button>
                    </div>
                </div>
            </CenteredComponent>
        );
    }

}