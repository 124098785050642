import React from 'react';
import { Input, FormFeedback } from 'reactstrap';
import InputComponent from './inputComponent';

export var InputForm = ({ field, form: { touched, errors }, ...props }) => (
    <div>
        <InputComponent className="form-control-alternative" invalid={!!(touched[field.name] && errors[field.name])}
            {...field}
            {...props} />
        {touched[field.name] && errors[field.name] && <FormFeedback className="pb-1 display-block">{errors[field.name]}</FormFeedback>}
    </div>
);