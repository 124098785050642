import React, { Component } from 'react';
import { Form, Button, FormGroup, Input } from 'reactstrap';
import { Formik } from "formik";
import Slider from "rc-slider";
import * as Yup from "yup";

import 'rc-slider/assets/index.css';

import { _ } from '../../../controllers/languageController';
import CenteredComponent from '../centeredComponent';
import SelectInputComponent, { optionFilter } from '../selectInputComponent';
import ConfigController from '../../../controllers/configController';
import ProfileAvailabilityComponent from '../profile/profileAvailabilityComponent';

export default class Step2Component extends Component {

    constructor(props) {
        super(props);

        var isPharmacyEdition = ConfigController.isPharmacyEdition();
        var isHealthcareEdition = ConfigController.isHealthcareEdition();

        if (this.props.state[2]) {
            this.state = {
                submitted: false,
                shiftedData: true,  // true if data has been shifted from another step
                alternateWeekdaysOpen: false,
                availableIn: this.props.state[2].availableIn,
                hoursPerWeek: this.props.state[2].hoursPerWeek,
                // regular weekdays
                monday: this.props.state[2].monday,
                tuesday: this.props.state[2].tuesday,
                wednesday: this.props.state[2].wednesday,
                thursday: this.props.state[2].thursday,
                friday: this.props.state[2].friday,
                saturday: this.props.state[2].saturday,
                sunday: this.props.state[2].sunday,
                // alternate weekdays
                altMonday: this.props.state[2].altMonday,
                altTuesday: this.props.state[2].altTuesday,
                altWednesday: this.props.state[2].altWednesday,
                altThursday: this.props.state[2].altThursday,
                altFriday: this.props.state[2].altFriday,
                altSaturday: this.props.state[2].altSaturday,
                altSunday: this.props.state[2].altSunday,
                // others
                nightlyShift: this.props.state[2].nightlyShift,
                replacement: this.props.state[2].replacement,
                overpay: this.props.state[2].overpay,
                accomodation: this.props.state[2].accomodation,
                management: this.props.state[2].management,
                licenceHolder: this.props.state[2].licenceHolder,
                covidTester: this.props.state[2].covidTester,
                flexibleCalendarWeek: this.props.state[2].flexibleCalendarWeek, // added 29/9/22
                availabilities: this.props.state[2].availabilities
            };
        } else {
            this.state = {
                submitted: false,
                shiftedData: false,  // true if data has been shifted from another step
                alternateWeekdaysOpen: false,
                availableIn: 0,
                hoursPerWeek: this.retrieveJobType().defaultHoursPerWeek,
                // CS 28/4/22: added missing default values to prevent issues when submitting *undefined* values
                nightlyShift: /*false*/this.retrieveBoolDecisions()[0],
                // replacement defaults to true for healthcare edition, otherwise false
                replacement: isHealthcareEdition ? /*true*/this.retrieveBoolDecisions()[1] : /*false*/this.retrieveBoolDecisions()[0],
                overpay: /*false*/this.retrieveBoolDecisions()[0],
                accomodation: /*false*/this.retrieveBoolDecisions()[0],
                management: /*false*/this.retrieveBoolDecisions()[0],
                licenceHolder: /*false*/this.retrieveBoolDecisions()[0],
                covidTester: /*false*/this.retrieveBoolDecisions()[0],
                flexibleCalendarWeek: /*false*/this.retrieveBoolDecisions()[0] // added 29/9/22
            };
        }
    }

    // defaukt properties
    static defaultProps = {
        countOfSteps: 5  // default count of steps until completion (only for display)
    };

    componentWillMount() {

        window.document.getElementById('root').scrollIntoView({behavior: 'smooth'})

        //
        // here we determine if the alternate weekdays need to be open or collapsed by default
        //
        if ( this.state.shiftedData)  // only if previous data exists which is indicated by this flag
        {
            var altWeekOpen = false;

            if ( this.state.altMonday && this.state.altMonday.id != this.state.monday.id) altWeekOpen = true;
            if ( this.state.altTuesday && this.state.altTuesday.id != this.state.tuesday.id) altWeekOpen = true;
            if ( this.state.altWednesday && this.state.altWednesday.id != this.state.wednesday.id) altWeekOpen = true;
            if ( this.state.altThursday && this.state.altThursday.id != this.state.thursday.id) altWeekOpen = true;
            if ( this.state.altFriday && this.state.altFriday.id != this.state.friday.id) altWeekOpen = true;
            if ( this.state.altSaturday && this.state.altSaturday.id != this.state.saturday.id) altWeekOpen = true;
            if ( this.state.altSunday && this.state.altSunday.id != this.state.sunday.id) altWeekOpen = true;
                
            this.setState({ alternateWeekdaysOpen: altWeekOpen });
        }
    }

    onSubmit(fields) {

        if (!this.state.submitted) this.setState({ submitted: true });

        var isPharmacyEdition = ConfigController.isPharmacyEdition();
        var isHealthcareEdition = ConfigController.isHealthcareEdition();

        //console.log(`overpay: ${this.state.overpay.id}`);
        //console.log(`accomodation: ${this.state.accomodation.id}`);

        //
        // CS 28/1/22
        // if state.alternateWeekdaysOpen has been turned off make sure all alternate weekdays match the std weekdays
        //
        if ( this.state.alternateWeekdaysOpen == false)
        {
            this.state.altMonday = this.state.monday;
            this.state.altTuesday = this.state.tuesday;
            this.state.altWednesday = this.state.wednesday;
            this.state.altThursday = this.state.thursday;
            this.state.altFriday = this.state.friday;
            this.state.altSaturday = this.state.saturday;
            this.state.altSunday = this.state.sunday;
        }
        else{
            // CS 14/3/22: SUNDAY / altSunday
            // clean up default values for Sunday (pharmacy edition doesn't support sunday so they have to be set)
            if ( this.state.altSunday == null ) this.state.altSunday = this.state.sunday;
        }

        //
        // CS 11/2/22
        // clean up availability - time periods
        //
        if (this.state.availabilities)
        {
            var availabilityList = this.state.availabilities;
            // remove all items with missing values
            availabilityList = availabilityList.filter(x => x.periodStart != '' && x.periodEnd != '');
            // remove all items where periodEnd <= today
            availabilityList = availabilityList.filter(x => new Date(x.periodEnd) > new Date());
    
            // update state
            this.state.availabilities = availabilityList;

            // if temp job has not been selected - return empty list and ignore what has been entered
            if ( this.state.replacement.id != true) this.state.availabilities = [];
        }

        this.props.next({
            availableIn: this.state.availableIn, hoursPerWeek: this.state.hoursPerWeek,
            // standard weekdays
            monday: this.state.monday, tuesday: this.state.tuesday, wednesday: this.state.wednesday,
            thursday: this.state.thursday, friday: this.state.friday, saturday: this.state.saturday,
            sunday: this.state.sunday,
            // alternate weekdays
            altMonday: this.state.altMonday, altTuesday: this.state.altTuesday, altWednesday: this.state.altWednesday,
            altThursday: this.state.altThursday, altFriday: this.state.altFriday, altSaturday: this.state.altSaturday,
            altSunday: this.state.altSunday,
            // others
            nightlyShift: this.state.nightlyShift, 
            replacement: this.state.replacement,
            overpay: this.state.overpay, accomodation: this.state.accomodation,
            management: this.state.management, licenceHolder: this.state.licenceHolder, 
            covidTester: this.state.covidTester,
            flexibleCalendarWeek: this.state.flexibleCalendarWeek,
            availabilities: this.state.availabilities
        });

    }

    // CS 20/4/22: adapted result to the various editions
    retrieveHoursPerWeek() {
        if ( ConfigController.isPharmacyEdition())
            return `${this.state.hoursPerWeek} ${_("text.hour")} / ${this.state.hoursPerWeek / 4} ${_("text.tenth")}`;

        return `${this.state.hoursPerWeek} ${_("text.hour")}`;
    }

    retrieveAvailableIn() {
        if ( this.state.availableIn === 0)
            return _("available.now");
        return `${_("text.availableIn")}: ${this.state.availableIn} ${_("text.week")} / ${this.state.availableIn / 4} ${_("text.month")}`;
    }

    retrieveJobType() {
        return this.props.state[1].jobType;
    }

    retrieveShifts() {

        if (this.shifts) return this.shifts;

        // CS 8/3/22: job type pharmacist can NOT SELECT NIGHT shift type
        // var includeNightShiftType = this.props.state[1].jobType.code != "jobTypes.pharmacist";
        // this.shifts = ConfigController.retrieveShifts(includeNightShiftType);
        // CS 10/3/22: shifts with NIGHT if not pharmacy edition
        if (!this.shifts) this.shifts = ConfigController.retrieveShifts();

        return this.shifts;
    }


    // this.retrieveBoolDecisions()[0] == false
    // this.retrieveBoolDecisions()[1] == true
    retrieveBoolDecisions() { 

        if (this.decision) return this.decision;

        this.decision = [{ id: false, item: _("false") }, { id: true, item: _("true") }]
        return this.decision;
    }

    // CS 29/9/22: toggle function for the ridiculous bool/bit converter functions
    toggleBoolDecision(current){

        if ( current == null || current.id === false ) return this.retrieveBoolDecisions()[1];
        return this.retrieveBoolDecisions()[0];
    }

    selectHoursPerWeek(value) {
        this.setState({ hoursPerWeek: value });
    }

    selectAvailableIn(value) {
        this.setState({ availableIn: value });
    }

    //
    // Regular weekdays
    //
    selectMonday(value) {

        if ( this.state.altMonday == null || this.state.monday.id === this.state.altMonday.id)
            this.setState({ monday: value, altMonday: value });
        else
            this.setState({ monday: value });
    }
    selectTuesday(value) {
        if ( this.state.altTuesday == null || this.state.tuesday.id === this.state.altTuesday.id)
            this.setState({ tuesday: value, altTuesday: value });
        else
            this.setState({ tuesday: value });
    }
    selectWednesday(value) {
        if ( this.state.altWednesday == null || this.state.wednesday.id === this.state.altWednesday.id)
            this.setState({ wednesday: value, altWednesday: value });
        else
            this.setState({ wednesday: value });
    }
    selectThursday(value) {
        if ( this.state.altThursday == null || this.state.thursday.id === this.state.altThursday.id)
            this.setState({ thursday: value, altThursday: value });
        else
            this.setState({ thursday: value });
    }
    selectFriday(value) {
        if ( this.state.altFriday == null || this.state.friday.id === this.state.altFriday.id)
            this.setState({ friday: value, altFriday: value });
        else
            this.setState({ friday: value });
    }
    selectSaturday(value) {
        if ( this.state.altSaturday == null || this.state.saturday.id === this.state.altSaturday.id)
            this.setState({ saturday: value, altSaturday: value });
        else
            this.setState({ saturday: value });
    }
    selectSunday(value) {
        if ( this.state.altSunday == null || this.state.sunday.id === this.state.altSunday.id)
            this.setState({ sunday: value, altSunday: value });
        else
            this.setState({ sunday: value });
    }
    //
    // Alternate weekday selector functions
    //
    selectAlternateMonday(value) { this.setState({ altMonday: value }); }
    selectAlternateTuesday(value) { this.setState({ altTuesday: value });  }
    selectAlternateWednesday(value) { this.setState({ altWednesday: value }); }
    selectAlternateThursday(value) { this.setState({ altThursday: value }); }
    selectAlternateFriday(value) { this.setState({ altFriday: value }); }
    selectAlternateSaturday(value) { this.setState({ altSaturday: value }); }
    selectAlternateSunday(value) { this.setState({ altSunday: value }); }    
    // other settings
    selectNightlyShift(value) { this.setState({ nightlyShift: value }); }
    selectReplacement(value) { this.setState({ replacement: value }); }
    selectOverpay(value) { this.setState({ overpay: value }); }
    selectAccomodation(value) { this.setState({ accomodation: value }); }
    selectManagement(value) { this.setState({ management: value }); }
    selectLicenceHolder(value) { this.setState({ licenceHolder: value }); }
    selectCovidTester(value) { this.setState({ covidTester: value }); }
    selectFlexibleCalendarWeek(value) { this.setState({ flexibleCalendarWeek: value }); }

    // CS 9/2/22: bound event to new ProfileTimePeriodsComponent
    onChangeAvailability(data)
    {
        this.setState({availabilities: data})
    }

    // CS 14/10/22: make "flexible week" and "alternate week" mutually exclusive
    toggleFlexibleCalendarWeek()
    {
        var newState = this.toggleBoolDecision(this.state.flexibleCalendarWeek);
        var turnedOn = newState.id === true;

        if ( turnedOn)
        {
            // user turned flexible week on
            // turn on and alternate week off (mutually exclusive)
            this.setState({ flexibleCalendarWeek: newState, alternateWeekdaysOpen: false });
        }
        else
            this.setState({ flexibleCalendarWeek: newState });
    }
    toggleAlternateWeekdaysOpen(value)
    {
        var newState = !this.state.alternateWeekdaysOpen;
        var turnedOn = newState;

        var valFalse = this.retrieveBoolDecisions()[0];

        if ( turnedOn)
        {
            // user turned alternate week on
            // turn on and alternate week off (mutually exclusive)
            this.setState({ alternateWeekdaysOpen: newState, flexibleCalendarWeek: valFalse });
        }
        else
            this.setState({ alternateWeekdaysOpen: newState });
    }

    render() {
        
        var titleCode = "text.register.step2";
        if (ConfigController.isHealthcareEdition()) titleCode = "text.register.health.step2";

        // flag if the selected job type is pharmacist
        var selectedPharmacist = this.props.state[1].jobType.code == "jobTypes.pharmacist";
        //console.log(`selectedPharmacist = ${selectedPharmacist}`)
        // if not pharmacist - reset the two pharmacist flags
        if ( !selectedPharmacist)
        {
            this.state.management = false;
            this.state.licenceHolder = false;
        }

        // get some hard references to shift types
        var fullDayWork = this.retrieveShifts()[1];
        var notAvailableForWork = this.retrieveShifts()[0];

        // init SUNDAY with NO
        if ( !this.state.sunday ) this.state.sunday = notAvailableForWork; // not available entry

        // CS 25/4/22: different language tokens for editions
        var langWeekdays = "placeholder.titleWeekdays";
        var langAlternateWeekdays = "placeholder.titleAlternateWeekdays";

        if ( ConfigController.isHealthcareEdition()) {
            langWeekdays = "placeholder.health.titleWeekdays";
            langAlternateWeekdays = "placeholder.health.titleAlternateWeekdays";
        }

        // CS 29/11/22" Pflege only - disabled code below since we support defaultHoursPerWeek now
        /*
            // CS 26/4/22: pflege - default to 20 hours
            // defaultValue={this.state.hoursPerWeek || this.retrieveJobType().minimumHoursPerWeek}
            if (ConfigController.isHealthcareEdition()) 
                this.state.hoursPerWeek = this.state.hoursPerWeek || 20;    // default to 20 hours, regardless of what type of job for pflege-finden
        */            

        return (
            <CenteredComponent size={12} className="animated fadeIn">
                <h1 className="mb-2">{_("step")} 2 {_("of")} {this.props.countOfSteps}</h1>
                <h4 className="mb-4 col-lg-12">{_(`${titleCode}.${this.props.type}`)}</h4>
                <Formik onSubmit={this.onSubmit.bind(this)}
                    render={(props) => (
                        <Form noValidate className="col-lg-12 mb-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>

                            <div className="two-columns-grid py-2">
                                <FormGroup className="noselect">
                                    <Slider className="mb-1"
                                        min={this.retrieveJobType().minimumHoursPerWeek === this.retrieveJobType().maximumHoursPerWeek ? 0 : this.retrieveJobType().minimumHoursPerWeek}
                                        max={this.retrieveJobType().maximumHoursPerWeek}
                                        disabled={this.retrieveJobType().maximumHoursPerWeek === this.retrieveJobType().minimumHoursPerWeek}
                                        //defaultValue={this.state.hoursPerWeek || this.retrieveJobType().minimumHoursPerWeek}
                                        // CS 7/11/22: support for newly introduced DefaultHoursPerWeek property
                                        defaultValue={this.retrieveJobType().defaultHoursPerWeek}
                                        step={1}
                                        onChange={this.selectHoursPerWeek.bind(this)} />

                                    <h6 className="text-left mb-0">{_("text.hoursPerWeek")}: {this.retrieveHoursPerWeek()}</h6>
                                </FormGroup>

                                <FormGroup className="noselect">
                                    <Slider className="mb-1" min={0} max={24} step={1} onChange={this.selectAvailableIn.bind(this)}
                                        defaultValue={this.state.availableIn || 0} />
                                    <h6 className="text-left mb-0"> {this.retrieveAvailableIn()}</h6>
                                </FormGroup>
                            </div>

                            {/* STANDARD WEEKDAYS */}
                            <div className="two-columns-grid">
                                <span className='span-grid-row title'>{_(langWeekdays)}</span>
                                <FormGroup>
                                    <SelectInputComponent
                                        onSelect={this.selectMonday.bind(this)}
                                        onFilter={optionFilter(this.retrieveShifts())}
                                        submitted={this.state.submitted}
                                        disabled={!this.retrieveJobType().canAdjustShifts}
                                        placeholder={_("placeholder.monday")}
                                        default={this.state.monday || fullDayWork} />
                                </FormGroup>
                                <FormGroup>
                                    <SelectInputComponent
                                        onSelect={this.selectTuesday.bind(this)}
                                        onFilter={optionFilter(this.retrieveShifts())}
                                        submitted={this.state.submitted}
                                        disabled={!this.retrieveJobType().canAdjustShifts}
                                        placeholder={_("placeholder.tuesday")}
                                        default={this.state.tuesday || fullDayWork} />
                                </FormGroup>
                                <FormGroup>
                                    <SelectInputComponent
                                        onSelect={this.selectWednesday.bind(this)}
                                        onFilter={optionFilter(this.retrieveShifts())}
                                        submitted={this.state.submitted}
                                        disabled={!this.retrieveJobType().canAdjustShifts}
                                        placeholder={_("placeholder.wednesday")}
                                        default={this.state.wednesday || fullDayWork} />
                                </FormGroup>
                                <FormGroup>
                                    <SelectInputComponent
                                        onSelect={this.selectThursday.bind(this)}
                                        onFilter={optionFilter(this.retrieveShifts())}
                                        submitted={this.state.submitted}
                                        disabled={!this.retrieveJobType().canAdjustShifts}
                                        placeholder={_("placeholder.thursday")}
                                        default={this.state.thursday || fullDayWork} />
                                </FormGroup>
                                <FormGroup>
                                    <SelectInputComponent
                                        onSelect={this.selectFriday.bind(this)}
                                        onFilter={optionFilter(this.retrieveShifts())}
                                        submitted={this.state.submitted}
                                        disabled={!this.retrieveJobType().canAdjustShifts}
                                        placeholder={_("placeholder.friday")}
                                        default={this.state.friday || fullDayWork} />
                                </FormGroup>
                                <FormGroup>
                                    <SelectInputComponent
                                        onSelect={this.selectSaturday.bind(this)}
                                        onFilter={optionFilter(this.retrieveShifts())}
                                        submitted={this.state.submitted}
                                        disabled={!this.retrieveJobType().canAdjustShifts}
                                        placeholder={_("placeholder.saturday")}
                                        default={this.state.saturday || fullDayWork} />
                                </FormGroup>
                                {/* NO SUNDAY for PHARMACY EDITION */}
                                {ConfigController.isPharmacyEdition() == false && 
                                    <FormGroup>
                                        <SelectInputComponent
                                            onSelect={this.selectSunday.bind(this)}
                                            onFilter={optionFilter(this.retrieveShifts())}
                                            submitted={this.state.submitted}
                                            disabled={!this.retrieveJobType().canAdjustShifts}
                                            placeholder={_("placeholder.sunday")}
                                            default={this.state.sunday || fullDayWork} />
                                    </FormGroup>
                                }

                            </div>

                            {/* FLEXIBLE WEEK AND ALTERNATE WEEK TOGGLES */}
                            <span className='span-grid-row title left-right-spaced'>
                                {/* CS 29/9/22 ADDED FLEXIBLE WEEK SUPPORT */}
                                {/* FLEXIBLE WEEK only for PHARMACY */}
                                {ConfigController.isPharmacyEdition() == true && 
                                    <FormGroup className="m-0 mb-1 row">
                                        <label className="custom-toggle my-auto">
                                            <input type="checkbox" checked={this.state.flexibleCalendarWeek.id === true} 
                                                    onChange={this.toggleFlexibleCalendarWeek.bind(this)} />
                                            <span className="custom-toggle-slider rounded-circle"></span>
                                        </label>
                                        <h5 className="col-auto m-0">{_("placeholder.titleFlexibleCalendarWeek")}</h5>
                                    </FormGroup>
                                }
                                {/* CS:28/1/22 ALTERNATE WEEK TOGGLE */}
                                <FormGroup className="m-0 mb-1 row">
                                    <label className="custom-toggle my-auto">
                                        <input type="checkbox" checked={this.state.alternateWeekdaysOpen} 
                                                onChange={this.toggleAlternateWeekdaysOpen.bind(this)} />
                                        <span className="custom-toggle-slider rounded-circle"></span>
                                    </label>
                                    <h5 className="col-auto m-0">{_(langAlternateWeekdays)}</h5>
                                </FormGroup>
                            </span>

                            {/* ALTERNATE WEEKDAYS */}
                            { this.state.alternateWeekdaysOpen && <div className="two-columns-grid">

                                <FormGroup>
                                    <SelectInputComponent
                                        onSelect={this.selectAlternateMonday.bind(this)}
                                        onFilter={optionFilter(this.retrieveShifts())}
                                        submitted={this.state.submitted}
                                        disabled={!this.retrieveJobType().canAdjustShifts}
                                        placeholder={_("placeholder.altMonday")}
                                        // default={this.state.altMonday || fullDayWork}
                                        // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                        value={this.state.altMonday || fullDayWork} />
                                </FormGroup>
                                <FormGroup>
                                    <SelectInputComponent
                                        onSelect={this.selectAlternateTuesday.bind(this)}
                                        onFilter={optionFilter(this.retrieveShifts())}
                                        submitted={this.state.submitted}
                                        disabled={!this.retrieveJobType().canAdjustShifts}
                                        placeholder={_("placeholder.altTuesday")}
                                        // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                        value={this.state.altTuesday || fullDayWork} />
                                </FormGroup>
                                <FormGroup>
                                    <SelectInputComponent
                                        onSelect={this.selectAlternateWednesday.bind(this)}
                                        onFilter={optionFilter(this.retrieveShifts())}
                                        submitted={this.state.submitted}
                                        disabled={!this.retrieveJobType().canAdjustShifts}
                                        placeholder={_("placeholder.altWednesday")}
                                        // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                        value={this.state.altWednesday || fullDayWork} />
                                </FormGroup>
                                <FormGroup>
                                    <SelectInputComponent
                                        onSelect={this.selectAlternateThursday.bind(this)}
                                        onFilter={optionFilter(this.retrieveShifts())}
                                        submitted={this.state.submitted}
                                        disabled={!this.retrieveJobType().canAdjustShifts}
                                        placeholder={_("placeholder.altThursday")}
                                        // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                        value={this.state.altThursday || fullDayWork} />
                                </FormGroup>
                                <FormGroup>
                                    <SelectInputComponent
                                        onSelect={this.selectAlternateFriday.bind(this)}
                                        onFilter={optionFilter(this.retrieveShifts())}
                                        submitted={this.state.submitted}
                                        disabled={!this.retrieveJobType().canAdjustShifts}
                                        placeholder={_("placeholder.altFriday")}
                                        // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                        value={this.state.altFriday || fullDayWork} />
                                </FormGroup>
                                <FormGroup>
                                    <SelectInputComponent
                                        onSelect={this.selectAlternateSaturday.bind(this)}
                                        onFilter={optionFilter(this.retrieveShifts())}
                                        submitted={this.state.submitted}
                                        disabled={!this.retrieveJobType().canAdjustShifts}
                                        placeholder={_("placeholder.altSaturday")}
                                        // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                        value={this.state.altSaturday || fullDayWork} />
                                </FormGroup>
                                {/* NO SUNDAY for PHARMACY EDITION */}
                                {ConfigController.isPharmacyEdition() == false && 
                                    <FormGroup>
                                        <SelectInputComponent
                                            onSelect={this.selectAlternateSunday.bind(this)}
                                            onFilter={optionFilter(this.retrieveShifts())}
                                            submitted={this.state.submitted}
                                            disabled={!this.retrieveJobType().canAdjustShifts}
                                            placeholder={_("placeholder.altSunday")}
                                            // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                            value={this.state.altSunday || notAvailableForWork} />
                                    </FormGroup>
                                }

                            </div> }

                            {/* OTHER QUESTIONS */}
                            <div className="two-columns-grid">

                                {/* Andere Einstellungen title */}
                                {/* <span className='span-grid-row title'>{_("placeholder.titleOtherAvailability")}</span> */}

                                {/* CS 20/4/22: only pharma edition has NIGHTSHIFTS */}
                                {ConfigController.isPharmacyEdition() &&
                                    <FormGroup>
                                        <SelectInputComponent
                                            onSelect={this.selectNightlyShift.bind(this)}
                                            onFilter={optionFilter(this.retrieveBoolDecisions())}
                                            submitted={this.state.submitted}
                                            disabled={!this.retrieveJobType().suitableForNightlyShifts}
                                            placeholder={_("placeholder.nightlyShifts")}
                                            default={this.state.nightlyShift || this.retrieveBoolDecisions()[0]} />
                                    </FormGroup>
                                }
                                <FormGroup>
                                    <SelectInputComponent
                                        onSelect={this.selectReplacement.bind(this)}
                                        onFilter={optionFilter(this.retrieveBoolDecisions())}
                                        submitted={this.state.submitted}
                                        disabled={!this.retrieveJobType().suitableForReplacments}
                                        placeholder={ConfigController.isHealthcareEdition() ? _("placeholder.health.replacement") : _("placeholder.replacement")}
                                        default={this.state.replacement || this.retrieveBoolDecisions()[0]} />
                                </FormGroup>

                                {/* AVAILABILITY TIME PERIODS - only if temp job availability has been selected */}
                                {/* CS 11/2/22: added new component for handling new featured availability time periods */}
                                {this.state.replacement && this.state.replacement.id == true && 
                                    <div className="span-grid-row my-2">
                                        <ProfileAvailabilityComponent data={this.state.availabilities} onChange={this.onChangeAvailability.bind(this)} ></ProfileAvailabilityComponent>
                                    </div>
                                }

                                {/* OverPay only for PHARMACIES */}
                                { ConfigController.isPharmacyEdition() && this.props.type !== "person" &&
                                    <FormGroup>
                                        <SelectInputComponent
                                            onSelect={this.selectOverpay.bind(this)}
                                            onFilter={optionFilter(this.retrieveBoolDecisions())}
                                            submitted={this.state.submitted}
                                            disabled={false}
                                            placeholder={_("placeholder.overpay")}
                                            default={this.state.overpay || this.retrieveBoolDecisions()[0]} />
                                    </FormGroup>
                                }
                                
                                {ConfigController.isPharmacyEdition() &&
                                    <FormGroup>
                                        <SelectInputComponent
                                            onSelect={this.selectAccomodation.bind(this)}
                                            onFilter={optionFilter(this.retrieveBoolDecisions())}
                                            submitted={this.state.submitted}
                                            disabled={false}
                                            placeholder={_("placeholder.accomodation")}
                                            default={this.state.accomodation || this.retrieveBoolDecisions()[0]} />
                                    </FormGroup>
                                }
                                { ConfigController.isPharmacyEdition() && selectedPharmacist == true &&
                                    <FormGroup>
                                        <SelectInputComponent
                                            onSelect={this.selectManagement.bind(this)}
                                            onFilter={optionFilter(this.retrieveBoolDecisions())}
                                            submitted={this.state.submitted}
                                            disabled={false}
                                            placeholder={_("placeholder.management")}
                                            default={this.state.management || this.retrieveBoolDecisions()[0]} />
                                    </FormGroup>
                                }
                                { ConfigController.isPharmacyEdition() && selectedPharmacist == true &&
                                    <FormGroup>
                                        <SelectInputComponent
                                            onSelect={this.selectLicenceHolder.bind(this)}
                                            onFilter={optionFilter(this.retrieveBoolDecisions())}
                                            submitted={this.state.submitted}
                                            disabled={false}
                                            placeholder={_("placeholder.licenceHolder")}
                                            default={this.state.licenceHolder || this.retrieveBoolDecisions()[0]} />
                                    </FormGroup>
                                }
                                {ConfigController.isPharmacyEdition() &&
                                    <FormGroup>
                                        <SelectInputComponent
                                            onSelect={this.selectCovidTester.bind(this)}
                                            onFilter={optionFilter(this.retrieveBoolDecisions())}
                                            submitted={this.state.submitted}
                                            disabled={false}
                                            placeholder={_("placeholder.covidTester")}
                                            default={this.state.covidTester || this.retrieveBoolDecisions()[0]} />
                                    </FormGroup>
                                }
                            </div>

                            <div className="row mb-3">
                                <div className="col-md-6 m-0 py-2">
                                    <Button type="button" className="col-12" color="danger" onClick={(x) => this.props.back()}><i className="fas fa-angle-left"></i> {_("back")}</Button>
                                </div>
                                <div className="offset-md-6 col-md-6 text-right m-0 py-2">
                                    <Button type="submit" className="col-12" color="success">{_("next")} <i className="fas fa-angle-right"></i></Button>
                                </div>
                            </div>
                        </Form>
                    )}>
                </Formik>
            </CenteredComponent>
        );
    }

}