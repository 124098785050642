import React, { Component } from 'react';
import CenteredComponent from '../centeredComponent';
import { _ } from '../../../controllers/languageController';
import AuthenticationController from '../../../controllers/authenticationController';
import SelectInputComponent from '../selectInputComponent';
import ConfigController from '../../../controllers/configController';
import { FormGroup } from 'reactstrap';

import Slider from "rc-slider";
import 'rc-slider/assets/index.css';
import ProfileAvailabilityComponent from '../profile/profileAvailabilityComponent';

export default class MatchCompareComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isPerson: AuthenticationController.isPerson(),
            //jobTypePharmacistsSelected: this.retrieveJob(this.props.value.profile.jobID).code == "jobTypes.pharmacist"
        };
        // CS 31/1/22: introduced new datasets for handling alternative weekdays plus new Sunday
        this.leftShifts = {
            isAlternateWeekSet: false,  // flag indicating if any of the alternative weekdays have been set
            // regular weekdays
            monday: null,
            tuesday: null,
            wednesday: null,
            thursday: null,
            friday: null,
            saturday: null,
            sunday: null,
            // alternate weekdays
            altMonday: null,
            altTuesday: null,
            altWednesday: null,
            altThursday: null,
            altFriday: null,
            altSaturday: null,
            altSunday: null,
        }
        this.rightShifts = {
            isAlternateWeekSet: false,  // flag indicating if any of the alternative weekdays have been set
            // regular weekdays
            monday: null,
            tuesday: null,
            wednesday: null,
            thursday: null,
            friday: null,
            saturday: null,
            sunday: null,
            // alternate weekdays
            altMonday: null,
            altTuesday: null,
            altWednesday: null,
            altThursday: null,
            altFriday: null,
            altSaturday: null,
            altSunday: null,
        }
    }

    componentWillMount()
    {
        //
        // load shift data into new class structures and set flag accordingly
        //
        this.leftShifts = this.mapWeekDays(this.props.value.profile.shifts);
        this.rightShifts = this.mapWeekDays(this.props.value.otherProfile.shifts);
    }

    mapWeekDays ( shifts )
    {
        var result = {};
    
        //
        // from: {id: '91569e09-1f7a-4def-90d7-edab5fb8efd4', item: 'Nein', raw: 'shifts.3'}
        // to: {shiftID: 'fa3a8270-45a7-492a-b5bf-4c4769adb2a3', dayOfWeek: 4}
        //
        // 
        //
        var fullDayWork = ConfigController.retrieveShifts()[1];
        var notAvailableForWork = ConfigController.retrieveShifts()[0];
    
        result.isAlternateWeekSet = false;

        // Monday (defaults to full day)
        {
            var item = shifts.filter(x => x.dayOfWeek === 0);
            item.length > 0 ? result.monday = item[0].shiftID : result.monday = fullDayWork.id;
        }
        // Tuesday (defaults to full day)
        {
            var item = shifts.filter(x => x.dayOfWeek === 1);
            item.length > 0 ? result.tuesday = item[0].shiftID : result.tuesday = fullDayWork.id;
        }
        // Wednesday (defaults to full day)
        {
            var item = shifts.filter(x => x.dayOfWeek === 2);
            item.length > 0 ? result.wednesday = item[0].shiftID : result.wednesday = fullDayWork.id;
        }
        // Thursday (defaults to full day)
        {
            var item = shifts.filter(x => x.dayOfWeek === 3);
            item.length > 0 ? result.thursday = item[0].shiftID : result.thursday = fullDayWork.id;
        }
        // Friday (defaults to full day)
        {
            var item = shifts.filter(x => x.dayOfWeek === 4);
            item.length > 0 ? result.friday = item[0].shiftID : result.friday = fullDayWork.id;
        }
        // Saturday (defaults to full day)
        {
            var item = shifts.filter(x => x.dayOfWeek === 5);
            item.length > 0 ? result.saturday = item[0].shiftID : result.saturday = fullDayWork.id;
        }
        // sunday (defaults to not available)
        {
            var item = shifts.filter(x => x.dayOfWeek === 6);
            item.length > 0 ? result.sunday = item[0].shiftID : result.sunday = notAvailableForWork.id;
        }
        //
        // alternative weekdays (optional), defaults to equivalent from regular weekday
        //
        {
            var item = shifts.filter(x => x.dayOfWeek === 20);
            item.length > 0 ? result.altMonday = item[0].shiftID : result.altMonday = result.monday;
        }
        {
            var item = shifts.filter(x => x.dayOfWeek === 21);
            item.length > 0 ? result.altTuesday = item[0].shiftID : result.altTuesday = result.tuesday;
        }
        {
            var item = shifts.filter(x => x.dayOfWeek === 22);
            item.length > 0 ? result.altWednesday = item[0].shiftID : result.altWednesday = result.wednesday;
        }
        {
            var item = shifts.filter(x => x.dayOfWeek === 23);
            item.length > 0 ? result.altThursday = item[0].shiftID : result.altThursday = result.thursday;
        }
        {
            var item = shifts.filter(x => x.dayOfWeek === 24);
            item.length > 0 ? result.altFriday = item[0].shiftID : result.altFriday = result.friday;
        }
        {
            var item = shifts.filter(x => x.dayOfWeek === 25);
            item.length > 0 ? result.altSaturday = item[0].shiftID : result.altSaturday = result.saturday;
        }
        {
            var item = shifts.filter(x => x.dayOfWeek === 26);
            item.length > 0 ? result.altSunday = item[0].shiftID : result.altSunday = result.sunday;
        }

        // isAlternateWeekSet
        if ( !result.isAlternateWeekSet && result.monday != result.altMonday) result.isAlternateWeekSet = true;
        if ( !result.isAlternateWeekSet && result.tuesday != result.altTuesday) result.isAlternateWeekSet = true;
        if ( !result.isAlternateWeekSet && result.wednesday != result.altWednesday) result.isAlternateWeekSet = true;
        if ( !result.isAlternateWeekSet && result.thursday != result.altThursday) result.isAlternateWeekSet = true;
        if ( !result.isAlternateWeekSet && result.friday != result.altFriday) result.isAlternateWeekSet = true;
        if ( !result.isAlternateWeekSet && result.saturday != result.altSaturday) result.isAlternateWeekSet = true;
        if ( !result.isAlternateWeekSet && result.sunday != result.altSunday) result.isAlternateWeekSet = true;
    
        return result;
    
    }

    retrieveType(myType) {

        if (AuthenticationController.isPerson()) {
            if (myType) return "person";
            return "pharmacy";
        }

        if (myType) return "pharmacy";

        return "person";
    }

    retrieveDistance(distance) {
        if (distance <= 0) {
            return _("distance.0");
        } else if (distance >= 1000) {
            return _("distance.1000");
        }
        return distance + " " + _("km");
    }

    retrieveHoursPerWeek(hoursPerWeek) {
        if ( ConfigController.isPharmacyEdition())
            return `${hoursPerWeek} ${_("text.hour")} / ${hoursPerWeek / 4} ${_("text.tenth")}`;

        return `${hoursPerWeek} ${_("text.hour")}`;
    }

    retrieveAvailableIn(availableIn) {
        if (availableIn <= 0) {
            return _("available.now");
        }
        return `${availableIn} ${_("text.week")} / ${availableIn / 4} ${_("text.month")}`;
    }

    retrieveJob(jobId) {
        return ConfigController.retrieveJobTypes().filter(x => x.id === jobId)[0];
    }

    retrieveShift(shiftId) {
        return ConfigController.retrieveShifts().filter(x => x.id === shiftId)[0];
    }

    retrieveShiftForDay(shift, dayOfWeek) {
        return shift.filter(x => x.dayOfWeek === dayOfWeek)[0].shiftID;
    }

    retrieveMatchingComponent(item) {
        return [(
            <h4 className="mb-2 text-left">{_(`${item}.text`)}</h4>
        ), (
            <FormGroup className="row mb-2">
                <div className="col-6 pr-1">
                    <div className="text-left">
                        {this.retrieveMatchingItems(item, this[`map${item.charAt(0).toUpperCase()}${item.substring(1)}`])}
                    </div>
                </div>
                <div className="col-6 pl-1">
                    <div className="text-left">
                        {this.retrieveMatchingItems(item, this[`map${item.charAt(0).toUpperCase()}${item.substring(1)}`], true)}
                    </div>
                </div>
            </FormGroup>
        )];
    }

    retrieveMatchingItems(item, mapper, other) {
        let items = this.props.value.profile[item];
        let otherItems = this.props.value.otherProfile[item];

        if (other) {
            items = otherItems;
            otherItems = this.props.value.profile[item];
        }

        if (items.length == 0) {
            let id = Math.random();
            return (
                <div className={`custom-control custom-checkbox mb-3`}>
                    <input checked={false} className="custom-control-input" id={id} type="checkbox" />
                    <label className="custom-control-label noselect" htmlFor={id}>{_("empty")}</label>
                </div>
            );
        }

        return [
            ...this.retrieveMatching(items, otherItems, mapper, true)
                .map(x => (
                    <div key={x.id} className="custom-control custom-checkbox is-valid mb-3">
                        <input checked={true} className="custom-control-input" id={x.id} type="checkbox" />
                        <label className="custom-control-label noselect" htmlFor={x.id}>{_(x.item)}</label>
                    </div>
                )),
            ...this.retrieveMatching(items, otherItems, mapper, false)
                .map(x => (
                    <div key={x.id} className="custom-control custom-checkbox mb-3">
                        <input checked={false} className="custom-control-input" id={x.id} type="checkbox" />
                        <label className="custom-control-label noselect" htmlFor={x.id}>{_(x.item)}</label>
                    </div>
                )),
        ];
    }

    retrieveMatching(items, otherItems, mapper, match) {
        let inverse = (value, invert) => {
            if (invert) {
                return !value;
            }
            return value;
        }

        return items.filter(x => inverse(otherItems.filter(y => y == x).length > 0, !match))
            .map(x => mapper(x))
            .sort((a, b) => a.item.localeCompare(b.item));
    }

    mapLanguages(id) {
        return ConfigController.retrieveLanguages().filter(x => x.id === id)[0];
    }

    mapCashierSystems(id) {
        return ConfigController.retrieveCashierSystemsAll().filter(x => x.id === id).map(x => { return { id: x.id, item: x.item.split("|")[0] }; })[0];
    }

    mapCustomerSkills(id) {
        return ConfigController.retrieveCustomerSkills().filter(x => x.id === id)[0];
    }

    mapAdminSkills(id) {
        return ConfigController.retrieveAdminSkills().filter(x => x.id === id)[0];
    }

    mapLogisticSkills(id) {
        return ConfigController.retrieveLogisticSkills().filter(x => x.id === id)[0];
    }

    mapManufacturSkills(id) {
        return ConfigController.retrieveManufacturSkills().filter(x => x.id === id)[0];
    }

    mapMarketingSkills(id) {
        return ConfigController.retrieveMarketingSkills().filter(x => x.id === id)[0];
    }

    renderGeneralAvailability()
    {
        var result = [];

        result.push(
            <h3 className="mb-2 text-left">{_("text.profile.availability")}</h3>
        )

        result.push(
            <FormGroup className="pt-2 mb-2 two-columns-grid">
                <div>
                    <Slider className="mb-1" disabled min={0} max={40} step={1} defaultValue={this.props.value.profile.hoursPerWeek} />
                    <h6 className={`text-left mb-0 ${this.props.value.profile.hoursPerWeek === this.props.value.otherProfile.hoursPerWeek ? "is-valid" : "is-invalid"}`}>
                        {_("text.hoursPerWeek")}: {this.retrieveHoursPerWeek(this.props.value.profile.hoursPerWeek)}
                    </h6>
                </div>
                <div>
                    <Slider className="mb-1" disabled min={0} max={40} step={1} defaultValue={this.props.value.otherProfile.hoursPerWeek} />
                    <h6 className={`text-left mb-0 ${this.props.value.profile.hoursPerWeek === this.props.value.otherProfile.hoursPerWeek ? "is-valid" : "is-invalid"}`}>
                        {_("text.hoursPerWeek")}: {this.retrieveHoursPerWeek(this.props.value.otherProfile.hoursPerWeek)}
                    </h6>
                </div>
            </FormGroup>
        )

        result.push(
            <FormGroup className="pt-2 mb-2 two-columns-grid">
                <div>
                    <Slider className="mb-1" disabled min={0} max={24} step={1} defaultValue={this.props.value.profile.availableInWeeks} />
                    <h6 className={`text-left mb-0 ${this.props.value.profile.availableInWeeks === this.props.value.otherProfile.availableInWeeks ? "is-valid" : "is-invalid"}`}>
                        {_("text.availableIn")}: {this.retrieveAvailableIn(this.props.value.profile.availableInWeeks)}
                    </h6>
                </div>
                <div>
                    <Slider className="mb-1" disabled min={0} max={24} step={1} defaultValue={this.props.value.otherProfile.availableInWeeks} />
                    <h6 className={`text-left mb-0 ${this.props.value.profile.availableInWeeks === this.props.value.otherProfile.availableInWeeks ? "is-valid" : "is-invalid"}`}>
                        {_("text.availableIn")}: {this.retrieveAvailableIn(this.props.value.otherProfile.availableInWeeks)}
                    </h6>
                </div>
            </FormGroup>
        )

        return result;
    }

    renderStandardWeekdays()
    {
        // flag if the selected job type is pharmacist
        //var selectedPharmacist = this.state.job.jobType.code == "jobTypes.pharmacist";

        var result = [];

        result.push(
            <span className='title'>{_("placeholder.titleWeekdays")}</span>
        )

        result.push(
            <FormGroup className="row pt-2 mb-0">
                <div className="col-6 pr-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.monday == this.rightShifts.monday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.monday")}
                        default={this.retrieveShift(this.leftShifts.monday)} />
                </div>
                <div className="col-6 pl-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.monday == this.rightShifts.monday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.monday")}
                        default={this.retrieveShift(this.rightShifts.monday)} />
                </div>
            </FormGroup>
        )

        result.push(
            <FormGroup className="row pt-2 mb-0">
                <div className="col-6 pr-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.tuesday == this.rightShifts.tuesday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.tuesday")}
                        default={this.retrieveShift(this.leftShifts.tuesday)} />
                </div>
                <div className="col-6 pl-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.tuesday == this.rightShifts.tuesday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.tuesday")}
                        default={this.retrieveShift(this.rightShifts.tuesday)} />
                </div>
            </FormGroup>
        )

        result.push(
            <FormGroup className="row pt-2 mb-0">
                <div className="col-6 pr-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.wednesday == this.rightShifts.wednesday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.wednesday")}
                        default={this.retrieveShift(this.leftShifts.wednesday)} />
                </div>
                <div className="col-6 pl-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.wednesday == this.rightShifts.wednesday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.wednesday")}
                        default={this.retrieveShift(this.rightShifts.wednesday)} />
                </div>
            </FormGroup>
        )

        result.push(
                <FormGroup className="row pt-2 mb-0">
                <div className="col-6 pr-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.thursday == this.rightShifts.thursday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.thursday")}
                        default={this.retrieveShift(this.leftShifts.thursday)} />
                </div>
                <div className="col-6 pl-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.thursday == this.rightShifts.thursday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.thursday")}
                        default={this.retrieveShift(this.rightShifts.thursday)} />
                </div>
            </FormGroup>
        )

        result.push(

            <FormGroup className="row pt-2 mb-0">
                <div className="col-6 pr-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.friday == this.rightShifts.friday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.wednesday")}
                        default={this.retrieveShift(this.leftShifts.wednesday)} />
                </div>
                <div className="col-6 pl-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.friday == this.rightShifts.friday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.friday")}
                        default={this.retrieveShift(this.rightShifts.friday)} />
                </div>
            </FormGroup>
        )

        result.push(
            <FormGroup className="row pt-2 mb-0">
                <div className="col-6 pr-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.saturday == this.rightShifts.saturday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.saturday")}
                        default={this.retrieveShift(this.leftShifts.saturday)} />
                </div>
                <div className="col-6 pl-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.saturday == this.rightShifts.saturday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.saturday")}
                        default={this.retrieveShift(this.rightShifts.saturday)} />
                </div>
            </FormGroup>
        )

        // NO SUNDAY for PHARMACY edition
        if ( ConfigController.isPharmacyEdition() == false )
        {
            result.push(

                <FormGroup className="row pt-2 mb-0">
                    <div className="col-6 pr-1">
                        <SelectInputComponent disabled shadow
                            className={this.leftShifts.sunday == this.rightShifts.sunday ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.sunday")}
                            default={this.retrieveShift(this.leftShifts.sunday)} />
                    </div>
                    <div className="col-6 pl-1">
                        <SelectInputComponent disabled shadow
                            className={this.leftShifts.sunday == this.rightShifts.sunday ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.sunday")}
                            default={this.retrieveShift(this.rightShifts.sunday)} />
                    </div>
                </FormGroup>
            )
        }

        // 29/9/22: added new flexibleCalendarWeek flag (ProfileModel)
        // 17/10/22: moved the FLEXIBLE block to the end with y-gaps
        result.push(
            <FormGroup className="row pt-2 mb-0 my-3">
                <div className="col-6 pr-1">
                    <SelectInputComponent disabled shadow
                        className={this.props.value.profile.flexibleCalendarWeek == this.props.value.otherProfile.flexibleCalendarWeek ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.titleFlexibleCalendarWeek")}
                        default={{ id: "none", item: _(this.props.value.profile.flexibleCalendarWeek + "") }} />
                </div>
                <div className="col-6 pl-1">
                    <SelectInputComponent disabled shadow
                        className={this.props.value.otherProfile.flexibleCalendarWeek == this.props.value.profile.flexibleCalendarWeek ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.titleFlexibleCalendarWeek")}
                        default={{ id: "none", item: _(this.props.value.otherProfile.flexibleCalendarWeek + "") }} />
                </div>
            </FormGroup>
        )


        return result;
    }

    renderAlternateWeekdays()
    {
        if ( this.leftShifts.isAlternateWeekSet == false && this.rightShifts.isAlternateWeekSet == false ) return null;

        var result = [];

        result.push(
            <span className='title mt-2'>{_("placeholder.titleAlternateWeekdays")}</span>
        );
        result.push(

            <FormGroup className="row pt-2 mb-0">
                <div className="col-6 pr-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.altMonday == this.rightShifts.altMonday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.altMonday")}
                        default={this.retrieveShift(this.leftShifts.altMonday)} />
                </div>
                <div className="col-6 pl-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.altMonday == this.rightShifts.altMonday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.altMonday")}
                        default={this.retrieveShift(this.rightShifts.altMonday)} />
                </div>
            </FormGroup>

        );
        result.push(

            <FormGroup className="row pt-2 mb-0">
                <div className="col-6 pr-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.altTuesday == this.rightShifts.altTuesday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.altTuesday")}
                        default={this.retrieveShift(this.leftShifts.altTuesday)} />
                </div>
                <div className="col-6 pl-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.altTuesday == this.rightShifts.altTuesday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.altTuesday")}
                        default={this.retrieveShift(this.rightShifts.altTuesday)} />
                </div>
            </FormGroup>

        );
        result.push(

            <FormGroup className="row pt-2 mb-0">
                <div className="col-6 pr-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.altWednesday == this.rightShifts.altWednesday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.altWednesday")}
                        default={this.retrieveShift(this.leftShifts.altWednesday)} />
                </div>
                <div className="col-6 pl-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.altWednesday == this.rightShifts.altWednesday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.altWednesday")}
                        default={this.retrieveShift(this.rightShifts.altWednesday)} />
                </div>
            </FormGroup>

        );
        result.push(

            <FormGroup className="row pt-2 mb-0">
                <div className="col-6 pr-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.altThursday == this.rightShifts.altThursday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.altThursday")}
                        default={this.retrieveShift(this.leftShifts.altThursday)} />
                </div>
                <div className="col-6 pl-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.altThursday == this.rightShifts.altThursday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.altThursday")}
                        default={this.retrieveShift(this.rightShifts.altThursday)} />
                </div>
            </FormGroup>

        );
        result.push(

            <FormGroup className="row pt-2 mb-0">
                <div className="col-6 pr-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.altFriday == this.rightShifts.altFriday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.altFriday")}
                        default={this.retrieveShift(this.leftShifts.altFriday)} />
                </div>
                <div className="col-6 pl-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.altFriday == this.rightShifts.altFriday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.altFriday")}
                        default={this.retrieveShift(this.rightShifts.altFriday)} />
                </div>
            </FormGroup>

        );
        result.push(

            <FormGroup className="row pt-2 mb-0">
                <div className="col-6 pr-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.altSaturday == this.rightShifts.altSaturday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.altSaturday")}
                        default={this.retrieveShift(this.leftShifts.altSaturday)} />
                </div>
                <div className="col-6 pl-1">
                    <SelectInputComponent disabled shadow
                        className={this.leftShifts.altSaturday == this.rightShifts.altSaturday ? "is-valid" : "is-invalid"}
                        placeholder={_("placeholder.altSaturday")}
                        default={this.retrieveShift(this.rightShifts.altSaturday)} />
                </div>
            </FormGroup>

        );

        // NO SUNDAY for PHARMACY edition
        if ( ConfigController.isPharmacyEdition() == false )
        {
            result.push(

                <FormGroup className="row pt-2 mb-0">
                    <div className="col-6 pr-1">
                        <SelectInputComponent disabled shadow
                            className={this.leftShifts.altSunday == this.rightShifts.altSunday ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.altSunday")}
                            default={this.retrieveShift(this.leftShifts.altSunday)} />
                    </div>
                    <div className="col-6 pl-1">
                        <SelectInputComponent disabled shadow
                            className={this.leftShifts.altSunday == this.rightShifts.altSunday ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.altSunday")}
                            default={this.retrieveShift(this.rightShifts.altSunday)} />
                    </div>
                </FormGroup>

            );
        }

        return result;
    }

    renderOtherAttributes()
    {
        var isPharmacyEdition = ConfigController.isPharmacyEdition();
        var isHealthcareEdition = ConfigController.isHealthcareEdition();

        var result = [];

        result.push(
            <span className='title my-2'>{_("placeholder.titleOtherAvailability")}</span>
        )

        if ( isPharmacyEdition)
        {
            result.push(
                <FormGroup className="row mb-2">
                    <div className="col-6 pr-1">
                        <SelectInputComponent disabled shadow
                            className={this.props.value.profile.availableForNightlyShifts === this.props.value.otherProfile.availableForNightlyShifts ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.nightlyShifts")}
                            default={{ id: "none", item: _(this.props.value.profile.availableForNightlyShifts + "") }} />
                    </div>
                    <div className="col-6 pl-1">
                        <SelectInputComponent disabled shadow
                            className={this.props.value.profile.availableForNightlyShifts === this.props.value.otherProfile.availableForNightlyShifts ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.nightlyShifts")}
                            default={{ id: "none", item: _(this.props.value.otherProfile.availableForNightlyShifts + "") }} />
                    </div>
                </FormGroup>
            )
            }

        result.push(

            <FormGroup className="row mb-2">
                <div className="col-6 pr-1">
                    <SelectInputComponent disabled shadow
                        className={this.props.value.profile.availableAsReplacement === this.props.value.otherProfile.availableAsReplacement ? "is-valid" : "is-invalid"}
                        placeholder={isHealthcareEdition ? _("placeholder.health.replacement") : _("placeholder.replacement")}
                        default={{ id: "none", item: _(this.props.value.profile.availableAsReplacement + "") }} />
                </div>
                <div className="col-6 pl-1">
                    <SelectInputComponent disabled shadow
                        className={this.props.value.profile.availableAsReplacement === this.props.value.otherProfile.availableAsReplacement ? "is-valid" : "is-invalid"}
                        placeholder={isHealthcareEdition ? _("placeholder.health.replacement") : _("placeholder.replacement")}
                        default={{ id: "none", item: _(this.props.value.otherProfile.availableAsReplacement + "") }} />
                </div>
            </FormGroup>
        )

        // if both side match for temp jobs - show the availabilities (if present)
        // availabilities of the left side party (current user)
        //this.leftAvailabilities = this.props.value.profile.availabilities
        // availabilities of the right side party (other party to be compared with)
        //this.RightAvailabilities = this.props.value.otherProfile.availabilities
        if ( this.props.value.profile.availableAsReplacement === this.props.value.otherProfile.availableAsReplacement)
        {
            var myAvailabilities = this.props.value.profile.availabilities;
            var otherAvailabilities = this.props.value.otherProfile.availabilities;

            // only render if any of the lists have some entries - skip otherwise
            if ( myAvailabilities.length > 0 || otherAvailabilities.length > 0 )
            {
                result.push(

                    <FormGroup className="row mb-2">
                        <div className="col-6 pr-1">
                            <ProfileAvailabilityComponent data={myAvailabilities} mode="view"></ProfileAvailabilityComponent>
                        </div>
                        <div className="col-6 pl-1">
                            <ProfileAvailabilityComponent data={otherAvailabilities} mode="view"></ProfileAvailabilityComponent>
                        </div>
                    </FormGroup>
                )
            }

        }

        // CS 12/8/21 - added two new fields meant and Accomodation
        // CS 19/10/21 - display only for job-seekers and then only the pharmacist side of Overpay
        if ( isPharmacyEdition && this.state.isPerson)
        {
            result.push(

                <FormGroup className="row mb-2">
                    <div className="col-6 pr-1">
                        <SelectInputComponent disabled shadow placeholder=" " default=" " className=""/>
                    </div>
                    <div className="col-6 pl-1">
                        <SelectInputComponent disabled shadow
                            className={this.props.value.profile.availableForOverpay === this.props.value.otherProfile.availableForOverpay ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.overpay")}
                            default={{ id: "none", item: _(this.props.value.otherProfile.availableForOverpay + "") }} />
                    </div>
                </FormGroup>
            )
        }

        if ( isPharmacyEdition)
        {
            result.push(

                <FormGroup className="row mb-2">
                    <div className="col-6 pr-1">
                        <SelectInputComponent disabled shadow
                            className={this.props.value.profile.accomodation === this.props.value.otherProfile.accomodation ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.accomodation")}
                            default={{ id: "none", item: _(this.props.value.profile.accomodation + "") }} />
                    </div>
                    <div className="col-6 pl-1">
                        <SelectInputComponent disabled shadow
                            className={this.props.value.profile.accomodation === this.props.value.otherProfile.accomodation ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.accomodation")}
                            default={{ id: "none", item: _(this.props.value.otherProfile.accomodation + "") }} />
                    </div>
                </FormGroup>
            )

            result.push(

                /* CS 24/9/21 - added new field Management */
                <FormGroup className="row mb-2">
                    <div className="col-6 pr-1">
                        <SelectInputComponent disabled shadow
                            className={this.props.value.profile.management === this.props.value.otherProfile.management ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.management")}
                            default={{ id: "none", item: _(this.props.value.profile.management + "") }} />
                    </div>
                    <div className="col-6 pl-1">
                        <SelectInputComponent disabled shadow
                            className={this.props.value.profile.management === this.props.value.otherProfile.management ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.management")}
                            default={{ id: "none", item: _(this.props.value.otherProfile.management + "") }} />
                    </div>
                </FormGroup>
            )

            result.push(
                /* CS 24/9/21 - added new field LicenceHolder */
                <FormGroup className="row mb-2">
                    <div className="col-6 pr-1">
                        <SelectInputComponent disabled shadow
                            className={this.props.value.profile.licenceHolder === this.props.value.otherProfile.licenceHolder ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.licenceHolder")}
                            default={{ id: "none", item: _(this.props.value.profile.licenceHolder + "") }} />
                    </div>
                    <div className="col-6 pl-1">
                        <SelectInputComponent disabled shadow
                            className={this.props.value.profile.licenceHolder === this.props.value.otherProfile.licenceHolder ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.licenceHolder")}
                            default={{ id: "none", item: _(this.props.value.otherProfile.licenceHolder + "") }} />
                    </div>
                </FormGroup>
            )
    
            result.push(
    
                /* CS 15/10/21 - added new field CovidTester */
                <FormGroup className="row mb-2">
                    <div className="col-6 pr-1">
                        <SelectInputComponent disabled shadow
                            className={this.props.value.profile.covidTester === this.props.value.otherProfile.covidTester ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.covidTester")}
                            default={{ id: "none", item: _(this.props.value.profile.covidTester + "") }} />
                    </div>
                    <div className="col-6 pl-1">
                        <SelectInputComponent disabled shadow
                            className={this.props.value.profile.covidTester === this.props.value.otherProfile.covidTester ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.covidTester")}
                            default={{ id: "none", item: _(this.props.value.otherProfile.covidTester + "") }} />
                    </div>
                </FormGroup>
            )

            // CS 11/7/22: added lindaFlag matching comparison
            // only show if both parties are from 'DE'
            var profileInGermany = this.props.value.profile.postalCountryCode.toUpperCase() == "DE";
            var otherprofileinGermany = this.props.value.otherProfile.postalCountryCode.toUpperCase() == "DE";

            if ( profileInGermany && otherprofileinGermany)
            {
                var leftLindaFlagText = this.state.isPerson ? _("placeholder.lindaExperience") : _("placeholder.lindaMember");
                var rightLindaFlagText = this.state.isPerson ? _("placeholder.lindaMember") : _("placeholder.lindaExperience");

                result.push(

                    <FormGroup className="row mb-2">
                        <div className="col-6 pr-1">
                            <SelectInputComponent disabled shadow
                                className={this.props.value.profile.lindaFlag === this.props.value.otherProfile.lindaFlag ? "is-valid" : "is-invalid"}
                                placeholder={leftLindaFlagText}
                                default={{ id: "none", item: _(this.props.value.profile.lindaFlag + "") }} />
                        </div>
                        <div className="col-6 pl-1">
                            <SelectInputComponent disabled shadow
                                className={this.props.value.profile.lindaFlag === this.props.value.otherProfile.lindaFlag ? "is-valid" : "is-invalid"}
                                placeholder={rightLindaFlagText}
                                default={{ id: "none", item: _(this.props.value.otherProfile.lindaFlag + "") }} />
                        </div>
                    </FormGroup>
                )
            }
    
        }

        return result;
    }

    render() {

        if (ConfigController.isPharmacyEdition()) return this.renderForPharmacyEdition();
        if (ConfigController.isHealthcareEdition()) return this.renderForHealthcareEdition();

        return(
            <h1>!!! UNKNOWN EDITION !!!</h1>
        )

    }

    renderForPharmacyEdition(){

        return (
            <div className="col-12 m-0 p-0">

                <FormGroup className="mb-2">
                    <SelectInputComponent disabled shadow
                        className="is-valid"
                        placeholder={_(`placeholder.jobTypes.${this.retrieveType(true)}`)}
                        default={this.retrieveJob(this.props.value.profile.jobID)} />
                </FormGroup>

                <hr className="mb-3 mt-4" />

                <FormGroup className="row mb-0">
                    <div className="col-6 pr-1 text-left">
                        <h4>{_("my.profile")}</h4>
                    </div>
                    <div className="col-6 pl-1 text-left">
                        <h4>{_("others.profile")}</h4>
                    </div>
                </FormGroup>

                <FormGroup className="row mb-2">
                    <div className="col-6 pr-1">
                        <SelectInputComponent disabled shadow
                            className={this.props.value.otherProfileArea.id === this.props.value.profileArea.id ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.postalArea")}
                            default={{ id: this.props.value.profileArea.id, item: `${this.props.value.profileArea.code} ${this.props.value.profileArea.name}` }} />
                    </div>
                    <div className="col-6 pl-1">
                        <SelectInputComponent disabled shadow
                            className={this.props.value.otherProfileArea.id === this.props.value.profileArea.id ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.postalArea")}
                            default={{ id: this.props.value.otherProfileArea.id, item: `${this.props.value.otherProfileArea.code} ${this.props.value.otherProfileArea.name}` }} />
                    </div>
                </FormGroup>

                <FormGroup className="pt-2 mb-2 two-columns-grid">
                    <div>
                        <Slider className="mb-1" disabled min={0} max={1000} step={1} defaultValue={this.props.value.profile.maximumDistanceToWork} />
                        <h6 className={`text-left mb-0 ${this.props.value.profile.maximumDistanceToWork === this.props.value.otherProfile.maximumDistanceToWork ? "is-valid" : "is-invalid"}`}>
                            {_("distance.radius")} {this.retrieveDistance(this.props.value.profile.maximumDistanceToWork)}
                        </h6>
                    </div>
                    <div>
                        <Slider className="mb-1" disabled min={0} max={1000} step={1} defaultValue={this.props.value.otherProfile.maximumDistanceToWork} />
                        <h6 className={`text-left mb-0 ${this.props.value.profile.maximumDistanceToWork === this.props.value.otherProfile.maximumDistanceToWork ? "is-valid" : "is-invalid"}`}>
                            {_("distance.radius")} {this.retrieveDistance(this.props.value.otherProfile.maximumDistanceToWork)}
                        </h6>
                    </div>
                </FormGroup>

                <hr className="mb-3 mt-4" />

                {/* GENERAL AVAILABILITY */}
                {this.renderGeneralAvailability()}

                {/* STANDARD WEEKDAYS */}
                {this.renderStandardWeekdays()}

                {/* ALTERNATIVE WEEKDAYS */}
                {this.renderAlternateWeekdays()}

                {/* OTHER ATTRIBUTES */}
                {this.renderOtherAttributes()}

                <hr className="mb-3 mt-4" />

                <h3 className="mb-2 text-left">{_("text.profile.knowledge")}</h3>
                {this.retrieveMatchingComponent("languages")}
                {this.retrieveMatchingComponent("cashierSystems")}

                <hr className="mb-3 mt-4" />

                <h3 className="mb-2 text-left">{_("text.profile.skills")}</h3>
                {this.retrieveMatchingComponent("customerSkills")}
                {this.retrieveMatchingComponent("adminSkills")}
                {this.retrieveMatchingisPharmacyEdition && Component("logisticSkills")}
                {this.retrieveMatchingComponent("manufacturSkills")}
                {this.retrieveMatchingComponent("marketingSkills")}
            </div>
        );        
    }

    renderForHealthcareEdition(){

        return (
            <div className="col-12 m-0 p-0">

                <FormGroup className="mb-2">
                    <SelectInputComponent disabled shadow
                        className="is-valid"
                        placeholder={_(`placeholder.jobTypes.${this.retrieveType(true)}`)}
                        default={this.retrieveJob(this.props.value.profile.jobID)} />
                </FormGroup>

                <hr className="mb-3 mt-4" />

                <FormGroup className="row mb-0">
                    <div className="col-6 pr-1 text-left">
                        <h4>{_("my.profile")}</h4>
                    </div>
                    <div className="col-6 pl-1 text-left">
                        <h4>{_("others.profile")}</h4>
                    </div>
                </FormGroup>

                <FormGroup className="row mb-2">
                    <div className="col-6 pr-1">
                        <SelectInputComponent disabled shadow
                            className={this.props.value.otherProfileArea.id === this.props.value.profileArea.id ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.postalArea")}
                            default={{ id: this.props.value.profileArea.id, item: `${this.props.value.profileArea.code} ${this.props.value.profileArea.name}` }} />
                    </div>
                    <div className="col-6 pl-1">
                        <SelectInputComponent disabled shadow
                            className={this.props.value.otherProfileArea.id === this.props.value.profileArea.id ? "is-valid" : "is-invalid"}
                            placeholder={_("placeholder.postalArea")}
                            default={{ id: this.props.value.otherProfileArea.id, item: `${this.props.value.otherProfileArea.code} ${this.props.value.otherProfileArea.name}` }} />
                    </div>
                </FormGroup>

                <FormGroup className="pt-2 mb-2 two-columns-grid">
                    <div>
                        <Slider className="mb-1" disabled min={0} max={1000} step={1} defaultValue={this.props.value.profile.maximumDistanceToWork} />
                        <h6 className={`text-left mb-0 ${this.props.value.profile.maximumDistanceToWork === this.props.value.otherProfile.maximumDistanceToWork ? "is-valid" : "is-invalid"}`}>
                            {_("distance.radius")} {this.retrieveDistance(this.props.value.profile.maximumDistanceToWork)}
                        </h6>
                    </div>
                    <div>
                        <Slider className="mb-1" disabled min={0} max={1000} step={1} defaultValue={this.props.value.otherProfile.maximumDistanceToWork} />
                        <h6 className={`text-left mb-0 ${this.props.value.profile.maximumDistanceToWork === this.props.value.otherProfile.maximumDistanceToWork ? "is-valid" : "is-invalid"}`}>
                            {_("distance.radius")} {this.retrieveDistance(this.props.value.otherProfile.maximumDistanceToWork)}
                        </h6>
                    </div>
                </FormGroup>

                <hr className="mb-3 mt-4" />

                {/* GENERAL AVAILABILITY */}
                {this.renderGeneralAvailability()}

                {/* STANDARD WEEKDAYS */}
                {this.renderStandardWeekdays()}

                {/* ALTERNATIVE WEEKDAYS */}
                {this.renderAlternateWeekdays()}

                {/* OTHER ATTRIBUTES */}
                {this.renderOtherAttributes()}

                <hr className="mb-3 mt-4" />

                {/* <h3 className="mb-2 text-left">{_("text.profile.knowledge")}</h3> */}
                {this.retrieveMatchingComponent("languages")}

                <hr className="mb-3 mt-4" />

                {/* <h3 className="mb-2 text-left">{_("text.profile.skills")}</h3> */}
                {this.retrieveMatchingComponent("customerSkills")}
            </div>
        );        
    }

}