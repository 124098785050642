import React, { Component } from 'react';
import { _ } from '../../../controllers/languageController';
import SecurityController from '../../../controllers/securityController';
import ApiController, { callbackWrapper } from '../../../controllers/apiController';
import { withRouter } from 'react-router-dom';
import ConfigController from '../../../controllers/configController';
import LoadingItemComponent from '../loadingItemComponent';

class MatchCompareDocumentsComponents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentWillMount() {
        this.load();
    }

    load() {
        ApiController.retrieveProfileFiles(this.props.profileId, this.props.otherProfileId, callbackWrapper((response) => {
            this.setState({ content: response.data.result, loading: false });
        }));
    }

    openDocument(id) {
        window.open(SecurityController.secureDocumentLink(id), "_blank").focus();
    }

    retrieveFiles() {
        if (this.state.content.length === 0) {
            return (<h5>{_("empty")}</h5>)
        }

        return this.state.content
            .sort((a, b) => _(this.mapDocumentType(a.documentTypeID).item)
                .localeCompare(_(this.mapDocumentType(b.documentTypeID).item)))
            .map(x => (
                <div key={x.id} className="text-left">
                    <p>
                        <a><b>({_(this.mapDocumentType(x.documentTypeID).item)})</b> {x.name}</a>
                        <button className="btn btn-sm btn-success ml-2" onClick={() => this.openDocument(x.id)}>{_("text.open.default")}</button>
                    </p>
                </div>
            ));
    }

    mapDocumentType(id) {
        return ConfigController.retrieveDocumentTypes().filter(x => x.id === id)[0];
    }

    render() {
        if (this.state.loading) {
            return (<LoadingItemComponent />);
        }
        return (
            <div>
                {this.retrieveFiles()}
            </div>
        );
    }

}

export default withRouter(MatchCompareDocumentsComponents);