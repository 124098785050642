import React, { Component } from 'react';
import LoadingItemComponent from '../_components/loadingItemComponent';
import ManageProfileComponent, { mapFromCommand, mapToCommand } from '../_components/profile/manageProfileComponent';
import ApiController, { callbackWrapper } from '../../controllers/apiController';
import NotificationController from '../../controllers/notificationController';
import { _ } from '../../controllers/languageController';

export default class EditProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };
    }

    componentWillMount() {

        if (!this.props.match.params.profile) {
            this.props.history.push("/");
            return;
        }

        ApiController.retrieveProfile(this.props.match.params.profile, callbackWrapper(this.loadCallback.bind(this)).bind(this));
    }

    loadCallback(response) {
        
        if (response.data.error) {
            NotificationController.pushError(_(response.data.error.message));
            this.props.history.push("/profile");
            return;
        }

        this.setState({ data: mapFromCommand(response.data.result), loading: false });
    }

    onSubmit(data) {

        this.setState({ loading: true, data: data });
        ApiController.updateProfile({
            profileID: this.props.match.params.profile,
            ...mapToCommand(data)
        }, callbackWrapper(this.callback.bind(this)).bind(this));
    }

    callback(response) {
        if (response.data.error) {
            NotificationController.pushError(_(response.data.error.message), _("error.updateProfile"));
            this.setState({ loading: false });
            return;
        }

        NotificationController.pushSuccess(_("success.updateProfile"));
        this.props.history.push("/profile");
    }


    render() {
        if (this.state.loading) return (<LoadingItemComponent />);
        return (<ManageProfileComponent submit={this.onSubmit.bind(this)} job={this.state.data.job} selection={this.state.data.selection} />);
    }

}