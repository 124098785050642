import React, { Component } from 'react';
import { Form, Button, FormGroup, Input } from 'reactstrap';
import { Formik } from "formik";
import Slider from "rc-slider";

import 'rc-slider/assets/index.css';

import LanguageController, { _ } from '../../../controllers/languageController';
import CenteredComponent from '../centeredComponent';
import SelectInputComponent, { optionFilter, postalAreaFilter } from '../selectInputComponent';
import ConfigController from '../../../controllers/configController';

export default class Step1Component extends Component {

    constructor(props) {
        super(props);

        if (this.props.state[1]) {
            this.state = {
                submitted: false,
                selectedDistance: this.props.state[1].distance,
                selectedArea: this.props.state[1].area,
                selectedJobType: this.props.state[1].jobType
            };
        } else {
            this.state = {
                submitted: false,
                selectedDistance: 0
            };
        }
    }

    // defaukt properties
    static defaultProps = {
        countOfSteps: 5  // default count of steps until completion (only for display)
    };
    

    componentWillMount() {
        window.document.getElementById('root').scrollIntoView({
            behavior: 'smooth'
        })
    }

    onSubmit(fields) {

        if (!this.state.submitted) this.setState({ submitted: true });

        if (!this.state.selectedArea || !this.state.selectedJobType) return;

        if (this.props.state.length > 2 && this.props.state[1].jobType.id !== this.state.selectedJobType.id) {
            //console.log("job changed!");
            delete this.props.state[2];
        }

        this.props.next({ countryCode:LanguageController.determineLanguage(), area: this.state.selectedArea, jobType: this.state.selectedJobType, distance: this.state.selectedDistance });
    }

    selectArea(item) {
        this.setState({ selectedArea: item });
    }

    retrievePostalArea() {
        return this.state.selectedArea;
    }

    selectJobType(item) {
        this.setState({ selectedJobType: item });
    }

    selectDistance(value) {
        this.setState({ selectedDistance: Math.ceil((value * value) / 5) * 5 });
    }

    retrieveDistance() {
        if (this.state.selectedDistance <= 0) {
            return _("distance.0");
        } else if (this.state.selectedDistance >= 1000) {
            return _("distance.1000");
        }
        return this.state.selectedDistance + " " + _("km");
    }

    retrieveDistanceNormalized() {
        if (!this.state.selectedDistance || this.state.selectedDistance <= 0) {
            return 0;
        }

        for (let i = 0; i <= 32; i++) {
            if ((Math.ceil((i * i) / 5) * 5) == this.state.selectedDistance) {
                return i;
            }
        }

        return 32;
    }

    render() {

        var titleCode = "text.register.step1";
        if (ConfigController.isHealthcareEdition()) titleCode = "text.register.health.step1";

        // css class for healthcare with smaller font size
        var jobSelectClass = "";
        if (ConfigController.isHealthcareEdition()) jobSelectClass = "health-job-type-select";


        // CS 25/4/22: get all active countries in one go at the start
        var activeCountriesCodes = LanguageController.availableLanguages();
        // CS 19/7/22: get all country code names (actively translated) and sort them by name
        let activeCountries = activeCountriesCodes.map( x => {return { code: x, countryName: _("nationalities." + x)}}).sort((a, b) => a.countryName.localeCompare(b.countryName));

        return (
            <CenteredComponent size={12} className="animated fadeIn">

                <h1 className="mb-2">{_("step")} 1 {_("of")} {this.props.countOfSteps}</h1>
                <h4 className="mb-4 offset-lg-1 col-lg-10">{_(`${titleCode}.${this.props.type}`)}</h4>
                <Formik onSubmit={this.onSubmit.bind(this)}
                    render={(props) => (
                        <Form noValidate className="offset-lg-2 col-lg-8 mb-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>

                            <FormGroup className="mb-2">
                                <div className="dropdown col-12 m-0 p-0">
                                    <Button color="secondary" className=" dropdown-toggle col-12" data-toggle="dropdown" id="countryDropdownMenu">
                                        {_(`country.${LanguageController.determineLanguage()}`)}
                                    </Button>
                                    {activeCountries.length > 1 && 
                                        <ul className="dropdown-menu" aria-labelledby="countryDropdownMenu">
                                            {
                                                activeCountries.filter(x => x.code !== LanguageController.determineLanguage())
                                                    .map(x => (
                                                        <li key={x.code}>
                                                            <a className="dropdown-item" onClick={() => {
                                                                LanguageController.changeLanguage(x.code, true);
                                                            }}>
                                                                {_(`country.${x.code}`)}
                                                            </a>
                                                        </li>
                                                    ))
                                            }
                                        </ul>
                                    }
                                </div>
                            </FormGroup>

                            <FormGroup className="mb-2">
                                <SelectInputComponent
                                    onSelect={this.selectArea.bind(this)}
                                    onFilter={postalAreaFilter}
                                    submitted={this.state.submitted}
                                    inputmode="text"
                                    placeholder={_("placeholder.postalArea")}
                                    default={this.retrievePostalArea()} />
                            </FormGroup>

                            <FormGroup className="mb-2">
                                {/* <SelectInputComponent
                                    onSelect={this.selectJobType.bind(this)}
                                    onFilter={optionFilter(this.retrieveJobTypes())}
                                    submitted={this.state.submitted}
                                    placeholder={_(`placeholder.jobTypes.${this.props.type}`)}
                                    default={this.retrieveJobType()} 
                                    // CS 20/4/22: added drop down arrow icon
                                    className="custom-select" /> */}
                                <div className={jobSelectClass}>
                                    <SelectInputComponent
                                        onSelect={this.selectJobType.bind(this)}
                                        onFilter={optionFilter(ConfigController.retrieveJobTypes())}
                                        submitted={this.state.submitted}
                                        placeholder={_(`placeholder.jobTypes.${this.props.type}`)}
                                        default={this.state.selectedJobType} 
                                        // CS 20/4/22: added drop down arrow icon
                                        className="custom-select" />
                                </div>
                            </FormGroup>

                            <FormGroup className="py-2 mb-0 noselect">
                                <Slider className="mb-1" min={0} max={32} step={1} onChange={this.selectDistance.bind(this)} defaultValue={this.retrieveDistanceNormalized()} />
                                <h6 className="text-left mb-0">{_("distance.radius")} {this.retrieveDistance()}</h6>
                            </FormGroup>

                            {/* <div className="row">
                                <div className="col-md-6 m-0 py-2" style={this.props.back ? {} : {display:'none'}}>
                                    <Button type="button" className="col-12" color="danger" onClick={(x) => this.props.back()}><i className="fas fa-angle-left"></i> {_("back")}</Button>
                                </div>
                                <div className={"offset-md-6 col-md-6 text-right py-2" + (this.props.back ? "m-0" : "")}>
                                    <Button type="submit" className="col-12" color="success">{_("next")} <i className="fas fa-angle-right"></i></Button>
                                </div>
                            </div> */}
                            <div className="row mb-3">
                                <div className="col-md-6 m-0 py-2">
                                    <Button type="button" className="col-12" color="danger" onClick={(x) => this.props.back()}><i className="fas fa-angle-left"></i> {_("back")}</Button>
                                </div>
                                <div className="offset-md-6 col-md-6 text-right m-0 py-2">
                                    <Button type="submit" className="col-12" color="success">{_("next")} <i className="fas fa-angle-right"></i></Button>
                                </div>
                            </div>


                        </Form>
                    )}>
                </Formik>
            </CenteredComponent>
        );
    }
}
