import React, { Component } from 'react';
import AuthenticationController from '../../controllers/authenticationController';
import ApiController, { callbackWrapper } from '../../controllers/apiController';
import LoadingItemComponent from '../_components/loadingItemComponent';
import UpdateNationalityComponent from '../_components/mandatoryChange/updateNationalityComponent';
import NotificationController from '../../controllers/notificationController';
import { _ } from '../../controllers/languageController';
import AcceptTermsComponent from '../_components/mandatoryChange/acceptTermsComponent';

export default class MandatoryChange extends Component {

    constructor(props) {
        super(props);
        this.state = {
            change: undefined,
            loading: true
        };
    }

    /* CS 15/5/20: here we check if the user really has mandatory changes and not just  */
    //componentWillMount() {
    componentDidMount() {
        
        if (!AuthenticationController.isMandatoryChange()) {
            this.props.history.push("/");
            return;
        }

        this.load();
    }

    load() {
        ApiController.retrievePendingMandatoryChanges(callbackWrapper(((response) => {
            this.setState({ change: response.data.result[0], loading: false });
        }).bind(this)).bind(this));
    }

    onSubmit() {
        this.setState({ loading: true });
        return callbackWrapper((response) => {
            if (response.data.error) {
                NotificationController.pushError(_(response.data.error.message), _("error.update"));
                this.setState({ loading: false });
                return;
            }

            AuthenticationController.setToken(response.data.result);
            this.props.history.push("/");
        }).bind(this);
    }

    retrieveItem(item) {
        switch (item) {
            case "nationality":
                return (<UpdateNationalityComponent id={item} onSubmit={this.onSubmit.bind(this)} />);
            case "terms":
                return (<AcceptTermsComponent id={item} onSubmit={this.onSubmit.bind(this)} />);
            default:
                return null;
        }
    }

    render() {
        if (this.state.loading) {
            return (<LoadingItemComponent />);
        }

        return this.retrieveItem(this.state.change);
    }

}