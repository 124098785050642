import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormGroup, Button } from 'reactstrap';
import { _ } from '../../../controllers/languageController';
import ApiController, { callbackWrapper } from '../../../controllers/apiController';
import NotificationController from '../../../controllers/notificationController';
import LoadingItemComponent from '../loadingItemComponent';

class ChangeNotificationsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentWillMount() {
        this.load();
    }

    load() {
        ApiController.retrieveAccountNotifications(callbackWrapper(this.callback.bind(this)).bind(this));
    }

    callback(response) {

        this.setState({
            loading: false,
            newsletter: response.data.result.newsletter,
            newPotentialMatch: (response.data.result.notifications & 1) == 1,   // CS 1/9/21: fixed bit operations error
            contactRequested: (response.data.result.notifications & 2) == 2     // CS 1/9/21: fixed bit operations error
        });
        //console.log(response);
    }

    submit() {
        this.setState({ loading: true });
        ApiController.updateNotifications({
            newsletter: this.state.newsletter,
            notifications: (this.state.newPotentialMatch ? 1 : 0) | (this.state.contactRequested ? 2 : 0)
        }, callbackWrapper(this.saveCallback.bind(this)).bind(this));
    }

    saveCallback(response) {
        this.setState({ loading: false });
        if (response.data.error) {
            NotificationController.pushError(_(response.data.error.message), _("error.notificationsUpdate"));
            return;
        }

        NotificationController.pushSuccess(_("success.notificationsUpdate"));
    }

    toggle(type) {
        let state = {};
        state[type] = !this.state[type]

        this.setState(state);
    }

    render() {
        if (this.state.loading) {
            return (<LoadingItemComponent />);
        }

        return (
            <div className="text-left">
                <FormGroup className="m-0 mb-2 row">
                    <label className="custom-toggle my-auto">
                        <input type="checkbox" checked={this.state.newsletter} onChange={() => this.toggle("newsletter")} />
                        <span className="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <h5 className="col-auto m-0">{_("text.newsletter")}</h5>
                </FormGroup>

                <FormGroup className="m-0 mb-2 row">
                    <label className="custom-toggle my-auto">
                        <input type="checkbox" checked={this.state.newPotentialMatch} onChange={() => this.toggle("newPotentialMatch")} />
                        <span className="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <h5 className="col-auto m-0">{_("text.newPotentialMatch")}</h5>
                </FormGroup>

                <FormGroup className="m-0 mb-2 row">
                    <label className="custom-toggle my-auto">
                        <input type="checkbox" checked={this.state.contactRequested} onChange={() => this.toggle("contactRequested")} />
                        <span className="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <h5 className="col-auto m-0">{_("text.contactRequested")}</h5>
                </FormGroup>

                <div className="row">
                    <div className="offset-md-6 col-md-6 text-right">
                        <Button onClick={this.submit.bind(this)} className="col-12" color="success">{_("save")} <i className="fas fa-angle-right"></i></Button>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(ChangeNotificationsComponent);