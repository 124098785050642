import React, { Component } from 'react';
import ConfigController from '../../controllers/configController';

export default class LoadingComponent extends Component {

    render() {
        if (this.props.loading) {
            return (
                <div className="apo-loading">
                    <div className="w-100">
                        <div className="d-flex justify-content-center">
                            {ConfigController.isPharmacyEdition() &&
                                <img style={{height: "5rem"}} className="animated infinite flash slower delay-1s" src={process.env.PUBLIC_URL + "/assets/images/apo-loading-icon.svg?v=2.0"} alt="" />
                            }
                            {ConfigController.isHealthcareEdition() &&
                                <img style={{height: "10vmin"}} className="animated infinite flash slower delay-1s" src={process.env.PUBLIC_URL + "/assets/images/pflege-loading-icon.svg?v=1.0"} alt="" />
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.props.children;
        }
    }

}