import React, { Component } from 'react';
import AuthenticationController from '../../../controllers/authenticationController';
import ApiController, { callbackWrapper } from '../../../controllers/apiController';
import ConfigController from '../../../controllers/configController';
import { withRouter } from 'react-router-dom';
import { _ } from '../../../controllers/languageController';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SecurityController from '../../../controllers/securityController';
import LoadingItemComponent from '../loadingItemComponent';
import AccordionItemComponent from '../accordionItemComponent';

class ListProfilesComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: AuthenticationController.isPerson() ? "person" : "pharmacy",
            isAdmin: AuthenticationController.isAdmin(),
            profileList: []
        };
    }

    // default values for possibly missing props
    static defaultProps = {
        userType: "person",  // type of user to search for (person or pharmacy) - default to person
        mostRecentDays: 14, // count of days which qualify for initial most recent user list
        showOpen: false,    // controls if accordion should be displayed initially as open or closed
        accordionTitle: "Profile"  // title shown in the accordion
    };

    componentWillMount() {
        this.load();
    }

    load() {
        this.setState({ loading: true });
        ApiController.adminRetrieveAllPharmacyProfiles(callbackWrapper(this.loadProfilesCallback.bind(this)));
    }

    loadProfilesCallback(response) {
        this.setState({ profileList: response.data.result, loading: false });
    }

    profileCommandCallback(response) {
        this.load();
    }

    openProfile(e, profile) {
        if (e) {
            e.stopPropagation();
        }

        if (!profile.active) return;
        this.props.history.push(`match/${profile.id}`);
    }

    deleteProfile(e, profile) {
        e.stopPropagation();

        this.setState({ deleteProfile: profile });
    }

    confirmDeleteProfile() {
        let profile = this.state.deleteProfile;

        this.setState({ loading: true, deleteProfile: undefined });
        ApiController.deleteProfile(profile.id, callbackWrapper(this.profileCommandCallback.bind(this)));
    }

    cancelDeleteProfile() {
        this.setState({ deleteProfile: undefined });
    }

    editProfile(e, profile) {
        if (e) {
            e.stopPropagation();
        }

        this.props.history.push(`edit/${profile.id}`);
    }

    activateProfile(e, profile) {
        e.stopPropagation();

        this.setState({ loading: true });
        ApiController.activateProfile(profile.id, callbackWrapper(this.profileCommandCallback.bind(this)));
    }

    deactivateProfile(e, profile) {
        e.stopPropagation();

        this.setState({ loading: true });
        ApiController.deactivateProfile(profile.id, callbackWrapper(this.profileCommandCallback.bind(this)));
    }

    retrieveProfiles() {
        return this.state.profileList.map(x => {
            return (
                <div key={x.id} className="card text-left">
                    <div className="card-body" onClick={(() => this.openProfile(undefined, x)).bind(this)}>
                        <h5 className="card-title mb-0">
                            <span className="badge badge-dot">
                                <i className={x.active ? "bg-success" : "bg-danger"}></i>
                            </span>
                            {/* CS: 30/3/22 fixed problem with mixed countries preventing job type to be found - call new func retrieveNativeJobType */}
                            {_(ConfigController.retrieveNativeJobType(x.jobID).item)}
                            &nbsp;({x.hoursPerWeek} {_("text.hoursPerWeek")})
                            {x.active && <i className="fas fa-angle-right float-right"></i>}
                        </h5>
                        <small className="h6 text-muted">
                            &nbsp;&nbsp;{_("text.creationDate")}
                            :&nbsp;{new Date(x.creationDate).toLocaleDateString()}
                        </small>
                        <div className="text-right">
                            {x.active && <button className="btn btn-secondary btn-sm col-lg-auto mb-2 mb-lg-0" onClick={(e) => this.editProfile(e, x)}><i className="fas fa-pen"></i> {_("text.edit")}</button>}
                            {x.active && <button className="btn btn-secondary btn-sm col-lg-auto mb-2 mb-lg-0" data-toggle="tooltip" title="Vorübergehend deaktivieren" onClick={(e) => this.deactivateProfile(e, x)}>{_("text.deactivate")}</button>}
                            {!x.active && <button className="btn btn-success btn-sm col-lg-auto mb-2 mb-lg-0" onClick={(e) => this.activateProfile(e, x)}>{_("text.activate")}</button>}
                            <button className="btn btn-danger btn-sm col-lg-auto mb-2 mb-lg-0" onClick={(e) => this.deleteProfile(e, x)}>{_("text.delete")}</button>
                            {x.active && <button className="btn btn-success btn-sm col-lg-auto mb-2 mb-lg-0" onClick={(e) => this.openProfile(e, x)}>{_(`text.open.${this.state.type}`)} <i className="fas fa-angle-right" /></button>}
                        </div>
                    </div>
                </div>
            )
        });
    }

    createProfile() {
        this.props.history.push("/create");
    }

    render() {
        if (this.state.loading) {
            return (<LoadingItemComponent />);
        }

        var titleCount = this.state.profileList !== null ? this.state.profileList.length : 0;
        var titleStr = `${this.props.accordionTitle} (${titleCount})`;

        return (
            <AccordionItemComponent title={titleStr} show={this.props.showOpen}>
                <Modal className="modal-dialog-centered modal-lg" isOpen={this.state.deleteProfile} toggle={this.cancelDeleteProfile.bind(this)}>
                    <ModalHeader toggle={this.cancelDeleteProfile.bind(this)}>{_("text.confirm.text")}</ModalHeader>
                    <ModalBody className="py-0">
                        {_("text.confirm.deleteProfile")}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.cancelDeleteProfile.bind(this)}>{_("cancel")}</Button>
                        <Button color="danger" onClick={this.confirmDeleteProfile.bind(this)}>{_("confirm")}</Button>
                    </ModalFooter>
                </Modal>

                {this.retrieveProfiles()}
                <div className="row">
                    <div className="offset-md-6 col-md-6 text-right">
                        <Button type="submit" className="col-12" color="success" onClick={this.createProfile.bind(this)}>{_("create")} <i className="fas fa-plus"></i></Button>
                    </div>
                </div>
            </AccordionItemComponent>
        );
    }

}

export default withRouter(ListProfilesComponent);