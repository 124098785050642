const LoadingController = {

    register(show, hide) {
        this.showLoadingIndicator = show;
        this.hideLoadingIndicator = hide;
    },

    show() {
        this.showLoadingIndicator();
    },

    hide() {
        this.hideLoadingIndicator();
    }

}

export default LoadingController;