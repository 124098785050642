import React, { Component, Fragment } from 'react';
import AuthenticationController from '../../../controllers/authenticationController';
import ApiController, { callbackWrapper } from '../../../controllers/apiController';
import { withRouter } from 'react-router-dom';
import { _ } from '../../../controllers/languageController';
import AccordionItemComponent from '../accordionItemComponent';
import LoadingItemComponent from '../loadingItemComponent';

//
// CS: 23/9/21
// component for listing all available system statistics for administrators only
//
class SystemStatsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            userList: null,
            searchTerm: "",  // search term being used for filtering
            countryCounters: null,
            showData: this.props.showOpen | false
        };

        this.cardHeaderStyle={
            padding:'.5rem 1rem', 
            textTransform: "uppercase",
            backgroundColor: '#f8f8f8',
            border: '1px solid #ddd'
        };
        this.cardBodyStyle={
            padding:'.5rem 1rem', 
            paddingBottom: '1rem'
        };
    }

    // default values for possibly missing props
    static defaultProps = {
        showOpen: false,    // controls if accordion should be displayed initially as open or closed
        accordionTitle: "UNTITLED"  // title shown in the accordion
    };

    // CS: make sure user is really logged on as admin
    componentDidMount() {
        if (!AuthenticationController.isAdmin()) {
            this.props.history.push("/");
        }

        // only preload data if props.showOpen is true, otherwise load when the accordion has been expanded
        if ( this.showData ) this.loadData();
    }

    loadData()
    {
        // CS 6/7/22: prevent data from being reloaded again - use cached
        if ( this.state.countryCounters ) return;

        this.setState({ loading:true});
        ApiController.retrieveCountryStats(callbackWrapper((response) => {
            //console.table(response.data);
            this.setState({ countryCounters: response.data, showData:true, loading:false});
        }));
    }

    renderCountryCounters() {

        // no data available?
        if (this.state.countryCounters === null || this.state.countryCounters.length === 0) return;

        var result = [];

        var items = this.state.countryCounters.map(x=> 
            (
                //var creationDate = new Date(Date.parse(x.account.creationDate));
                <div key={Math.random()} className="card text-left hover-shadow grow-2">
                    <h4 className="card-header" style={this.cardHeaderStyle}>
                        {/* <span className="material-icons mr-2">outlined_flag</span>     */}
                        {_("country." + x.countryCode)}
                    </h4>
                    <div className="card-body" style={this.cardBodyStyle}>
                        <p className="card-text text-xs two-columns-grid">
                            <div>
                                {/* PHARMACY COUNTERS */}
                                <div className="side-by-side">
                                    <span className="material-icons">local_pharmacy</span>
                                    <span className="badge">Jobangebote</span>
                                </div>
                                <div className="side-by-side">
                                    <span className="badge pl-0">Anzahl:</span>
                                    <span className="badge">{x.pharmacyCount}</span>
                                </div>
                                <div className="side-by-side">
                                    <span className="badge pl-0">Profile:</span>
                                    <span className="badge">{x.pharmacyProfileCount}</span>
                                </div>
                                <div className="side-by-side">
                                    <span className="badge pl-0">inaktive Profile:</span>
                                    <span className="badge">{x.inactivePharmacyProfileCount}</span>
                                </div>
                            </div>
                            <div>
                            {/* PERSON COUNTERS */}
                            <div className="side-by-side">
                                <span className="material-icons">people</span>
                                <span className="badge">Jobsuchende</span>
                            </div>
                            <div className="side-by-side">
                                <span className="badge pl-0">Anzahl:</span>
                                <span className="badge">{x.personCount}</span>
                            </div>
                            <div className="side-by-side">
                                <span className="badge pl-0">Profile:</span>
                                <span className="badge">{x.personProfileCount}</span>
                            </div>
                            <div className="side-by-side">
                                <span className="badge pl-0">inaktive Profile:</span>
                                <span className="badge">{x.inactivePersonProfileCount}</span>
                            </div>
                            </div>
                        </p>
                     </div>
                </div>
            ));
    
        // wrap the items in special div.user-search-grid
        result.push(
            <div className="two-columns-grid">
                {items}
            </div>
        );

        return result;
    }


    // CS 6/7/22: when user opens the accordion - load data if not already loaded
    openedAccordion(){
        this.loadData();
        this.setState({ showData:true });
    }
    closedAccordion(){
        this.setState({ showData:false });
    }

    render() {
        if (this.state.loading) {
            return (<LoadingItemComponent />);
        }

        return (
            <Fragment>
                <AccordionItemComponent title={this.props.accordionTitle} show={this.props.showOpen || this.state.showData} onOpen={this.openedAccordion.bind(this)} onClose={this.closedAccordion.bind(this)}>
                    {this.state.showData && this.renderCountryCounters()}
                </AccordionItemComponent>
            </Fragment>
        );
    }

}

export default withRouter(SystemStatsComponent);