import aesjs from 'aes-js';
import base64url from "base64url";
import ConfigController from './configController';
import AuthenticationController from './authenticationController';
import { apiEndpoint } from './apiController';
import crypto from 'crypto';

const SecurityController = {

    temp_key() {
        if (!this.temp_aes_key) {
            this.temp_aes_key = new Uint8Array(16);
            window.crypto.getRandomValues(this.temp_aes_key);
        }
        return this.temp_aes_key;
    },

    temp_encrypt(data) {
        return aesjs.utils.hex.fromBytes(
            new aesjs.ModeOfOperation.ctr(this.temp_key(), new aesjs.Counter(5))
                .encrypt(aesjs.utils.utf8.toBytes(JSON.stringify(data)))
        );
    },

    temp_decrypt(encrypted) {
        return JSON.parse(aesjs.utils.utf8.fromBytes(
            new aesjs.ModeOfOperation.ctr(this.temp_key(), new aesjs.Counter(5))
                .decrypt(aesjs.utils.hex.toBytes(encrypted))
        ));
    },

    encryptAES(data, key, iv) {
        let cipher = crypto.createCipheriv("aes-256-cbc", key, iv);
        return cipher.update(data, 'utf8', 'base64') + cipher.final('base64');
    },

    queryAuthentication() {
        let aesKey = crypto.randomBytes(16).toString("hex");
        let aesIV = crypto.randomBytes(8).toString("hex");

        let encryptedKey = crypto.publicEncrypt({
            key: ConfigController.retrievePublicKey().exportKey('pkcs8-public-pem'),
            padding: crypto.constants.RSA_PKCS1_PADDING
        }, Buffer.from(`${aesKey}|${aesIV}`)).toString("base64");

        let token = this.encryptAES(AuthenticationController.retrieveToken(), aesKey, aesIV);
        return { key: base64url.fromBase64(encryptedKey), token: base64url.fromBase64(token) };
    },

    secureDocumentLink(documentId) {
        let auth = this.queryAuthentication();
        return apiEndpoint(`document/${documentId}?key=${auth.key}&token=${auth.token}`);
    },

    secureProfilePictureLink(profileId, otherProfileId) {
        let auth = this.queryAuthentication();
        return apiEndpoint(`document/picture/profile/${profileId}/${otherProfileId}?key=${auth.key}&token=${auth.token}`);
    }

}

export default SecurityController;