import React, { Component } from 'react';
import AddProfileInStepsComponent from './addProfileInStepsComponent';
import { _ } from '../../controllers/languageController';


export default class CreateProfile extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        // CS 16/7/20: if pharmacy run old code, for persons run new CreateProfileStepsComponent
        return (            
            <AddProfileInStepsComponent />
        );
   }

}