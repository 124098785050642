import React, { Component } from 'react';
import { _ } from '../../controllers/languageController';
import Step1Component from '../_components/registration/step1Component';
import Step2Component from '../_components/registration/step2Component';
import Step3Component from '../_components/registration/step3Component';
import Step4Component from '../_components/registration/step4Component';
import LoadingController from '../../controllers/loadingContoller';
import AuthenticationController from '../../controllers/authenticationController';
import ApiController, { callbackWrapper } from '../../controllers/apiController';
import NotificationController from '../../controllers/notificationController';
import { withRouter } from 'react-router-dom';
import ConfigController from '../../controllers/configController';

class AddProfileInStepsComponent extends Component {

    constructor(props) {
        super(props);
        // <... userType={"Person" || "Pharmacy"}/>
        this.state = {
            step: 1, 
            content: [],
            userType: this.props.userType
        };

        this.cardHeaderStyle={
            padding:'.5rem 1rem', 
            textTransform: "uppercase",
            backgroundColor: '#f8f8f8',
            border: '1px solid #ddd'
        };
        this.cardBodyStyle={
            padding:'.5rem 1rem', 
            paddingBottom: '1rem'
        };
    }

    // default values for possibly missing props
    static defaultProps = {
        userType: "person",  // type of user to search for (person or pharmacy) - default to person
    };


/*     
    componentWillMount() {
        this.setState({ step: 1, content: [] });

        if (!this.props.match.params.userType
            || ["person", "pharmacy"].indexOf(this.props.match.params.userType) === -1) {
        } else {
            this.setState({ type: this.props.match.params.userType });
        }
    }
 */

    // CS 25/11/21: added correct userType so APO creates appropriate new profile
    componentWillMount() {
        var currentUserIsPerson = AuthenticationController.isPerson();

        var userType = currentUserIsPerson ? "person" : "pharmacy";
        this.setState({ userType: userType });
    }

    onCommit() {

        // output of all content pages
        // console.log("onCommit:content[1]: ", this.state.content[1])
        // console.log("onCommit:content[2]: ", this.state.content[2])
        // console.log("onCommit:content[3]: ", this.state.content[3])
        // console.log("onCommit:content[4]: ", this.state.content[4])

        var isPharmacyEdition = ConfigController.isPharmacyEdition();
        var isHealthcareEdition = ConfigController.isHealthcareEdition();

        let createProfileData = {
            postalAreaId: this.state.content[1].area.id,    // CS 16/7/2020 - added new postalAreaID for each profile            
            jobId: this.state.content[1].jobType.id,
            maximumDistancetoWork: Math.min(1000, this.state.content[1].distance),
            hoursPerWeek: this.state.content[2].hoursPerWeek,
            availableInWeeks: this.state.content[2].availableIn,
            availableForNightlyShifts: isPharmacyEdition ? this.state.content[2].nightlyShift.id : false,
            availableAsReplacement: this.state.content[2].replacement.id,

            // added 2 new properties
            availableForOverpay: this.state.content[2].overpay.id,
            accomodation: isPharmacyEdition ? this.state.content[2].accomodation.id : false,

            // CS 24/9/21 added 2 new properties management and licenceHolder
            management: this.state.content[2].management.id,
            licenceHolder: isPharmacyEdition ? this.state.content[2].licenceHolder.id : false,
            // CS 15/10/21 added new property CovidTester
            covidTester: isPharmacyEdition ? this.state.content[2].covidTester.id : false,
            // CS 27/6/22: added new property [ProfileModel].LindaFlag] in step 4
            lindaFlag: this.state.content[4].lindaFlag.id,
            // CS 29/9/22: added new property [ProfileModel].FlexibleCalendarWeek in step 2
            flexibleCalendarWeek: isPharmacyEdition ? this.state.content[2].flexibleCalendarWeek.id : false,

            otherLanguage: this.state.content[3].otherText.languages || "",
            otherCashierSystem: this.state.content[3].otherText.cashierSystems || "",
            otherAdminSkill: this.state.content[4].otherText.admin || "",
            otherCustomerSkill: this.state.content[4].otherText.customer || "",
            otherLogisticSkill: this.state.content[4].otherText.logistic || "",
            otherManufacturSkill: this.state.content[4].otherText.manufactur || "",
            otherMarketingSkill: this.state.content[4].otherText.marketing || "",
            remarks: this.state.content[4].profileRemarks,  // CS 28/4/22: added missing support for new field Profile.Remarks
            shifts: [
                { dayOfWeek: 0, shiftId: this.state.content[2].monday.id }, { dayOfWeek: 1, shiftId: this.state.content[2].tuesday.id },
                { dayOfWeek: 2, shiftId: this.state.content[2].wednesday.id }, { dayOfWeek: 3, shiftId: this.state.content[2].thursday.id },
                { dayOfWeek: 4, shiftId: this.state.content[2].friday.id }, { dayOfWeek: 5, shiftId: this.state.content[2].saturday.id },
                { dayOfWeek: 6, shiftId: this.state.content[2].sunday.id },
                // alternate weekdays
                { dayOfWeek: 20, shiftId: this.state.content[2].altMonday.id }, { dayOfWeek: 21, shiftId: this.state.content[2].altTuesday.id },
                { dayOfWeek: 22, shiftId: this.state.content[2].altWednesday.id }, { dayOfWeek: 23, shiftId: this.state.content[2].altThursday.id },
                { dayOfWeek: 24, shiftId: this.state.content[2].altFriday.id }, { dayOfWeek: 25, shiftId: this.state.content[2].altSaturday.id },
                { dayOfWeek: 26, shiftId: this.state.content[2].altSunday.id }
            ],
            languages: Object.keys(this.state.content[3].selected.languages || {}),
            cashierSystems: Object.keys(this.state.content[3].selected.cashierSystems || {}),
            adminSkills: Object.keys(this.state.content[4].selected.admin || {}),
            customerSkills: Object.keys(this.state.content[4].selected.customer || {}),
            logisticSkills: Object.keys(this.state.content[4].selected.logistic || {}),
            manufacturSkills: Object.keys(this.state.content[4].selected.manufactur || {}),
            marketingSkills: Object.keys(this.state.content[4].selected.marketing || {}),
            // CS 9/2/22: added new feature set of time periods per profile
            availabilities: this.state.content[2].availabilities || []
        };

        // CS 29/4/22: if health edition the admin skills come from step-3 instead of step-4
        if ( isHealthcareEdition)
        {
            createProfileData.otherAdminSkill = this.state.content[3].otherText.admin;
            createProfileData.adminSkills = Object.keys(this.state.content[3].selected.admin || {});
        }

        ApiController.createProfile(createProfileData, callbackWrapper(this.onResponse.bind(this)));
    }

    // callback from ApiController.createProfile call to API
    onResponse(response) {
        if (response.data.error) {
            NotificationController.pushError(_(response.data.error.message), _("error.createProfile"));
            this.setState({ loading: false });
            return;
        }

        NotificationController.pushSuccess(_("success.createProfile"));
        this.props.history.push("/profile");
    }

    onNext(data) {

        let newState = Object.assign({}, this.state);
        newState.content[this.state.step] = data;

        //console.log(newState);

        if (this.state.step === 4) {
            LoadingController.show();
            this.onCommit();
            return;
        }

        this.setState((prevState, props) => ({
            step: Math.min(4, prevState.step + 1),
            content: newState.content
        }));
    }

    onBack() {
        if (this.state.step > 1) {
            this.setState((prevState, props) => ({
                step: prevState.step - 1
            }));
        }
        else    // cancel -> go to current users profile page
        {
            this.props.history.push("/profile");
        }
    }

    retrieveStep() {
        switch (this.state.step) {
            case 1:
                return (<Step1Component countOfSteps={4} next={this.onNext.bind(this)} back={this.onBack.bind(this)} type={this.state.userType} state={this.state.content} />);
            case 2:
                return (<Step2Component countOfSteps={4} next={this.onNext.bind(this)} back={this.onBack.bind(this)} type={this.state.userType} state={this.state.content} />);
            case 3:
                return (<Step3Component countOfSteps={4} next={this.onNext.bind(this)} back={this.onBack.bind(this)} type={this.state.userType} state={this.state.content} />);
            case 4:
                return (<Step4Component countOfSteps={4} next={this.onNext.bind(this)} back={this.onBack.bind(this)} type={this.state.userType} state={this.state.content} />);
            default:
                return "";
        }
    }

    render() {

        var result = [];

        // prop userType is not provided?
        if ( !this.state.userType)
            result.push(<h1>props 'userType' is not set</h1>);
        else            
            result.push(this.retrieveStep());

        return result;
    }

}

export default withRouter(AddProfileInStepsComponent)