import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { _ } from '../../../controllers/languageController';
import ApiController, { callbackWrapper } from '../../../controllers/apiController';
import { Form, FormGroup, Button } from 'reactstrap';
import { InputForm } from '../inputFormComponent';
import ConfigController from '../../../controllers/configController';
import SelectInputComponent, { optionFilter } from '../selectInputComponent';
import NotificationController from '../../../controllers/notificationController';
import AuthenticationController from '../../../controllers/authenticationController';
import LoadingItemComponent from '../loadingItemComponent';
import { Formik, Field } from 'formik';

class AdminNotesComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            account: null,  // will receive the loaded account model (person or phamracy)
        };
    }

    componentWillMount() {
        this.loadAccount();
    }

    loadAccount() {
        if (AuthenticationController.isPerson()) {
            ApiController.retrieveAccountPerson(callbackWrapper(this.loadAccountCallback).bind(this));
        } else {
            ApiController.retrieveAccountPharmacy(callbackWrapper(this.loadAccountCallback).bind(this));
        }
    }

    loadAccountCallback = (response) => {
        this.setState({ account: response.data.result.account, loading:false });
    }

    changeAdminNotes(value) {

        // console.log(`changeRemarks::value = ${value}`)
        this.state.account.admin_Notes = value;
        this.setState({ account: this.state.account });
    }

    onSubmit(fields) {

        const admin_Contact = fields.admin_Contact;
        const admin_Notes = this.state.account.admin_Notes; // textarea change has been updated directly in state
        
        this.state.account.admin_Contact = admin_Contact;
        this.state.account.admin_Notes = admin_Notes;
        this.setState({ loading: true, account: this.state.account });

        ApiController.updateAccountAdminNotes({
            admin_Contact: admin_Contact,
            admin_Notes: admin_Notes
        }, callbackWrapper(this.updateAccountCallback.bind(this)).bind(this));
    }    

    updateAccountCallback(response) {
        if (response.data.error) {
            NotificationController.pushError(_(response.data.error.message), _("error.update"));
        } else {
            this.setState({ loading: false });
            NotificationController.pushSuccess(_("success.update"));
            this.loadAccount();
        }
    }


    render() {

        if (this.state.loading) return (<LoadingItemComponent />);

        const initialValues = {
            admin_Contact: this.state.account.admin_Contact,
            admin_Notes: this.state.account.admin_Notes
        };

        return (
            <Formik initialValues={initialValues} onSubmit={this.onSubmit.bind(this)}
                render={(props) => (
                    // <Form noValidate className="col-12 p-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>
                    <Form className="col-12 p-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>

                        <FormGroup className="mb-2">
                            <Field placeholder={_("text.admin.contact")} name="admin_Contact" component={InputForm} />
                        </FormGroup>

                        <div className="form-group">
                            <label for="txtNotes" className="mb-0">{_("text.admin.notes")}</label>
                            {/* <textarea id="txtNotes" className="form-control my-2" placeholder={_("text.admin.notes")} rows="6" onChange={(e) => this.changeRemarks(e.target.value)}> */}
                            <textarea id="txtNotes" className="form-control my-2" placeholder={_("text.admin.notes")} rows="6" onChange={(e) => this.changeAdminNotes(e.target.value)}>
                                {this.state.account.admin_Notes}
                            </textarea>
                        </div>

                        <div className="row">
                            <div className="offset-md-6 col-md-6 text-right">
                                <Button type="submit" className="col-12" color="success">{_("save")} <i className="fas fa-angle-right"></i></Button>
                            </div>
                        </div>
                    </Form>

                )}
            />
        );        
    }

}

export default withRouter(AdminNotesComponent);