import React, { Component } from 'react';
import { _ } from '../../../controllers/languageController';
import { withRouter } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Dropzone from 'react-dropzone'
import NotificationController from '../../../controllers/notificationController';
import ApiController, { callbackWrapper } from '../../../controllers/apiController';
import ConfigController from '../../../controllers/configController';

class UploadImageComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: true
        };
    }

    close() {
        this.setState({ open: false });
    }

    accept(file) {
        if (Array.isArray(file)) {
            file = file[0]; // nur 1 File
        }

        NotificationController.pushInfo(_("upload.started"));
        this.close();

        // CS 16/3/22: use new x.code field which contains the original (non-translated) value to search by
        ApiController.uploadFile(
            ConfigController.retrieveDocumentTypes().filter(
                x => x.code === "document.picture")[0].id,
                file, 
                callbackWrapper(this.callback.bind(this))
        );
    }

    callback(response) {
        if (response.data.error) {
            if (response.data.error.message === "error.validationFailed") {

                let result = `${_(response.data.error.message)}:\n`;
                for (let item in response.data.error.data) {
                    result += `${_(response.data.error.data[item])}\n`;
                }

                NotificationController.pushError(result, _("upload.failed"));
                return;
            }

            NotificationController.pushError(_(response.data.error.message), _("upload.failed"));
            return;
        }

        if (this.props.reload) {
            this.props.reload();
        }

        NotificationController.pushSuccess(_("upload.success"));
    }

    render() {
        return (
            <Modal className="modal-dialog-centered modal-lg" isOpen={this.props.show && this.state.open} toggle={this.close.bind(this)}>
                <ModalHeader toggle={this.close.bind(this)}>{_("notifications.uploadImage.header")}</ModalHeader>
                <ModalBody className="py-0">
                    <h4>{_("notifications.uploadImage.text")}</h4>
                    <Dropzone onDrop={this.accept.bind(this)}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="text-center">
                                <div className="py-4 upload" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p className="m-auto">{_("text.dragOrSelectImageToUpload")}</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.close.bind(this)}>{_("close")}</Button>
                </ModalFooter>
            </Modal>
        );
    }

}

export default withRouter(UploadImageComponent);