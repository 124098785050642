import React, { Component } from 'react';
import ApiController, { callbackWrapper } from '../../../controllers/apiController';
import InfiniteScroll from 'react-infinite-scroll-component';
import { _, _try } from '../../../controllers/languageController';
import AuthenticationController from '../../../controllers/authenticationController';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import LoadingItemComponent from '../loadingItemComponent';
import SecurityController from '../../../controllers/securityController';
import AccordionItemComponent from '../../_components/accordionItemComponent';

class MatchResultComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            items: [],
            contacts: [],
            contactsLoading: [],
            maxCount: 1,
            blockedCount: 0,
            adminImpersonating: false, // true if admin is currently impersonating this account which means it has more permissions
            adminShowAllContactDetails: false,  // if in impersoating mode this bool controls if all contact details are shown
            isJobSeeker: AuthenticationController.isPerson()
        };
    }

    componentDidMount() {

        //console.log(`FIRST ITEMS RECORD`)
        //console.log(this.props.data[0])

        // find out if admin is impersonating this account right now (through token with extra claim 'impersonatingAccountID')
        var inImpersonationMode = AuthenticationController.retrieveContent().impersonatingAccountID != null;

        this.setState({ 
            maxCount: this.props.data.length, 
            items: this.props.data.sort((a, b) => a.isBlacklisted - b.isBlacklisted || b.contactStatus - a.contactStatus || b.percent - a.percent), 
            blockedCount: this.props.data.filter(x => x.isBlacklisted === true).length,
            adminImpersonating: inImpersonationMode, // true if admin is currently impersonating which shows all details for instance
            loading: false });

        //this.load();
    }

    // load() {

    //     let length = 0;
    //     if (this.state) {
    //         length = this.state.items.length;
    //     }

    //     console.log("matchResultComponent::load function called")

    //     ApiController.retrieveMatching(this.props.profile, this.props.type == 1, length, 500,
    //         callbackWrapper((response) => {
                
    //             if (response.data.error) {
    //                 this.props.setCount(-1);
    //                 return;
    //             }

    //             let items = [...this.state.items];

    //             //
    //             // CS: 27/5/2020
    //             // introduced new attribute in result set called blacklistedByOther which indicates
    //             // if the profile match has been blacklisted (blocked) by the other party
    //             //
    //             // create data array with only non-blocked (blacklisted) by others entries
    //             let data =  response.data.result.items.filter(x => x.blacklistedByOther === false);

    //             data.forEach(element => {

    //                 // duplicates? if the id matches we have the match already
    //                 if (items.filter(x => x.id === element.id).length > 0) return;

    //                 items.push(element);
    //             });

    //             //this.setState({ maxCount: response.data.result.totalCount, items: items.sort((a, b) => a.isBlacklisted - b.isBlacklisted || b.contactStatus - a.contactStatus || b.percent - a.percent), loading: false });
    //             this.setState({ maxCount: data.length, 
    //                 items: items.sort((a, b) => a.isBlacklisted - b.isBlacklisted || b.contactStatus - a.contactStatus || b.percent - a.percent), 
    //                 blockedCount: items.filter(x => x.isBlacklisted === true).length,
    //                 loading: false });

    //             if (this.props.setCount) {
    //                 this.props.setCount(this.state.maxCount);
    //             }

    //         }).bind(this)
    //     );
    // }

    contactCommandCallback(response) {
        this.props.reload();
    }

    openMatch(e, match) {
        if (e) {
            e.stopPropagation();
        }

        this.props.history.push(`/match/compare/${this.props.profile}/${match.id}`);
    }

    //
    // CS 16/8/21: user sends a contact inquiry to the other party
    //
    sendInquiry(e, match) {
        e.stopPropagation();
        // this.setState({ loading: true });
        // ApiController.requestContact(this.props.profile, match.id, callbackWrapper(this.contactCommandCallback.bind(this)));
        // return;

        var self = this;

        this.setState({ loading: true });

        var allItems = this.state.items;
        // for person set ContactStatus.Connected, for pharmacy set ContactStatus.Requested
        allItems.filter(x => x.id === match.id)[0].contactStatus = this.state.isJobSeeker ? 2 : 1;   

        //ApiController.requestContact(this.props.profile, match.id, callbackWrapper(this.contactCommandCallback.bind(this)));
        ApiController.requestContact(this.props.profile, match.id, () => {

            self.setState({ 
                // items: allItems,
                items: allItems.sort((a, b) => a.isBlacklisted - b.isBlacklisted || b.contactStatus - a.contactStatus || b.percent - a.percent),
                loading: false
             });

        });
    }

    acceptInquiry(e, match) {
        e.stopPropagation();

        // this.setState({ loading: true });
        // ApiController.acceptContact(this.props.profile, match.id, callbackWrapper(this.contactCommandCallback.bind(this)));
        // return;

        var self = this;

        this.setState({ loading: true });

        var allItems = this.state.items;
        // set to ContactStatus.Connected
        allItems.filter(x => x.id === match.id)[0].contactStatus = 2;

        //ApiController.requestContact(this.props.profile, match.id, callbackWrapper(this.contactCommandCallback.bind(this)));
        ApiController.acceptContact(this.props.profile, match.id, () => {

            self.setState({ 
                items: allItems,
                loading: false
             });

        });

    }

    revokeInquiry(e, match) {
        e.stopPropagation();

        this.setState({ deleteContact: match });
    }

    deleteInquiry(e, match) {
        e.stopPropagation();

        this.setState({ deleteContact: match });
    }

    confirmDeleteContact() {
        let contact = this.state.deleteContact;

        // this.setState({ loading: true, deleteContact: undefined });
        // ApiController.deleteContact(this.props.profile, contact.id, callbackWrapper(this.contactCommandCallback.bind(this)));
        // return;

        var self = this;

        this.setState({ loading: true,  deleteContact: undefined });

        var allItems = this.state.items;
        // set to ContactStatus.Connected
        allItems.filter(x => x.id === contact.id)[0].contactStatus = 0;

        //ApiController.requestContact(this.props.profile, match.id, callbackWrapper(this.contactCommandCallback.bind(this)));
        ApiController.deleteContact(this.props.profile, contact.id, () => {

            self.setState({ 
                items: allItems,
                loading: false
             });

        });

    }

    cancelDeleteContact() {
        this.setState({ deleteContact: undefined });
    }

    whiteListContact(e, match) {
        e.stopPropagation();

        this.props.whiteListContact(match);

        // CS 16/8/21 - disabled and pushed to callback to parent
        // this.setState({ loading: true });
        // ApiController.toggleBlacklist(this.props.profile, match.id, callbackWrapper(this.contactCommandCallback.bind(this)));
    }

    blacklistContact = (e, match) => 
    {
        e.stopPropagation();
        this.setState({ blacklistContact: match });
    }

    cancelBlacklistContact() {
        this.setState({ blacklistContact: undefined });
    }

    confirmBlacklistContact() {
        let contact = this.state.blacklistContact;

        this.props.blackListContact(contact);

        //
        // CS 16/8/21 - disabled and pushed to callback to parent
        //
        //this.setState({ loading: true, deleteContact: undefined });
        //ApiController.toggleBlacklist(this.props.profile, contact.id, callbackWrapper(this.contactCommandCallback.bind(this)));
    }

    loadContactInformation(id) {

        if (!this.loading) this.loading = [];

        let loading = this.loading;
        if (loading[id]) return;

        loading[id] = true;
        ApiController.retrieveContactInformations(this.props.profile, id, callbackWrapper(((response) => {
            
            if (response.data.error) return;

            loading[id] = false;
            this.setState(prev => {
                let contacts = Object.assign({}, prev.contacts);
                contacts[id] = response.data.result;

                return {
                    ...prev,
                    contacts
                };
            })
        }).bind(this)).bind(this));
    }

    retrieveItemInformation(item) {

        if (!this.state.contacts[item.id] || this.loading[item.id]) {
            return (<h6>{_("contact.loading")}</h6>)
        }

        // CS 24/8/21: output of pharmacy details for job seekers
        if (this.state.isJobSeeker) {
            return (
                <h6>
                    {/* {_("placeholder.name")}: <span>{this.state.contacts[item.id].name}</span><br /> */}
                    {/* {_("placeholder.address")}: <span>{this.state.contacts[item.id].address}, {item.postalArea.code} {item.postalArea.name}</span><br /> */}
                    {_("placeholder.email")}: <span><a href={`mailto:${this.state.contacts[item.id].email}`} onClick={(e) => { e.stopPropagation() }}>{this.state.contacts[item.id].email}</a></span>
                    {/* ONLY admin's can see the phone number */}
                    {this.state.adminImpersonating && item.matchPhone && <span className="d-block">{_("placeholder.phone")}: {item.matchPhone}</span>}
                </h6>
            );
        }

        // CS 24/8/21: output of job seeker details for pharmacies
        return (
            <h6>
                {_("placeholder.name")}: <span>{this.state.contacts[item.id].name}</span><br />
                {/* {_("placeholder.address")}: <span>{this.state.contacts[item.id].address}</span><br /> */}
                <span className="d-block">
                    {_("placeholder.email")}: <a href={`mailto:${this.state.contacts[item.id].email}`} onClick={(e) => { e.stopPropagation() }}>{this.state.contacts[item.id].email}</a>
                </span>
                {/* ONLY admin's can see the phone number */}
                {this.state.adminImpersonating && item.matchPhone && <span className="d-block">{_("placeholder.phone")}: {item.matchPhone}</span>}
                {/* output of gender and nationality */}
                <span>
                    {_("placeholder.gender")}: {_("gender."+this.state.contacts[item.id].gender)}
                </span>
                <span className="ml-3">
                    {_("placeholder.nationality")}: {_("nationalities."+this.state.contacts[item.id].nationalityCode)}
                </span>
            </h6>
        );
    }

    renderItem = (item) => {

        // DEBUG
        // {(item.contactStatus == 2 || this.state.adminShowAllContactDetails) && this.retrieveItemInformation(item)}
        // console.log(`Name:${item.matchName}, contactStatus: ${item.contactStatus}, adminShowAllContactDetails:${this.state.adminShowAllContactDetails}`,item)

        return(
            <div key={item.id} className={"card text-left " + (item.isBlacklisted ? 'bg-light' : '')}>
                <div className="card-body" onClick={(() => this.openMatch(undefined, item)).bind(this)}>
                    <h5 className="card-title mb-0">
                        {/* fa-power-off fa-minus-circle fa-frown-o fa-toggle-off */}
                        {!item.isBlacklisted && <i className="fas fa-power-off float-right p-1" style={{cursor:'pointer'}} onClick={(e) => this.blacklistContact(e, item)} title={_try("contact.blacklistTooltip")} data-toggle="tooltip"></i>}
                        {/* fa-toggle-on fa-info-circle fa-pause fa-pause-circle */}
                        {item.isBlacklisted && <i className="fas fa-pause float-right p-1" style={{cursor:'pointer'}} onClick="(e) => e.stopPropagation();$(this).tooltip('hide');return false;" title={_try("contact.blacklistedTooltip")} data-toggle="tooltip"></i>}
                        <div className="row m-0">
                            <div className="col-lg-3 text-center m-auto">
                                {item.contactStatus == 2 &&
                                    <div>
                                        <img className="w-50 m-auto d-none d-lg-block" src={SecurityController.secureProfilePictureLink(this.props.profile, item.id)} onError={i => i.target.src=''} />
                                        <img className="w-25 m-auto d-lg-none" src={SecurityController.secureProfilePictureLink(this.props.profile, item.id)} onError={i => i.target.src=''} />
                                    </div>
                                }
                                <h2 className="m-auto">{parseFloat("" + (item.percent * 100)).toFixed(2)}%</h2>
                                {/* {item.lastView && <h6 className="text-muted">{_("text.lastSeen")} {new Date(item.lastView.replace("T", " ") + " GMT").toLocaleDateString()}</h6>} */}
                                {item.lastView && <h6 className="text-muted">{_("text.lastSeen")} {new Date(item.lastView.split(' ')[0]).toLocaleDateString()}</h6>}
                            </div>
                            <div className="col-lg-9">
                                {/* CS 18/8/21: remove default name printing for pharmacies but show pharmacy names by default to job seekers */}
                                {this.state.isJobSeeker == true && <span>{item.matchName}<br /></span>}
                                <span>{item.hoursPerWeek} {_("text.hoursPerWeek")}</span>
                                {/* job seekers see address in separate line */}
                                {this.state.isJobSeeker == true &&
                                    <span>
                                        <span><br/> {item.matchAddress}, {item.postalArea.countryCode.toUpperCase()}-{item.postalArea.code} {item.postalArea.name}</span>
                                    </span>
                                }
                                {/* pharmacies see address (with no street name) in single line */}
                                {this.state.isJobSeeker == false &&
                                    <span>
                                        <span>, {item.postalArea.countryCode.toUpperCase()}-{item.postalArea.code} {item.postalArea.name}</span>
                                    </span>
                                }
                                <br />
                                <h6>
                                    {item.availableInWeeks === 0 && <span className="text-success">{_("available.now")}</span>}
                                    {item.availableInWeeks > 0 && <span className="text-warning">{_("available.in")} {item.availableInWeeks} {_("text.week")}</span>}
                                    {/* {item.availableForNightlyShifts && <span> - {_("available.nightly")}</span>} */}
                                    {/* {item.availableAsReplacement && <span> - {_("available.replacement")}</span>} */}
                                    {item.licenceHolder && <span> - {_("placeholder.licenceHolder")}</span>}
                                </h6>
                                {/* CS 24/8/21: show always full pharmacy address and of course if contact relationship has been established */}
                                {/* CS 22/12/21: if admin is currently impersonating this account also show all details - acknowledged or not */}
                                {(item.contactStatus == 2 || this.state.adminShowAllContactDetails) && this.retrieveItemInformation(item)}
                            </div>
                        </div>
                    </h5>
                    <div className="text-right mt-lg-1 mt-2">
                        {item.isBlacklisted && <button className="btn btn-dark col-lg-auto btn-sm mb-3 mb-lg-0" onClick={(e) => this.whiteListContact(e, item)} title={_try("contact.whitelistTooltip")} data-toggle="tooltip">{_("contact.whitelist")}</button>}

                        {item.isBlacklisted == false && item.contactStatus == 0 && <button className="btn btn-success col-lg-auto btn-sm mb-3 mb-lg-0" onClick={(e) => this.sendInquiry(e, item)} title={_try("contact.inquiryTooltip")} data-toggle="tooltip">{_("contact.inquiry")}</button>}
                        {item.contactStatus == 1 && this.state.isJobSeeker && <button className="btn btn-success col-lg-auto btn-sm mb-3 mb-lg-0" onClick={(e) => this.acceptInquiry(e, item)} title={_try("contact.acceptTooltip")} data-toggle="tooltip">{_("contact.accept")}</button>}
                        {item.contactStatus == 1 && <button className="btn btn-warning col-lg-auto btn-sm mb-3 mb-lg-0" onClick={(e) => this.revokeInquiry(e, item)} title={_try("contact.revokeTooltip")} data-toggle="tooltip">{_("contact.revoke")}</button>}
                        {item.contactStatus == 2 && <button className="btn btn-danger col-lg-auto btn-sm mb-3 mb-lg-0" onClick={(e) => this.revokeInquiry(e, item)} title={_try("contact.deleteTooltip")} data-toggle="tooltip">{_("contact.delete")}</button>}

                        {/* CS 25/8/21: OPEN MATCH - PROFIL ANZEIGEN */}
                        <button className="btn btn-secondary col-lg-auto btn-sm mb-2 mb-lg-0" onClick={(e) => this.openMatch(e, item)}>{_("text.openMatch")} <i className="fas fa-angle-right"></i></button>

                    </div>
                </div>
            </div>

        );
    }

    listUnblockedItems = () => {

        if (this.state.items.length - this.state.blockedCount == 0) {
            return (
                <h5>{_("matching.emptyMatches")}</h5>
            );
        }

        //
        // render all non-blocked (non-blacklisted) matches
        //
        return this.state.items.filter(x => x.isBlacklisted === false).map(x => {

            if ((x.contactStatus === 2 || this.state.adminShowAllContactDetails) && !this.state.contacts[x.id]) {
                this.loadContactInformation(x.id);
            }

            return this.renderItem(x);

        });
    }

    listBlockedItems = () => {

        if (this.state.blockedCount == 0) {
            return (
                <h5>{_("matching.emptyMatches")}</h5>
            );
        }

        //
        // render all non-blocked (non-blacklisted) matches
        //
        return this.state.items.filter(x => x.isBlacklisted === true).map(x => {

            if ((x.contactStatus === 2 || this.state.adminShowAllContactDetails) && !this.state.contacts[x.id]) {
                this.loadContactInformation(x.id);
            }

            return this.renderItem(x);

        });
    }

    render() {
        if (this.state.loading) {
            return (<LoadingItemComponent />);
        }

        return [(
            <Modal className="modal-dialog-centered modal-lg" isOpen={this.state.blacklistContact !== undefined} toggle={this.cancelBlacklistContact.bind(this)}>
                <ModalHeader toggle={this.cancelBlacklistContact.bind(this)}>{_("text.confirm.text")}</ModalHeader>
                <ModalBody className="py-0">
                    {_("text.confirm.blacklistContact")}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.cancelBlacklistContact.bind(this)}>{_("cancel")}</Button>
                    <Button color="danger" onClick={this.confirmBlacklistContact.bind(this)}>{_("confirm")}</Button>
                </ModalFooter>
            </Modal>
        ), (
            <Modal className="modal-dialog-centered modal-lg" isOpen={this.state.deleteContact} toggle={this.cancelDeleteContact.bind(this)}>
                <ModalHeader toggle={this.cancelDeleteContact.bind(this)}>{_("text.confirm.text")}</ModalHeader>
                <ModalBody className="py-0">
                    {_("text.confirm.deleteContact")}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.cancelDeleteContact.bind(this)}>{_("cancel")}</Button>
                    <Button color="danger" onClick={this.confirmDeleteContact.bind(this)}>{_("confirm")}</Button>
                </ModalFooter>
            </Modal>
        ), (
            <div>
                {/* For all admin impersonators show toggle to display all contact details, settled or not */}
                {this.state.adminImpersonating && 

                    // toggle button for impersonator to turn on/off contact details
                    <div className="m-0 mb-2 row">
                        <label className="custom-toggle my-auto ml-auto">
                            {/* <input type="checkbox" checked={this.state.passwordVisible} onChange={() => this.setState({ passwordVisible: !this.state.passwordVisible })} /> */}
                            <input type="checkbox" checked={this.state.adminShowAllContactDetails} onChange={() => this.setState({adminShowAllContactDetails: !this.state.adminShowAllContactDetails})} />
                            <span className="custom-toggle-slider rounded-circle"></span>
                        </label>
                        {/* <h5 className="col-auto m-0">{_("placeholder.displayPassword")}</h5> */}
                        <h5 className="col-auto m-0">{_("placeholder.displayAllContactDetails")}</h5>
                    </div>

                }
                {this.state.blockedCount > 0 ? (
                    <AccordionItemComponent className="accordion-indented" title={`${_("matching.blocked")} (${this.state.blockedCount})`} icon="fa-power-off">
                        {this.listBlockedItems()}
                    </AccordionItemComponent>
                ) : ( <div></div>)
                }
                {this.listUnblockedItems()}

            </div>
        )];
    }

}

export default withRouter(MatchResultComponent);