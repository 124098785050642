import React, { Component } from 'react';
import CenteredComponent from '../_components/centeredComponent';
import { _, _try } from '../../controllers/languageController';
import MatchResultComponent from '../_components/matching/matchResultComponent';
import SecurityController from '../../controllers/securityController';
import ConfigController from '../../controllers/configController';
import AccordionItemComponent from '../_components/accordionItemComponent';
import ApiController, { callbackWrapper } from '../../controllers/apiController';
import LoadingItemComponent from '../_components/loadingItemComponent';

export default class Matching extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            matchesReplacement: [],
            allMatches: []
        };
    }


    componentDidMount() {
        this.loadMatchingData();
    }

    componentWillMount() {

        if (!this.props.match.params.profile) {
            this.props.history.push("/");
            return;
        }

        this.setState({ profileId: this.props.match.params.profile, key: 0, count: { replacement: 0, contacted: 0, matching: 0 } });
    }

    loadMatchingData(){

        //
        // CS 6/10/21: load profile as well to change some display behaviour for licenceHolders and Replacements
        //
        ApiController.retrieveProfile(this.state.profileId,
            callbackWrapper((response) => {

                // load the jobID from the profile and then load the jobType.item as code (native, not translated)
                var jobType = ConfigController.retrieveNativeJobType(response.data.result.jobID);
                this.setState({ profilePositionForPharmacist: jobType.item.toLowerCase() == "jobtypes.pharmacist" });

            }).bind(this)
        );        

        let offset = 0;
        let replacementsOnly = 0;
        ApiController.retrieveMatching(this.state.profileId,replacementsOnly, offset, 500,

            callbackWrapper((response) => {
                
                if (response.data.error) {
                    this.props.setCount(-1);
                    return;
                }

                //
                // CS: 27/5/2020
                // introduced new attribute in result set called blacklistedByOther which indicates
                // if the profile match has been blacklisted (blocked) by the other party
                //
                // create data array with only non-blocked (blacklisted) by others entries
                //let allMatches =  response.data.result.items.filter(x => x.blacklistedByOther === false);
                let allMatches =  response.data.result.items;
                let replacementOnlyData = allMatches.filter(x => x.availableAsReplacement == true);
                let licenceHoldersOnlyData = allMatches.filter(x => x.licenceHolder == true);
                let covidTesterOnlyData = allMatches.filter(x => x.covidTester == true);

                this.setState({ 
                    matchesReplacement: replacementOnlyData,
                    matchesLicenceHolders: licenceHoldersOnlyData,
                    matchesCovidTesters: covidTesterOnlyData,
                    allMatches: allMatches,
                    count: { 
                        replacement: replacementOnlyData.length, 
                        licenceHolders: licenceHoldersOnlyData.length, 
                        covidTesters: covidTesterOnlyData.length, 
                        contacted: 0, 
                        matching: allMatches.length },
                    loading: false
                 });

            }).bind(this)
        );        
    }

    forceRerender() {
        this.setState((prev, props) => ({
            key: prev.key + 1,
            loading: false
        }));
    }

    setCount(name, count) {
        let current = Object.assign({}, this.state.count);
        current[name] = count;

        this.setState({ count: current });
    }

    //
    // CS 16/8/21
    //
    blackListContact(item) {

        // this.setState({ loading: true });

        // find contact and set blacklisted to true
        var allMatches = this.state.allMatches;
        allMatches.filter(x => x.id === item.id)[0].isBlacklisted = true;

        this.setState({ 
            matchesReplacement: allMatches.filter(x => x.availableAsReplacement == true),
            allMatches: allMatches,
            // loading: false
         });

         // api call to blacklist contact and rerender when completed
         ApiController.toggleBlacklist(this.state.profileId, item.id, callbackWrapper(this.forceRerender.bind(this)));
    }

    //
    // CS 16/8/21
    //
    whiteListContact(item) {

        // this.setState({ loading: true });

        // find contact and set blacklisted to true
        var allMatches = this.state.allMatches;
        allMatches.filter(x => x.id === item.id)[0].isBlacklisted = false;

        this.setState({ 
            matchesReplacement: allMatches.filter(x => x.availableAsReplacement == true),
            allMatches: allMatches,
            // loading: false
         });

         // api call to blacklist contact and rerender when completed
         ApiController.toggleBlacklist(this.state.profileId, item.id, callbackWrapper(this.forceRerender.bind(this)));
    }

    render() {
        if (this.state.loading) {
            return (<LoadingItemComponent />);
        }

        return (
            <CenteredComponent key={this.state.key} size={9} className="animated fadeIn">
                <div className="my-3">

                    {/* Replacements */}
                    {this.state.count.replacement >= 0 && (
                        <AccordionItemComponent title={`${_("matching.replacement")} (${this.state.count.replacement})`} subtitle={`${_("matching.replacement_subtitle")}`}>
                            <MatchResultComponent 
                                data={this.state.matchesReplacement} 
                                profile={this.state.profileId} type={1} 
                                reload={this.forceRerender.bind(this)} 
                                blackListContact={this.blackListContact.bind(this)} 
                                whiteListContact={this.whiteListContact.bind(this)} 
                                setCount={((c) => this.setCount("replacement", c)).bind(this) } />
                        </AccordionItemComponent>
                    )}

                    {/* LicenseHolders */}
                    {this.state.profilePositionForPharmacist && this.state.count.licenceHolders > 0 && (
                        <AccordionItemComponent title={`${_("matching.licenceHolders")} (${this.state.count.licenceHolders})`} subtitle={`${_try("matching.licenceHolders_subtitle")}`}>
                            <MatchResultComponent 
                                data={this.state.matchesLicenceHolders} 
                                profile={this.state.profileId} type={1} 
                                reload={this.forceRerender.bind(this)} 
                                blackListContact={this.blackListContact.bind(this)} 
                                whiteListContact={this.whiteListContact.bind(this)} 
                                setCount={((c) => this.setCount("licenceHolders", c)).bind(this) } />
                        </AccordionItemComponent>
                    )}

                    {/* Covid-19 Tester */}
                    {this.state.count.covidTesters > 0 && (
                        <AccordionItemComponent title={`${_("matching.covidTesters")} (${this.state.count.covidTesters})`} subtitle={`${_try("matching.covidTesters_subtitle")}`}>
                            <MatchResultComponent 
                                data={this.state.matchesCovidTesters} 
                                profile={this.state.profileId} type={1} 
                                reload={this.forceRerender.bind(this)} 
                                blackListContact={this.blackListContact.bind(this)} 
                                whiteListContact={this.whiteListContact.bind(this)} 
                                setCount={((c) => this.setCount("covidTesters", c)).bind(this) } />
                        </AccordionItemComponent>
                    )}

                    <AccordionItemComponent title={`${_("matching.matching")} (${this.state.count.matching})`} show>
                        <MatchResultComponent 
                            data={this.state.allMatches} 
                            profile={this.state.profileId} type={0} 
                            reload={this.forceRerender.bind(this)} 
                            blackListContact={this.blackListContact.bind(this)} 
                            whiteListContact={this.whiteListContact.bind(this)} 
                            setCount={((c) => this.setCount("matching", c)).bind(this)} />
                    </AccordionItemComponent>
                </div>
            </CenteredComponent>
        );
    }

}