import React, { Component } from 'react';
import { Form, FormGroup, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import AccordionItemComponent from '../accordionItemComponent';
import { withRouter } from 'react-router-dom';
/* import SelectInputComponent, { optionFilter } from '../selectInputComponent'; */
import CenteredComponent from '../centeredComponent';
import { _ } from '../../../controllers/languageController';
import ConfigController from '../../../controllers/configController';
import ApiController, {callbackWrapper} from '../../../controllers/apiController';

import * as Yup from "yup";
import SelectInputComponent, { postalAreaFilter, optionFilter } from '../selectInputComponent';
import { InputForm } from '../inputFormComponent';
import { Formik, Field } from 'formik';
import AuthenticationController from '../../../controllers/authenticationController';
import LoadingItemComponent from '../loadingItemComponent';
import NotificationController from '../../../controllers/notificationController';

class AcceptTermsComponent extends Component {
    original = {phoneCountryCode:null, phone:null};
    lastSmsNumber = null; // will receive the last used SMS phone number for resending the code
    lastSmsMessage = null; // will receive the last message sent to user for resending
    sentSmsCode = null; // will receive the random number sent via SMS to verify phone number changes

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            deleteAccount: false,
            content: null,  // will receive the loaded account model for authenticated user
            requiresSmsConfirmation: false,  // set to true if UI is supposed to wait for SMS number confirmation
            isResendButtonDisabled: false   // is SMS resend button disabled for a minute?
        };
    }

    componentWillMount() {
        this.loadAccountDetails();
    }

    loadAccountDetails() {
        if (AuthenticationController.isPerson()) {
            ApiController.retrieveAccountPerson(callbackWrapper(this.loadAccountCallback).bind(this));
        } else {
            ApiController.retrieveAccountPharmacy(callbackWrapper(this.loadAccountCallback).bind(this));
        }
    }

    loadAccountCallback = (response) => {
        this.setState({ content: response.data.result, loading:false } );
        this.original.phoneCountryCode = this.state.content.account.phoneCountryCode;
        this.original.phone = this.state.content.account.phone;
    }

    // PHONE-COUNTRY-CODE
    selectPhoneCountryCode(item) {
        let content = Object.assign({}, this.state.content);
        content.account.phoneCountryCode = item.id;

        this.setState({ content: content, requiresSmsConfirmation: false });
    }

    // disable SMS code element if user changed the phone number so he needs to resubmit first
    onChangePhoneNumber(item) {
        this.setState({ requiresSmsConfirmation: false });
    }

    resendSmsCode(e) {
        if ( !this.lastSmsNumber || !this.lastSmsMessage) return; // should be set with last used sms phone number (incl. country code)
        NotificationController.sendSMS(this.lastSmsNumber, this.lastSmsMessage);

        // now, disable the resend button for 1 minute
        this.setState({
            isResendButtonDisabled: true
        });
    
        // **** here's the timeout ****
        setTimeout(() => this.setState({ isResendButtonDisabled: false }), 60000);        
    }



    onSubmit(fields) {

        const newPhoneCountryCode = this.state.content.account.phoneCountryCode;    // has been set in state during change
        var newPhone = fields.phone.replace(/[^0-9]/g, '');  // comes from input field, strip all non-numeric and special characters // .replace(/[^a-zA-Z0-9]/g, '');
        // remove leading zero's
        if (newPhone.charAt(0)=='0') newPhone = newPhone.substr(1);


        if (!newPhoneCountryCode || !newPhone) return; // make sure both fields are set
        const hasNumberChanged = newPhoneCountryCode != this.original.phoneCountryCode || newPhone != this.original.phone;


        //
        // SEND SMS CODE (random 4 digit number)
        // If user changed phone number (or country code).
        //
        // console.group('**onSubmit**')
        // console.log(`original.code: ${this.original.phoneCountryCode} -> ${newPhoneCountryCode}`)
        // console.log(`original.phone: ${this.original.phone} -> ${newPhone}`)

        // for AGB confirmations we ALWAYS require a SMS code verification in case already existing users entered a bogus number
        // if ( hasNumberChanged)
        if ( true)
        {
            if ( !this.state.requiresSmsConfirmation){
                const randomNumber = NotificationController.generateRandomNumber(4); // 4 digits
                this.sentSmsCode = randomNumber;    // remember sent SMS code to be confirmed
                var message = `${_('notification.randomNumberSMS')} ${randomNumber}`;
                var tel = `+${newPhoneCountryCode}${newPhone}`

                this.lastSmsNumber = tel;    // remember last used sms number for resending the code
                this.lastSmsMessage = message;
                NotificationController.sendSMS(tel, message);
                this.setState({requiresSmsConfirmation: true}); // state change to show confirmation field
                // console.log(`Sent random number: ${randomNumber}`)
                return;
            }
            else{
                // console.log(`Confirmation already turned on, expecting code '${this.sentSmsCode}'. Here are all fields.`)
                if ( fields['confirmationCode'] != this.sentSmsCode){
                    this.setState({enteredInvalidSmsCode: true}); // state change to show confirmation field
                    // console.log(`Expected '${this.sentSmsCode}' but entered '${fields['confirmationCode']}'`);
                    return;
                }
            }
        }
        // console.groupEnd('**onSubmit**')

        this.sentSmsCode = null;
        this.setState({ loading: true, fields: fields, requiresSmsConfirmation: false, enteredInvalidSmsCode:false });
        if ( hasNumberChanged) {

            ApiController.updateAccount({
                phoneCountryCode: this.state.content.account.phoneCountryCode,
                phone: newPhone,
                postalAreaID: this.state.content.account.postalAreaID
            }, callbackWrapper(this.updateAccountCallback.bind(this)).bind(this));
        }
        else{
            // confirm the acceptance of the mandatory change
            ApiController.mandatoryChange(this.props.id, {}, this.props.onSubmit());        
        }
    }

    updateAccountCallback(response) {
        if (response.data.error) {
            NotificationController.pushError(_(response.data.error.message), _("error.update"));
        } else {
            ApiController.mandatoryChange(this.props.id, {}, this.props.onSubmit());
        }
    }

    deleteAccount() {
        this.setState({ deleteAccount: true });
    }

    confirmDeleteAccount() {
        ApiController.deleteAccount(callbackWrapper((response) => {
            if (response.data.error) {
                NotificationController.pushError(_(response.data.error));
                return;
            }

            NotificationController.pushSuccess(_("success.accountDeleted"));
            AuthenticationController.logout();
            this.props.history.push("/");
        }).bind(this));
    }

    cancelDeleteAccount() {
        this.setState({ deleteAccount: false });
    }

    render() {

        if (this.state.loading) {
            return (<LoadingItemComponent />);
        }

        const validationSchema = Yup.object().shape({
            phone: Yup.string()
            .min(6, _("invalid.phone"))
            .required(_("no.phone")),
        });

        const initialValues = {
            phone: this.state.content.account.phone
        };

        return [
            (
                <Modal className="modal-dialog-centered modal-lg" isOpen={this.state.deleteAccount} toggle={this.cancelDeleteAccount.bind(this)}>
                    <ModalHeader toggle={this.cancelDeleteAccount.bind(this)}>{_("text.confirm.text")}</ModalHeader>
                    <ModalBody className="py-0">
                        {_("text.confirm.deleteAccount")}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.cancelDeleteAccount.bind(this)}>{_("cancel")}</Button>
                        <Button color="danger" onClick={this.confirmDeleteAccount.bind(this)}>{_("confirm")}</Button>
                    </ModalFooter>
                </Modal>
            ),
            (
                <CenteredComponent size={12} className="animated fadeIn agb-accept-page">


                    {/* <h1 className="mb-2">{_("text.acceptTerms.header")}</h1>
                    <h3 className="mb-2 offset-lg-2 col-lg-8">{_("text.acceptTerms.subtitle")}</h3> */}
                    <div className="offset-lg-2 col-lg-8 mb-0 agb-header">

                        <div className="">
                            <div>
                                <h1>Verifizierung Ihrer Handynummer</h1>
                                <p>
                                    Vielen Dank, dass Sie unseren Service nutzen. Um die Sicherheit Ihres Accounts zu gewährleisten und einen reibungslosen Zugang sicherzustellen, bitten wir Sie, Ihre Handynummer zu verifizieren.                                    
                                </p>
                            </div>

                            <AccordionItemComponent title="Grund und Verwendung" icon="fa-info-circle" className="accordion">
                                <ul className="agb-sms-reasons">
                                    <li><b>Warum?</b> Ihre Handynummer dient zur sicheren Verifizierung Ihres Accounts.</li>
                                    <li><b>Wie wird sie verwendet?</b> Wir senden Ihnen einen einmaligen Bestätigungscode per SMS, um Ihre Anmeldung zu bestätigen.</li>
                                    <li><b>Datenschutz:</b> Ihre Nummer wird <u>ausschließlich</u> von Apopersonal genutzt. Wir geben Ihre Daten nicht an Dritte oder potenzielle Arbeitgeber weiter.</li>
                                </ul>
                            </AccordionItemComponent>

                            <AccordionItemComponent title="Aktualisierung unserer AGB" icon="fa-info-circle" className="accordion">
                                <div>
                                    Bitte beachten Sie, dass wir unsere Allgemeinen Geschäftsbedingungen entsprechend angepasst haben, um diese Verifizierungsmethode zu berücksichtigen.
                                    <br />
                                    {ConfigController.isPharmacyEdition() && 
                                        <a href="https://www.apopersonal.com/agb" target="_blank">{_("text.showTerms")}</a>
                                    }
                                    {ConfigController.isHealthcareEdition() && 
                                        <a href="https://www.pflege-finden.com/agb" target="_blank">{_("text.showTerms")}</a>
                                    }
                                </div>
                            </AccordionItemComponent>

                        </div>


                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmit.bind(this)}
                            render={(props) => (

                            // <Form noValidate className="col-12 p-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>
                            <Form className="col-12 p-0 edit-account-component" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>

                                <FormGroup className="mb-2 profile-account-tel">
                                    <SelectInputComponent
                                            required submitted
                                            onSelect={this.selectPhoneCountryCode.bind(this)}
                                            onFilter={optionFilter(ApiController.getPhoneCountryCodeList())}
                                            default={ApiController.getPhoneCountryCode(this.state.content.account.phoneCountryCode)}
                                            placeholder={_("placeholder.phoneCountryCode")} />
                                    <Field placeholder={_("placeholder.phone")} required inputmode="numeric" name="phone" component={InputForm} onInput={this.onChangePhoneNumber.bind(this)}/>
                                </FormGroup>

                                {/* SMS NUMBER CONFIRMATION */}
                                { this.state.requiresSmsConfirmation &&
                                    <FormGroup className={'mb-2 sms-confirmation ' + (this.state.enteredInvalidSmsCode ? 'sms-invalid' : '')} test-number={window['runtimeConfig'].smsTestingWithSmsNumber}>
                                        <Field placeholder={_("placeholder.phoneSmsConfirmation")} autoFocus required name="confirmationCode" type="text" inputmode="numeric" component={InputForm} />
                                        <Button type="button" 
                                            disabled={this.state.isResendButtonDisabled} 
                                            onClick={(e) => this.resendSmsCode(e) } 
                                            className="col-12" >
                                                {this.state.isResendButtonDisabled ? _("placeholder.phoneSmsResendDisabled"): _("placeholder.phoneSmsResend")} 
                                                <i className="fas fa-angle-right"></i>
                                        </Button>
                                    </FormGroup>
                                }

                                <div className="row mt-3">
                                    <div className="offset-md-6 col-md-6 text-right">
                                        <Button type="submit" className="col-12" color="success">{_("accept")} <i className="fas fa-angle-right"></i></Button>
                                    </div>
                                </div>

                                <span className="delete-message row mt-4 text-left">
                                    Falls Sie sich entscheiden, unseren Service nicht mehr nutzen zu wollen, k&ouml;nnen Sie Ihr Konto l&ouml;schen.
                                </span>

                                <div className="row mt-2">
                                    <div className="offset-md-6 col-md-6 text-right">
                                        <Button className="col-12" color="danger" onClick={this.deleteAccount.bind(this)}><i className="fas fa-user-times"></i> {_("text.account.delete")}</Button>
                                    </div>
                                </div>


                            </Form>

                        )}/>
                    </div>
                </CenteredComponent>
            )
        ];
    }

}

export default withRouter(AcceptTermsComponent);