import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router';

import PublicLayout from './_layouts/publicLayout';

import AuthenticationController from '../controllers/authenticationController';

import Login from './account/login';
import Profile from './profile/profile';
import Activation from './account/activation';
import ResetPassword from './account/resetPassword';
import Register from './account/register';
import MandatoryChange from './account/mandatoryChange';
import Matching from './profile/matching';
import MatchingCompare from './profile/matchingCompare';
import CreateProfile from './profile/createProfile';
import EditProfile from './profile/editProfile';
import Verify from './account/verify';
import AdminIndexPage from './admin/adminIndexPage';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (

        AuthenticationController.isMandatoryChange()
            ? <Redirect to={{ pathname: "/actionRequired", state: { from: props.location } }} />
            : (AuthenticationController.isAuthenticated()
                ? <Component key={Math.random()} {...props} />
                : <Redirect to={{ pathname: "/login", state: { from: props.location } }} />)

    )} />
);

export default class App extends Component {

    render() {
        return (
            <PublicLayout>
                {/* <PrivateRoute exact path="/" component={(props) => (<Redirect to={{ pathname: "/profile", state: { from: props.location } }} />)} /> */}
                {/* CS: 15/5/20 added alternative default route for administrators */}
                <PrivateRoute exact path="/" component={
                        (props) => 
                        (
                            (
                                AuthenticationController.isAdmin()
                                ? <Redirect to={{ pathname: "/admin", state: { from: props.location } }} />
                                : <Redirect to={{ pathname: "/profile", state: { from: props.location } }} />
                            )
                        )
                    } />

                <Route exact path="/login" component={Login} />
                <Route exact path="/reset" component={ResetPassword} />
                <Route exact path="/activation/:token" component={Activation} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/register/:type" component={Register} />
                <Route exact path="/actionRequired" component={MandatoryChange} />
                <Route exact path="/verify/:token" component={Verify} />

                <PrivateRoute exact path="/profile" component={Profile} />
                <PrivateRoute exact path="/edit/:profile" component={EditProfile} />
                <PrivateRoute exact path="/create" component={CreateProfile} />

                <PrivateRoute exact path="/match/:profile" component={Matching} />
                <PrivateRoute exact path="/match/compare/:profile/:otherProfile" component={MatchingCompare} />

                <Route exact path="/admin" component={AdminIndexPage} />

            </PublicLayout>
        );
    }

}