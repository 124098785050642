import React, { Component } from 'react';
import CenteredComponent from '../centeredComponent';
import AccordionItemComponent from '../accordionItemComponent';
import { withRouter } from 'react-router-dom';
import LanguageController, { _ } from '../../../controllers/languageController';
import { Form, Button, FormGroup, Input } from 'reactstrap';
import { Formik } from 'formik';
import AuthenticationController from '../../../controllers/authenticationController';
import ApiController, { callbackWrapper } from '../../../controllers/apiController';
import ConfigController from '../../../controllers/configController';

class ProfileAvailabilityComponent extends Component {

    constructor(props) {
        // onChange(data) event
        super(props);
        this.state = {
            loading: false,
            submitted: false,
            type: AuthenticationController.isPerson() ? "person" : "pharmacy",
            isPerson: AuthenticationController.isPerson(),
            isAvailabilitiesOpen: false,
            // job: this.props.job || {
            //     jobType: this.retrieveJobTypes()[0],
            //     distance: 0
            // },
            selection: this.props.selection || {
                other: {},
                otherText: {},
                selected: {
                    language: {},
                    cashierSystem: {},
                    customer: {},
                    manufactur: {},
                    admin: {},
                    logistic: {},
                    marketing: {}
                }
            },
            //timePeriods : this.props.data.map(x => { return { periodStart: x.periodStart.substring(0,10), periodEnd: x.periodEnd.substring(0,10) } }) 
            timePeriods : this.props.data || [],
            isViewMode: (this.props.mode && this.props.mode.toLowerCase() == "view") || false   // mode, by default EDIT, optionally VIEW
        };

        // console.log("**DEBUG IN ProfileTimePeriodsComponent**")
        // console.table(this.state.timePeriods)
    }

    componentWillMount() {

        // set toggle accordingly
        this.setState({isAvailabilitiesOpen: this.state.timePeriods.length > 0})
        // if in VIEW mode and no items exist yet - create a dummy entry
        if ( this.state.isViewMode == false && (!this.state.timePeriods || this.state.timePeriods.length == 0)) 
        {
            var list = this.state.timePeriods;
            list.unshift({periodStart:"", periodEnd: ""});
            this.setState({timePeriods: list});
        }
    }

    componentWillReceiveProps (newProps) {

        // prop default has changed by parent -> reflect the changed value immediately
        //if( newProps.value !== this.props.value) this.selectItem(newProps.value);

    }    

    //
    // Behaviour control events for Availabilities
    //

    onAddNewTimePeriod(event){
        event.preventDefault();
        var list = this.state.timePeriods;

        // CS 8/3/22: check if there is already an empty entry - if yes ignore this click
        if (list.filter(x => x.periodStart == "" || x.periodEnd == "").length > 0) return;

        list.unshift({periodStart:"", periodEnd: ""});
        this.setState({timePeriods: list});
        //console.log(`onAddNewTimePeriod++`)
    }

    onChangePeriodStart(index, event) {
        var newValue = event.target.value;
        var list = this.state.timePeriods;
        list[index].periodStart = newValue;
        this.setState({timePeriods: list});
        this.props.onChange(list); // send changes to parent
        // console.log(`onChangePeriodStart[${index}] = `, newValue)
    }

    onChangePeriodEnd(index, event) {
        var newValue = event.target.value;
        var list = this.state.timePeriods;
        list[index].periodEnd = newValue;
        this.setState({timePeriods: list});
        this.props.onChange(list); // send changes to parent
        // console.log(`onChangePeriodEnd[${index}] = `, event.target.value)
    }

    onDeleteTimePeriod(index, event){
        event.preventDefault();
        var list = this.state.timePeriods;
        list.splice(index,1);
        this.setState({timePeriods: list});
        this.props.onChange(list); // send changes to parent
        // console.log(`onDeleteTimePeriod[${index}] = deleted`)
    }

    onChangeToggle() {

        var newIsOpen = !this.state.isAvailabilitiesOpen;

        this.setState({ isAvailabilitiesOpen: newIsOpen });
        if ( newIsOpen )
            this.props.onChange(this.state.timePeriods)
        else
            this.props.onChange(null)
    }

    calcMinDate(item) {
        if (item.periodStart == '') return this.dateFormatted();

        return this.dateFormatted(new Date(item.periodStart));
    }
    calcMaxDate(item) {
        if (item.periodEnd == '') return '';

        return this.dateFormatted(new Date(item.periodEnd));
    }

    /// CS 10/2/22: returns provided date in standard format YYYY-MM-DD
    dateFormatted(dt = new Date()) {

        var splinter = "-";

        var getDate = dt.getDate().toString();
        if (getDate.length == 1) getDate = "0"+getDate;  //example if 1 change to 01
        var getMonth = (dt.getMonth()+1).toString();
        if (getMonth.length == 1) getMonth = "0"+getMonth;  //example if 1 change to 01
        var getYear = dt.getFullYear().toString();
        
        return getYear +splinter+ getMonth +splinter+ getDate; // YYYY-MM-DD
    }


    render() {

        // don't display anything if in VIEW mode and if there aren't any items
        if ( this.state.isViewMode && this.state.timePeriods.length === 0) return null;

        var result = [];

        if ( this.state.isViewMode)
            result.push(this.renderViewList());
        else
        {
            result.push(this.renderToggle());
            result.push(this.renderEditList());
        }

        return result;
    }

    renderToggle()
    {
        return (
        <span className='span-grid-row title profile-availability-wrapper'>
            <FormGroup className="m-0 mb-1 row">
                <label className="custom-toggle my-auto">
                    <input  type="checkbox" checked={this.state.isAvailabilitiesOpen} onChange={this.onChangeToggle.bind(this)} disabled={this.state.isViewMode} />
                            {/* onChange={() => this.setState({ isAvailabilitiesOpen: !this.state.isAvailabilitiesOpen })} /> */}
                    <span className="custom-toggle-slider rounded-circle"></span>
                </label>
                <h5 className="col-auto m-0">{ConfigController.isHealthcareEdition() ? _("placeholder.health.titleProfileAvailability") : _("placeholder.titleProfileAvailability")}</h5>
            </FormGroup>
        </span>
        )

    }

    renderEditList()
    {
        if ( !this.state.isAvailabilitiesOpen) return;

        return (

            <div className="profile-availability">
                <h1>{ConfigController.isHealthcareEdition() ? _("placeholder.health.profileAvailabilityHeader") : _("placeholder.profileAvailabilityHeader")}</h1>
                <div className="container">

                    <div className="description">

                        <a href="##" className="button btn-add-item" onClick={(evt) => {this.onAddNewTimePeriod(evt)}} >
                            <span class="material-icons">add</span>
                            {_("placeholder.profileAvailabilityAddNew")}
                        </a>

                        <p className="muted">
                            {_("placeholder.profileAvailabilityDescription")}
                        </p>

                    </div>

                    <div className="item-list">

                        {this.state.timePeriods.map((item, index) => 

                            <div className="item">
                                <input type="date" min={this.calcMinDate(item)} max={this.calcMaxDate(item)} value={item.periodStart.substring(0,10)} onChange={(evt) => this.onChangePeriodStart(index, evt)} />
                                <span>{_("placeholder.profileAvailabilityDateUntil")}</span>
                                <input type="date" min={this.calcMinDate(item)} value={item.periodEnd.substring(0,10)} onChange={(evt) => this.onChangePeriodEnd(index, evt)} />
                                <a href="#" className="btn-delete-item" onClick={(evt) => {this.onDeleteTimePeriod(index, evt)}} >&times;</a>
                            </div>


                        )}

                    </div>
                </div>
            </div>  

        )      

    }

    renderViewList()
    {
        if ( !this.state.isAvailabilitiesOpen) return;

        //const localDateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        //console.log(date.toLocaleDateString("nl",{year:"2-digit",month:"2-digit", day:"2-digit"}));
        // const localDateOptions = { weekday: 'short', year: '2-digit', month: 'short', day: 'numeric' };
        // const localDateOptions = { year: '2-digit', month: 'short', day: 'numeric' };
        const localDateOptions = { weekday: 'short', year: '2-digit', month: '2-digit', day: 'numeric' };

        return (

            <div className="profile-availability view-mode">

                <h5>{_("placeholder.titleProfileAvailability")}</h5>

                <div className="item-list">

                    {this.state.timePeriods.map((item, index) => 

                        <div className="item">
                            <input type="text" value={new Date(item.periodStart).toLocaleDateString(undefined, localDateOptions)} readOnly={true} disabled={true} />
                            {/* <span>{new Date(item.periodStart).toLocaleDateString(undefined, localDateOptions)}</span> */}
                            <span>{_("placeholder.profileAvailabilityDateUntil")}</span>
                            <input type="text" value={new Date(item.periodEnd).toLocaleDateString(undefined, localDateOptions)} readOnly={true} disabled={true} />
                            {/* <span>{new Date(item.periodEnd).toLocaleDateString(undefined, localDateOptions)}</span> */}
                        </div>

                    )}

                </div>
            </div>  

        )      

    }

}

export default withRouter(ProfileAvailabilityComponent);

