import React, { Component } from 'react';
import { Form, Button, FormGroup, Input, FormFeedback } from 'reactstrap';
import { Formik, Field } from "formik";
import * as Yup from "yup";

import CenteredComponent from '../_components/centeredComponent';
import { InputForm } from '../_components/inputFormComponent';
import ApiController, { callbackWrapper } from '../../controllers/apiController';
import LoadingController from '../../controllers/loadingContoller';
import NotificationController from '../../controllers/notificationController';
import { _ } from '../../controllers/languageController';

export default class ResetPassword extends Component {

    onSubmit(fields) {
        LoadingController.show();
        ApiController.resetPassword(fields.email, callbackWrapper((response) => {
            if (response.data.error) {
                NotificationController.pushError(_(response.data.error.message));
                return;
            }

            NotificationController.pushSuccess(_("success.newPasswordSent"));
            this.props.history.push("/");
        }));
    }

    render() {
        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .email(_("invalid.email"))
                .required(_("no.email"))
        });

        return (
            <CenteredComponent size={12} className="animated fadeIn">
                <h1 className="mb-2">{_("text.resetPassword1.title")}</h1>
                <h4 className="mb-4 offset-lg-1 col-lg-10">{_("text.resetPassword1.subtitle")}</h4>
                <Formik initialValues={{ email: "" }} validationSchema={validationSchema} onSubmit={this.onSubmit.bind(this)}
                    render={(props) => (
                        <Form noValidate className="offset-lg-2 col-lg-8 mb-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>
                            <FormGroup className="mb-2">
                                <Field placeholder={_("placeholder.email")} name="email" type="email" component={InputForm} />
                            </FormGroup>

                            <div className="row">
                                <div className="offset-md-6 col-md-6 text-right">
                                    <Button type="submit" className="col-12" color="success" disabled={props.isSubmitting}>{_("next")} <i className="fas fa-angle-right"></i></Button>
                                </div>
                            </div>
                        </Form>
                    )}>
                </Formik>
            </CenteredComponent>
        );
    }

}