import React, { Component } from 'react';
import { Formik } from 'formik';
import CenteredComponent from '../centeredComponent';
import AccordionItemComponent from '../accordionItemComponent';
import { Form, Button, FormGroup, Input } from 'reactstrap';
import LanguageController, { _, _try } from '../../../controllers/languageController';   // CS 10/9/21
import ConfigController from '../../../controllers/configController';

export default class Step3Component extends Component {

    constructor(props) {
        super(props);


        if (this.props.state[3]) {
            this.state = {
                homeCountryName: _("country."+LanguageController.determineLanguage()),  // CS 10/9/21
                other: {
                    languages: (this.props.state[3].otherText.languages || "").trim().length > 0,
                    cashierSystems: (this.props.state[3].otherText.cashierSystems || "").trim().length > 0,
                    admin: (this.props.state[3].otherText.admin || "").trim().length > 0,
                },
                otherText: this.props.state[3].otherText,
                selected: this.props.state[3].selected
            };
        } else {
            this.state = {
                homeCountryName: _("country."+LanguageController.determineLanguage()),  // CS 10/9/21
                other: {},
                otherText: {},
                selected: {
                    languages: {},
                    cashierSystems: {},
                    marketingSkills: {},  // only required in case we buildMap with marketingSkills (health edition)
                    admin: {}   // for health only to show documentation systems
                }
            };
        }
    }

    // default properties
    static defaultProps = {
        countOfSteps: 5  // default count of steps until completion (only for display)
    };

    componentWillMount() {
        window.document.getElementById('root').scrollIntoView({behavior: 'smooth'})
    }

    onSubmit(fields) {
        this.props.next({ otherText: this.state.otherText, selected: this.state.selected });
    }

    retrieveLanguages() {
        if (this.languages) return this.languages;

        this.languages = ConfigController.retrieveLanguages();
        return this.languages;
    }

    // CS 12/7/22: renamed function to something more sane
    //retrieveHomeCashierSystems() {
    retrieveLocalCashierSystems() {
        if (this.cashierSystems) return this.cashierSystems;

        // CS 6/9/21: old system had the country codes right after the Item text - now we have extra properties (Category and Countries) which separate these informations
        //this.cashierSystems = ConfigController.retrieveCashierSystems().map(x => { return { id: x.id, item: x.item.split("|")[0] } });
        //this.cashierSystems = ConfigController.retrieveCashierSystems().filter(x => x.category == "POS").map(x => { return { id: x.id, item: x.item } });
        this.cashierSystems = ConfigController.retrieveCashierSystems().map(x => { return { id: x.id, item: x.item, category: x.category } });
        return this.cashierSystems;
    }

    // CS 7/4/22: added customer skills list for healthcare edition
    retrieveCustomerSkills() {
        if (this.customerSkills) return this.customerSkills;

        this.customerSkills = ConfigController.retrieveCustomerSkills().map(x => { return { id: x.id, item: x.item, category: "none" } });
        return this.customerSkills;
    }

    retrieveHealthAdminSkills() {
        if (this.healthAdminSkills) return this.healthAdminSkills;

        this.healthAdminSkills = ConfigController.retrieveAdminSkills();
        return this.healthAdminSkills;
    }

    selectItem(name, value, status) {

        let currentSelected = Object.assign({}, this.state.selected);

        if (status)
            currentSelected[name][value.id] = value;
        else
            delete currentSelected[name][value.id];

        this.setState({ selected: currentSelected });
    }

    toggleOther(name, status) {
        let currentOther = Object.assign({}, this.state.other);
        currentOther[name] = status;
        this.setState({ other: currentOther });
    }

    changeOther(name, value) {
        let currentOtherText = Object.assign({}, this.state.otherText);
        currentOtherText[name] = value;
        this.setState({ otherText: currentOtherText });
    }

    renderPharmacyCashierSystems()
    {
        var result = [];
        // regular local country systems
        // {this.buildMap("cashierSystems", this.retrieveHomeCashierSystems(), this.state.homeCountryName)}
        result.push(this.buildMap("cashierSystems", this.retrieveLocalCashierSystems(), this.state.homeCountryName));
        // additional other country systems
        // {this.renderOtherCashierSystems()}
        result.push(this.renderForeignCashierSystems());
        return result;
    }

    buildMap(name, items, countryName) {
        return (
            <div className="two-columns-grid">
                <h4 className="mb-2">
                    {_(`${name}.text`)} {countryName}
                    <small>{_try(`${name}.desc`)}</small>
                </h4>
                
                {items.map(x => (
                    <div key={x.id} className="custom-control custom-checkbox mb-3">
                        <input className="custom-control-input" id={x.id} type="checkbox" 
                            onChange={(e) => this.selectItem(name, x, e.target.checked)} 
                            checked={this.state.selected[name][x.id]} />
                        <label className="custom-control-label noselect side-by-side" htmlFor={x.id}>
                            {_(x.item)}
                            {x.category == "CommissioningSystem" && <span className="apo-badge-system ml-2">{_('cashierSystems.commissioningSystem')}</span>}
                        </label>
                    </div>
                ))}
                <div className={`custom-control custom-checkbox ${this.state.other[name] ? "mb-1" : "mb-3"}`}>
                    <input className="custom-control-input" id={`${name}Other`} type="checkbox" 
                        onChange={(e) => this.toggleOther(name, e.target.checked)} 
                        checked={this.state.other[name]}  />
                    <label className="custom-control-label noselect" htmlFor={`${name}Other`}>{_("other")}</label>
                </div>
                {this.state.other[name] && 
                    <Input className="form-control-alternative mb-2" placeholder={_("other")} type="text" 
                        value={this.state.otherText[name]} 
                        onChange={(e) => this.changeOther(name, e.target.value)} />
                }
            </div>
        );
    }

    buildMap(name, items, countryName) {
        return (
            <div>
                <h4 className="mb-2">
                    {_(`${name}.text`)} {countryName}
                    <small>{_try(`${name}.desc`)}</small>
                </h4>
                
                {items.map(x => (
                    <div key={x.id} className="custom-control custom-checkbox mb-3">
                        <input className="custom-control-input" id={x.id} type="checkbox" 
                            onChange={(e) => this.selectItem(name, x, e.target.checked)} 
                            checked={this.state.selected[name][x.id]} />
                        <label className="custom-control-label noselect side-by-side" htmlFor={x.id}>
                            {_(x.item)}
                            {x.category == "CommissioningSystem" && <span className="apo-badge-system ml-2">{_('cashierSystems.commissioningSystem')}</span>}
                        </label>
                    </div>
                ))}
                <div className={`custom-control custom-checkbox ${this.state.other[name] ? "mb-1" : "mb-3"}`}>
                    <input className="custom-control-input" id={`${name}Other`} type="checkbox" 
                        onChange={(e) => this.toggleOther(name, e.target.checked)} 
                        checked={this.state.other[name]}  />
                    <label className="custom-control-label noselect" htmlFor={`${name}Other`}>{_("other")}</label>
                </div>
                {this.state.other[name] && 
                    <Input className="form-control-alternative mb-2" placeholder={_("other")} type="text" 
                        value={this.state.otherText[name]} 
                        onChange={(e) => this.changeOther(name, e.target.value)} />
                }
            </div>
        );
    }

    // CS 12/7/22: renamed function to something more sane and more descriptive
    //buildMapOtherCashierSystems(title, items) {
    buildMapForeignCashierSystems(title, items) {
        let name = "cashierSystems";
        return (
            <AccordionItemComponent title={_("cashierSystems.text") + " " + title} show={false}>
                {items.map(x => (
                    <div key={x.id} className="custom-control custom-checkbox mb-3">
                        <input className="custom-control-input" id={x.id} type="checkbox" onChange={(e) => this.selectItem(name, x, e.target.checked)} checked={this.state.selected[name][x.id]} />
                        <label className="custom-control-label noselect side-by-side" htmlFor={x.id}>
                            {_(x.item)}
                            {x.category == "CommissioningSystem" && <span className="apo-badge-system ml-2">{_('cashierSystems.commissioningSystem')}</span>}
                        </label>
                    </div>
                ))}
            </AccordionItemComponent>
        );
    }


    // CS 12/7/22: renamed function to something more sane and more descriptive
    // renderOtherCashierSystems() {
    renderForeignCashierSystems() {
        let systems = ConfigController.retrieveCashierSystemLanguages();
        // CS 12/7/22: remap with code, translate countryName and order by it
        //return systems.map(x => this.buildMapForeignCashierSystems(_("nationalities." + x), ConfigController.retrieveCashierSystemsOther(x).map(x => { return { id: x.id, item: x.item, category: x.category } })));
        let list = systems.map( x => {return { code: x, countryName: _("nationalities." + x)}}).sort((a, b) => a.countryName.localeCompare(b.countryName));
        return list.map(x => this.buildMapForeignCashierSystems(x.countryName, ConfigController.retrieveCashierSystemsOther(x.code).map(y => { return { id: y.id, item: y.item, category: y.category } })));
    }

    render() {

        var titleCode = "text.register.step3";
        if (ConfigController.isHealthcareEdition()) titleCode = "text.register.health.step3";

        return (
            <CenteredComponent size={12} className="animated fadeIn">
                <h1 className="mb-2">{_("step")} 3 {_("of")} {this.props.countOfSteps}</h1>
                <h4 className="mb-4 offset-lg-12">
                    {_(`${titleCode}.${this.props.type}`)}
                    {_try(`${titleCode}.note`) != '' &&
                        <small className='d-block'>{_(`${titleCode}.note`)}</small>
                    }
                </h4>
                <Formik onSubmit={this.onSubmit.bind(this)}
                    render={(props) => (
                        <Form noValidate className="col-lg-12 mb-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>

                            {/* 
                                // CS 12/4/22: PHARMACY edition rendering 
                                //   __             __              __      
                                //  |__) |__|  /\  |__)  |\/|  /\  /  ` \ / 
                                //  |    |  | /~~\ |  \  |  | /~~\ \__,  |  
                                //                                          
                            */}
                            {ConfigController.isPharmacyEdition() && this.renderPharmacyEditionContent()}

                            {/* 
                                // CS 12/4/22: HEALTHCARE edition rendering
                                //        ___           ___       __        __   ___ 
                                //  |__| |__   /\  |     |  |__| /  `  /\  |__) |__  
                                //  |  | |___ /~~\ |___  |  |  | \__, /~~\ |  \ |___ 
                                //                                                    
                            */}
                            {ConfigController.isHealthcareEdition() && this.renderHealthCareEditionContent()}


                            <div className="row mt-2">
                                <div className="col-md-6 m-0 py-2">
                                    <Button type="button" className="col-12" color="danger" onClick={(x) => this.props.back()}><i className="fas fa-angle-left"></i> {_("back")}</Button>
                                </div>
                                <div className="offset-md-6 col-md-6 text-right m-0 py-2">
                                    <Button type="submit" className="col-12" color="success">{_("next")} <i className="fas fa-angle-right"></i></Button>
                                </div>
                            </div>
                        </Form>
                    )}>
                </Formik>
            </CenteredComponent>
        );
    }

    //   __             __              __      
    //  |__) |__|  /\  |__)  |\/|  /\  /  ` \ / 
    //  |    |  | /~~\ |  \  |  | /~~\ \__,  |  
    //                                          
    //  CONTENT
    //                                          
    renderPharmacyEditionContent(){

        var result = [];

        // 2 lists
        // left: languages 
        // right: cashierSystems plus otherCashierSystems (other countries than current) 
        result.push(
            <div className="row m-0 text-left">
                <div className="col-lg-6">
                    {this.buildMap("languages", this.retrieveLanguages())}
                </div>
                <div className="col-lg-6">
                    {this.renderPharmacyCashierSystems()}
                </div>
            </div>
        )

        return result;
    }

    //        ___           ___       __        __   ___ 
    //  |__| |__   /\  |     |  |__| /  `  /\  |__) |__  
    //  |  | |___ /~~\ |___  |  |  | \__, /~~\ |  \ |___ 
    // 
    //  CONTENT
    //                                                    
    renderHealthCareEditionContent(){

        var result = []

        // // free text skill description being stored in otherMarketingSkills
        // result.push(
        //     <div className="form-group">
        //         <h4>{_("marketingSkills.health.text")}</h4>
        //         <textarea className="form-control my-2" 
        //             placeholder={_("marketingSkills.health.text")} rows="6" 
        //             onChange={(e) => this.changeOther('marketingSkills', e.target.value)}>
        //             {this.state.otherText['marketingSkills']}
        //         </textarea>
        //     </div>
        // )

        // language list (2 columns)
        {
            var languages = this.retrieveLanguages();
            var languagesName = "languages";

            var title = _(`${languagesName}.text`);
            var note = _try(`${languagesName}.desc`);

            result.push(
                // <div className="row m-0 text-left">
                <div className="m-0 text-left">
                    <h4 className="mb-2">
                        {title}
                        {note != '' && <small className='ml-1'>{note}</small>}
                    </h4>
                    <div className="two-columns-grid">
                        {languages.map(x => (
                            <div key={x.id} className="custom-control custom-checkbox mb-3">
                                <input className="custom-control-input" id={x.id} type="checkbox" 
                                    onChange={(e) => this.selectItem(languagesName, x, e.target.checked)} 
                                    checked={this.state.selected[languagesName][x.id]} />
                                <label className="custom-control-label noselect side-by-side" htmlFor={x.id}>{_(x.item)}</label>
                            </div>
                        ))}
                        {/* OTHER */}
                        <div>
                            <div className={`custom-control custom-checkbox ${this.state.other[languagesName] ? "mb-1" : "mb-3"}`}>
                                <input className="custom-control-input" id={`${languagesName}Other`} type="checkbox" 
                                    onChange={(e) => this.toggleOther(languagesName, e.target.checked)} 
                                    checked={this.state.other[languagesName]}  />
                                <label className="custom-control-label noselect" htmlFor={`${languagesName}Other`}>{_("other")}</label>
                            </div>
                            {this.state.other[languagesName] && 
                                <Input className="form-control-alternative mb-2" placeholder={_("other")} type="text" 
                                    value={this.state.otherText[languagesName]} 
                                    onChange={(e) => this.changeOther(languagesName, e.target.value)} />
                            }
                        </div>
                    </div>
                </div>
            )
        }

        // health admin skills (2 columns)
        {
            var skills = this.retrieveHealthAdminSkills()
            var skillName = "admin";

            result.push(
                // <div className="row m-0 text-left">
                <div className="m-0 text-left">
                    <h4 className="mb-2">
                        {_(`skills.${skillName}.health.text`)}
                        <small className='ml-1'>{_try(`skills.${skillName}.health.desc`)}</small>
                    </h4>
                    <div className="two-columns-grid">
                        {skills.map(x => (
                            <div key={x.id} className="custom-control custom-checkbox mb-3">
                                <input className="custom-control-input" id={x.id} type="checkbox" 
                                    onChange={(e) => this.selectItem(skillName, x, e.target.checked)} 
                                    checked={this.state.selected[skillName][x.id]} />
                                <label className="custom-control-label noselect side-by-side" htmlFor={x.id}>{_(x.item)}</label>
                            </div>
                        ))}
                        {/* OTHER */}
                        <div>
                            <Input className="form-control-alternative mb-2" placeholder={_("other")} type="text" 
                                value={this.state.otherText[skillName]} 
                                onChange={(e) => this.changeOther(skillName, e.target.value)} />
                        </div>
                    </div>
                </div>
            )
        }        

        return result;
    }

}