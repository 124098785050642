import React, { Component } from 'react';
import { Input } from 'reactstrap';

export default class InputComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { id: props.id || Math.random() };
    }

    render() {
        return (
            <div className="form-label-group">
                <Input id={this.state.id} {...this.props} className={`form-control-alternative ${this.props.className}`}  />
                {this.props.placeholder && <label className="noselect" htmlFor={this.state.id}>{this.props.placeholder}</label>}
            </div>
        );
    }

}