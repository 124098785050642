import React, { Component } from 'react';
import { Collapse } from 'reactstrap';


export default class AccordionItemComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapse: this.props.show || false
        };
    }

    // CS 6/7/22: added new caller prop-callback routines for the various close and open states
    toggle() {

        var newOpen = !this.state.collapse;

        this.setState({ collapse: newOpen });

        if ( !newOpen && this.props.onClose ) this.props.onClose();
        if ( newOpen && this.props.onOpen ) this.props.onOpen();
    }

    render() {
        return (
            <div>
                <h5 className={ "mb-0 py-2 " + ( this.props.className || "") }>
                    <button className="btn btn-link w-100 text-default text-left collapsed p-0" type="button" onClick={this.toggle.bind(this)}>
                        <h3 style={{marginBottom: 0, paddingBottom:0, lineHeight:'100%'}}>
                            {this.props.icon ? <span className={'mr-1 fa ' + this.props.icon}></span> : ""}
                            {this.props.title}
                            <i className="fas fa-angle-down float-right"></i>
                        </h3>
                        <small style={{margin: 0, padding:0}}>{this.props.subtitle}</small>
                    </button>
                </h5>
                <div className="accordion-content">
                    <Collapse isOpen={this.state.collapse}>
                        {this.props.children}
                    </Collapse>
                </div>
            </div>
        );
    }

}