import React, { Component } from 'react';
import { _ } from '../../controllers/languageController';
import ConfigController from '../../controllers/configController';

export default class FooterComponent extends Component {

    render() {

        if ( ConfigController.isHealthcareEdition()) return this.renderHealthcareEdition();

        return this.renderPharmacyEdition();
    }


    //        __   __   __   ___  __   __   __                 
    //   /\  |__) /  \ |__) |__  |__) /__` /  \ |\ |  /\  |    
    //  /~~\ |    \__/ |    |___ |  \ .__/ \__/ | \| /~~\ |___ 
    //                                                         
    renderPharmacyEdition() {
        return (
            <footer className="pt-3" id="footer-main">
                <div className="container">
                    <div className="row align-items-center justify-content-xl-between">
                        <div className="col-xl-6">
                            <div className="text-center text-xl-left text-muted">
                                <div className="nav-item">
                                    © {new Date().getFullYear()} <a className="apo-link-secondary" href="https://apopersonal.com" target="_blank"><b>Apopersonal</b></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <ul className="nav nav-footer justify-content-center justify-content-xl-end">
                                <li className="nav-item">
                                    {/* <a href="https://www.apopersonal.com/kontakt" className="nav-link apo-link-secondary" target="_blank"><b>{_("nav.contact")}</b></a> */}
                                    {/* <a href={window.location.origin.toString() + "/kontakt"} className="nav-link apo-link-secondary" target="_blank" rel="noopener noreferrer"><b>{_("nav.contact")}</b></a> */}
                                    <a href="https://www.apopersonal.com/kontakt" className="nav-link apo-link-secondary" target="_blank" rel="noopener noreferrer"><b>{_("nav.contact")}</b></a>
                                </li>
                                <li className="nav-item">
                                    {/* <a href={window.location.origin.toString() + "/impressum"} className="nav-link apo-link-secondary" target="_blank" rel="noopener noreferrer"><b>{_("nav.impressum")}</b></a> */}
                                    <a href="https://www.apopersonal.com/impressum" className="nav-link apo-link-secondary" target="_blank" rel="noopener noreferrer"><b>{_("nav.impressum")}</b></a>
                                </li>
                                <li className="nav-item">
                                    {/* <a href={window.location.origin.toString() + "/impreagbssum"} className="nav-link apo-link-secondary" target="_blank" rel="noopener noreferrer"><b>{_("nav.terms")}</b></a> */}
                                    <a href="https://www.apopersonal.com/agb" className="nav-link apo-link-secondary" target="_blank" rel="noopener noreferrer"><b>{_("nav.terms")}</b></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

    //   __   ___       ___  __   ___     ___         __   ___      
    //  |__) |__  |    |__  / _` |__  __ |__  | |\ | |  \ |__  |\ | 
    //  |    |    |___ |___ \__> |___    |    | | \| |__/ |___ | \| 
    //                                                              
    renderHealthcareEdition() {
        return (
            <footer className="pt-3" id="footer-main">
                <div className="container">
                    <div className="row align-items-center justify-content-xl-between">
                        <div className="col-xl-6">
                            <div className="text-center text-xl-left text-muted">
                                <div className="nav-item">
                                    © {new Date().getFullYear()} <a className="apo-link-secondary" href="https://pflege-finden.com" target="_blank"><b>pflege-finden</b></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <ul className="nav nav-footer justify-content-center justify-content-xl-end">
                                <li className="nav-item">
                                    <a href="https://www.pflege-finden.com/kontakt" className="nav-link apo-link-secondary" target="_blank" rel="noopener noreferrer"><b>{_("nav.contact")}</b></a>
                                </li>
                                <li className="nav-item">
                                    <a href="https://www.pflege-finden.com/impressum" className="nav-link apo-link-secondary" target="_blank" rel="noopener noreferrer"><b>{_("nav.impressum")}</b></a>
                                </li>
                                <li className="nav-item">
                                    <a href="https://www.pflege-finden.com/agb" className="nav-link apo-link-secondary" target="_blank" rel="noopener noreferrer"><b>{_("nav.terms")}</b></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

}