import React from 'react';
import ReactDOM from 'react-dom';

import './styles/global.scss';
import 'react-notifications-component/dist/theme.css'

import App from "./pages/app";
import { Route } from "react-router"
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <Route component={App} />
    </BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
