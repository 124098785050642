import React, { Component } from 'react';
import { Formik } from 'formik';
import CenteredComponent from '../centeredComponent';
import { Form, Button, FormGroup, Input } from 'reactstrap';
import LanguageController, { _ } from '../../../controllers/languageController';
import ConfigController from '../../../controllers/configController';
import SelectInputComponent, { optionFilter } from '../selectInputComponent';
import AuthenticationController from '../../../controllers/authenticationController';

export default class Step4Component extends Component {

    constructor(props) {
        super(props);

        if (this.props.state[4]) {
            this.state = {
                other: {
                    customer: (this.props.state[4].otherText.customer || "").trim().length > 0,
                    manufactur: (this.props.state[4].otherText.manufactur || "").trim().length > 0,
                    admin: (this.props.state[4].otherText.admin || "").trim().length > 0,
                    logistic: (this.props.state[4].otherText.logistic || "").trim().length > 0,
                    marketing: (this.props.state[4].otherText.marketing || "").trim().length > 0,
                },
                otherText: this.props.state[4].otherText,
                selected: this.props.state[4].selected,
                profileRemarks: this.props.state[4].profileRemarks,
                // CS: 29/6/22 added new linda flag support
                lindaFlag: this.props.state[4].lindaFlag
            };
        } else {
            this.state = {
                other: {},
                otherText: {},
                selected: {
                    customer: {},
                    manufactur: {},
                    admin: {}, 
                    logistic: {}, 
                    marketing: {}
                },
                profileRemarks: null,
                // CS: 29/6/22 added new linda flag support
                lindaFlag: /*false*/this.retrieveBoolDecisions()[0],
            };
        }
    }

    // defaukt properties
    static defaultProps = {
        countOfSteps: 5  // default count of steps until completion (only for display)
    };

    componentWillMount() {
        window.document.getElementById('root').scrollIntoView({
            behavior: 'smooth'
        })
    }

    onSubmit(fields) {
        this.props.next({ 
            otherText: this.state.otherText, 
            selected: this.state.selected, 
            profileRemarks: this.state.profileRemarks,
            lindaFlag: this.state.lindaFlag
         });
    }

    retrieveBoolDecisions() { 

        if (this.decision) return this.decision;

        this.decision = [{ id: false, item: _("false") }, { id: true, item: _("true") }]
        return this.decision;
    }

    retrieveCustomerSkills() {
        if (this.customerSkills) {
            return this.customerSkills;
        }

        this.customerSkills = ConfigController.retrieveCustomerSkills();
        return this.customerSkills;
    }

    retrieveManufacturSkills() {
        if (this.manufacturSkills) {
            return this.manufacturSkills;
        }

        this.manufacturSkills = ConfigController.retrieveManufacturSkills();
        return this.manufacturSkills;
    }

    retrieveLogisticSkills() {
        if (this.logisticSkills) {
            return this.logisticSkills;
        }

        this.logisticSkills = ConfigController.retrieveLogisticSkills();
        return this.logisticSkills;
    }

    retrieveAdminSkills() {
        if (this.adminSkills) {
            return this.adminSkills;
        }

        this.adminSkills = ConfigController.retrieveAdminSkills();
        return this.adminSkills;
    }

    retrieveMarketingSkills() {
        if (this.marketingSkills) {
            return this.marketingSkills;
        }

        this.marketingSkills = ConfigController.retrieveMarketingSkills();
        return this.marketingSkills;
    }

    selectItem(name, value, status) {
        let currentSelected = Object.assign({}, this.state.selected);

        if (status) {
            currentSelected[name][value.id] = value;
        } else {
            delete currentSelected[name][value.id];
        }

        this.setState({ selected: currentSelected });
    }

    selectLindaFlag(value) { this.setState({ lindaFlag: value }); }

    toggleOther(name, status) {
        let currentOther = Object.assign({}, this.state.other);
        currentOther[name] = status;
        this.setState({ other: currentOther });
    }

    changeOther(name, value) {
        let currentOtherText = Object.assign({}, this.state.otherText);
        currentOtherText[name] = value;
        this.setState({ otherText: currentOtherText });
    }

    // CS 28/9/21: added Remarks to profile model
    changeRemarks(value) {
        this.state.profileRemarks = value;
        this.setState({ profileRemarks: this.state.profileRemarks });
    }


    buildMap(name, items) {
        return (
            <div>
                <h4 className="mb-2">{_(`skills.${name}.text`)}</h4>
                {items.map(x => (
                    <div key={x.id} className="custom-control custom-checkbox mb-3">
                        <input className="custom-control-input" id={x.id} type="checkbox" onChange={(e) => this.selectItem(name, x, e.target.checked)} checked={this.state.selected[name][x.id]} />
                        <label className="custom-control-label noselect" htmlFor={x.id}>{_(x.item)}</label>
                    </div>
                ))}
                <div className={`custom-control custom-checkbox ${this.state.other[name] ? "mb-1" : "mb-3"}`}>
                    <input className="custom-control-input" id={`${name}SkillsOther`} type="checkbox" onChange={(e) => this.toggleOther(name, e.target.checked)} checked={this.state.other[name]} />
                    <label className="custom-control-label noselect" htmlFor={`${name}SkillsOther`}>{_("other")}</label>
                </div>
                {this.state.other[name] && <Input className="form-control-alternative mb-2" placeholder={_("other")} type="text" value={this.state.otherText[name]} onChange={(e) => this.changeOther(name, e.target.value)} />}
            </div>
        );
    }

    render() {

        var titleCode = "text.register.step4";
        if (ConfigController.isHealthcareEdition()) titleCode = "text.register.health.step4";

        return (
            <CenteredComponent size={12} className="animated fadeIn">
                <h1 className="mb-2">{_("step")} 4 {_("of")} {this.props.countOfSteps}</h1>
                <h4 className="mb-4 col-lg-12">{_(`${titleCode}.${this.props.type}`)}</h4>
                <Formik onSubmit={this.onSubmit.bind(this)}
                    render={(props) => (
                        <Form noValidate className="col-lg-12 mb-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>

                            {/* 
                                // CS 12/4/22: PHARMACY edition rendering 
                                //   __             __              __      
                                //  |__) |__|  /\  |__)  |\/|  /\  /  ` \ / 
                                //  |    |  | /~~\ |  \  |  | /~~\ \__,  |  
                                //                                          
                            */}
                            {ConfigController.isPharmacyEdition() && this.renderPharmacyEditionContent()}

                            {/* 
                                // CS 12/4/22: HEALTHCARE edition rendering
                                //        ___           ___       __        __   ___ 
                                //  |__| |__   /\  |     |  |__| /  `  /\  |__) |__  
                                //  |  | |___ /~~\ |___  |  |  | \__, /~~\ |  \ |___ 
                                //                                                    
                            */}
                            {ConfigController.isHealthcareEdition() && this.renderHealthCareEditionContent()}

                            <div className="row">
                                <div className="col-md-6 m-0 py-2">
                                    <Button type="button" className="col-12" color="danger" onClick={(x) => this.props.back()}><i className="fas fa-angle-left"></i> {_("back")}</Button>
                                </div>
                                <div className="offset-md-6 col-md-6 text-right m-0 py-2">
                                    <Button type="submit" className="col-12" color="success">{_("next")} <i className="fas fa-angle-right"></i></Button>
                                </div>
                            </div>
                        </Form>
                    )}>
                </Formik>
            </CenteredComponent>
        );
    }

    //   __             __              __      
    //  |__) |__|  /\  |__)  |\/|  /\  /  ` \ / 
    //  |    |  | /~~\ |  \  |  | /~~\ \__,  |  
    //                                          
    //  CONTENT
    //                                          
    renderPharmacyEditionContent(){

        var result = [];

        var isPerson = this.props.type !== 'pharmacy';
        var lindaFlagText = isPerson ? _("placeholder.lindaExperience") : _("placeholder.lindaMember");

        result.push(
            [
                <div className="row m-0 text-left">
                    <div className="col-lg-6">
                        {this.buildMap("customer", this.retrieveCustomerSkills())}
                        {this.buildMap("admin", this.retrieveAdminSkills())}
                    </div>
                    <div className="col-lg-6">
                        {this.buildMap("manufactur", this.retrieveManufacturSkills())}
                        {this.buildMap("logistic", this.retrieveLogisticSkills())}
                        {this.buildMap("marketing", this.retrieveMarketingSkills())}
                    </div>
                </div>
            ]
        )

        // CS 11/7/2022: added new linda flag (only for germany)
        if ( LanguageController.currentCountryIs('de'))
        {
            result.push(
                <FormGroup>
                    <div className="linda-selector">
    
                        <img src="/assets/images/linda-logo.svg" alt="" />
    
                        <SelectInputComponent
                        onSelect={this.selectLindaFlag.bind(this)}
                        onFilter={optionFilter(this.retrieveBoolDecisions())}
                        submitted={this.state.submitted}
                        disabled={false}
                        placeholder={lindaFlagText}
                        default={this.state.lindaFlag || this.retrieveBoolDecisions()[0]} />
    
                    </div>
                </FormGroup>
            )
        }

        // Remarks
        result.push(
            <div className="form-group">
                <label for="txtRemarks">{_("remarks")}</label>
                <textarea id="txtRemarks" className="form-control my-2" placeholder={_("remarks")} rows="6" onChange={(e) => this.changeRemarks(e.target.value)}>
                    {this.state.profileRemarks}
                </textarea>
            </div>
        )

        return result;
    }

    //        ___           ___       __        __   ___ 
    //  |__| |__   /\  |     |  |__| /  `  /\  |__) |__  
    //  |  | |___ /~~\ |___  |  |  | \__, /~~\ |  \ |___ 
    // 
    //  CONTENT
    //                                                    
    renderHealthCareEditionContent(){

        var result = []

        {
            var items = this.retrieveCustomerSkills();
            var name = "customer";
    
            result.push(
                <div className="m-0 text-left">
                    {/* <h4 className="mb-2">{_(`skills.${name}.text`)}</h4> */}
                    <div className="two-columns-grid">
                        {items.map(x => (
                            <div key={x.id} className="custom-control custom-checkbox mb-3">
                                <input className="custom-control-input" id={x.id} type="checkbox" onChange={(e) => this.selectItem(name, x, e.target.checked)} checked={this.state.selected[name][x.id]} />
                                <label className="custom-control-label noselect" htmlFor={x.id}>{_(x.item)}</label>
                            </div>
                        ))}
                        <div>
                            <div className={`custom-control custom-checkbox ${this.state.other[name] ? "mb-1" : "mb-3"}`}>
                                <input className="custom-control-input" id={`${name}SkillsOther`} type="checkbox" onChange={(e) => this.toggleOther(name, e.target.checked)} checked={this.state.other[name]} />
                                <label className="custom-control-label noselect" htmlFor={`${name}SkillsOther`}>{_("other")}</label>
                            </div>
                            {this.state.other[name] && <Input className="form-control-alternative mb-2" placeholder={_("other")} type="text" value={this.state.otherText[name]} onChange={(e) => this.changeOther(name, e.target.value)} />}
                        </div>
                    </div>
                </div>
            )
        }

        // Remarks
        result.push(
            <div className="form-group">
                <label for="txtRemarks">{_("health.remarks")}</label>
                <textarea id="txtRemarks" className="form-control my-2" placeholder={_("health.remarks")} rows="6" onChange={(e) => this.changeRemarks(e.target.value)}>
                    {this.state.profileRemarks}
                </textarea>
            </div>
        )

        return result;
    }    

}