import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Button, FormGroup, Input } from 'reactstrap';
import CenteredComponent from '../centeredComponent';
import { _ } from '../../../controllers/languageController';
import ConfigController from '../../../controllers/configController';

class SelectTypeComponent extends Component {

    submit(type) {
        this.props.history.push(`/register/${type}`);
        if (this.props.update) {
            this.props.update({ type: type });
        }
    }

    render() {

        var langSignupAsUser = "text.signupAsPerson";
        var langSignupAsCompany = "text.signupAsPharmacy";

        if ( ConfigController.isHealthcareEdition()){
            var langSignupAsUser = "text.health.signupAsPerson";
            var langSignupAsCompany = "text.health.signupAsCompany";
        }

        return (
            <CenteredComponent size={12} className="animated fadeIn">
                <h1 className="mb-4">{_("")}</h1>
                <div className="offset-lg-3 col-lg-6 mb-0">
                    <div className="row">
                        <div className="col-12 m-0 p-1">
                            <Button className="col-12" color="success" onClick={() => { this.submit("person") }}><i className="fas fa-clinic-medical"></i> {_(langSignupAsUser)}</Button>
                        </div>
                        <div className="col-12 m-0 p-1">
                            <Button className="col-12" color="success" onClick={() => { this.submit("pharmacy") }}><i className="fas fa-user"></i> {_(langSignupAsCompany)}</Button>
                        </div>
                    </div>
                </div>
            </CenteredComponent>
        );
    }

}
export default withRouter(SelectTypeComponent);