import React, { Component } from 'react';
import AuthenticationController from '../../../controllers/authenticationController';
import ApiController, { callbackWrapper } from '../../../controllers/apiController';
import ConfigController from '../../../controllers/configController';
import { withRouter } from 'react-router-dom';
import { _ } from '../../../controllers/languageController';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SecurityController from '../../../controllers/securityController';
import LoadingItemComponent from '../loadingItemComponent';

class ProfilesComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            type: AuthenticationController.isPerson() ? "person" : "pharmacy",
            isPerson: AuthenticationController.isPerson()
        };
    }

    componentWillMount() {
        this.load();
    }

    load() {
        this.setState({ loading: true });
        ApiController.retrieveProfiles(callbackWrapper(this.profileCallback.bind(this)));
    }

    profileCallback(response) {
        this.setState({ content: response.data.result, loading: false });
    }

    profileCommandCallback(response) {
        this.load();
    }

    openProfile(e, profile) {
        if (e) e.stopPropagation();

        if (!profile.active) return;
        //console.log(`start match for profileID ${profile.id}`)
        this.props.history.push(`match/${profile.id}`);
    }

    deleteProfile(e, profile) {
        e.stopPropagation();

        this.setState({ deleteProfile: profile });
    }

    confirmDeleteProfile() {
        let profile = this.state.deleteProfile;

        this.setState({ loading: true, deleteProfile: undefined });
        ApiController.deleteProfile(profile.id, callbackWrapper(this.profileCommandCallback.bind(this)));
    }

    cancelDeleteProfile() {
        this.setState({ deleteProfile: undefined });
    }

    editProfile(e, profile) {
        if (e) {
            e.stopPropagation();
        }

        this.props.history.push(`edit/${profile.id}`);
    }

    activateProfile(e, profile) {
        e.stopPropagation();

        this.setState({ loading: true });
        ApiController.activateProfile(profile.id, callbackWrapper(this.profileCommandCallback.bind(this)));
    }

    deactivateProfile(e, profile) {
        e.stopPropagation();

        this.setState({ loading: true });
        ApiController.deactivateProfile(profile.id, callbackWrapper(this.profileCommandCallback.bind(this)));
    }

    // CS 17/3/22: applied naming standard
    renderProfiles() {

        var isPharmacyEdition = ConfigController.isPharmacyEdition();
        var isHealthcareEdition = ConfigController.isHealthcareEdition();


        return this.state.content.map(x => {

            return (
                <div key={x.id} className="card text-left">
                    <div className="card-body" onClick={(() => this.openProfile(undefined, x)).bind(this)}>
                        <h5 className="card-title mb-0">
                            <span className="badge badge-dot">
                                <i className={x.active ? "bg-success" : "bg-danger"}></i>
                            </span>
                            {/* CS: 30/3/22 fixed problem with mixed countries preventing job type to be found - call new func retrieveNativeJobType */}
                            {_(ConfigController.retrieveNativeJobType(x.jobID,x.postalCountryCode).item)}
                            &nbsp;({x.hoursPerWeek} {_("text.hoursPerWeek")})
                            {x.active && <i className="fas fa-angle-right float-right"></i>}

                            <span className="badge badge-sm badge-secondary ml-2">{x.postalCode} {x.postalName} ({x.postalCountryCode})</span>
                        </h5>
                        <small className="h6 text-muted">
                            &nbsp;&nbsp;{_("text.creationDate")}
                            :&nbsp;{new Date(x.creationDate).toLocaleDateString()}
                        </small>
                        <div className="text-right mt-lg-1 mt-4">
                            {x.active && <button className="btn btn-secondary btn-sm col-lg-auto mb-3 mb-lg-0" onClick={(e) => this.editProfile(e, x)}><i className="fas fa-pen"></i> {_("text.edit")}</button>}
                            {x.active && <button className="btn btn-secondary btn-sm col-lg-auto mb-4 mb-lg-0" data-toggle="tooltip-disabled" title="Vorübergehend deaktivieren" onClick={(e) => this.deactivateProfile(e, x)}>{_("text.deactivate")}</button>}
                            {!x.active && <button className="btn btn-success btn-sm col-lg-auto mb-3 mb-lg-0" onClick={(e) => this.activateProfile(e, x)}>{_("text.activate")}</button>}
                            {/* <button className="btn btn-danger btn-sm col-lg-auto mb-3 mb-lg-0" style={{fontSize: ".6em"}} onClick={(e) => this.deleteProfile(e, x)}>{_("text.delete")}</button> */}
                            <button className="btn btn-danger btn-sm col-lg-auto mb-3 mb-lg-0" onClick={(e) => this.deleteProfile(e, x)}>{_("text.delete")}</button>
                            { x.active && isPharmacyEdition && 
                                <button className="btn btn-success btn-sm col-lg-auto mb-lg-0" onClick={(e) => this.openProfile(e, x)}>{_(`text.open.${this.state.type}`)} <i className="fas fa-angle-right" /></button>
                            }
                            { x.active && isHealthcareEdition && 
                                <button className="btn btn-success btn-sm col-lg-auto mb-lg-0" onClick={(e) => this.openProfile(e, x)}>{_(`text.open.health.${this.state.type}`)} <i className="fas fa-angle-right" /></button>
                            }
                        </div>
                    </div>
                </div>
            )
        });
    }

    createProfile() {
        this.props.history.push("/create");
    }

    render() {

        if (this.state.loading) return (<LoadingItemComponent />);

        // CS 17/3/22: determine if user has reach the profile count limit and disable the ADD PROFILE button if necessary
        var config = ConfigController.retrieveConfiguration().configuration;
        var profileLimit = this.state.isPerson ? config.personProfileLimit : config.pharmacyProfileLimit;
        var limitReached = (this.state.isPerson && this.state.content.length >= profileLimit);

        return (
            <div>
                <Modal className="modal-dialog-centered modal-lg" isOpen={this.state.deleteProfile} toggle={this.cancelDeleteProfile.bind(this)}>
                    <ModalHeader toggle={this.cancelDeleteProfile.bind(this)}>{_("text.confirm.text")}</ModalHeader>
                    <ModalBody className="py-0">
                        {_("text.confirm.deleteProfile")}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.cancelDeleteProfile.bind(this)}>{_("cancel")}</Button>
                        <Button color="danger" onClick={this.confirmDeleteProfile.bind(this)}>{_("confirm")}</Button>
                    </ModalFooter>
                </Modal>

                {/* CS 17/3/22: render all profiles */}
                {this.renderProfiles()}

                {limitReached == false && 
                    <div className="row">
                        <div className="offset-md-5 col-md-7 text-right">
                            <Button type="submit" className="col-12" color="success" onClick={this.createProfile.bind(this)}>{_("create")} <i className="fas fa-plus"></i></Button>
                        </div>
                    </div>
                }
                {limitReached &&
                    <div className="row">
                        <div className="offset-md-5 col-md-7 text-right">
                            <Button type="submit" className="col-12" color="light" disabled>
                                {_("profileLimitReached")} &nbsp;
                                <i class="fa-solid fa-triangle-exclamation"></i>
                            </Button>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

export default withRouter(ProfilesComponent);