import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ReactNotification from 'react-notifications-component';
import FooterComponent from '../_components/footerComponent';
import LoadingComponent from '../_components/loadingComponent';

import LanguageController from '../../controllers/languageController';
import LoadingController from '../../controllers/loadingContoller';
import NavigationComponent from '../_components/navigationComponent';
//import BannerComponent from '../_components/bannerComponent';
import AdvertisementComponent from '../_components/advertisementComponent';
import ConfigController from '../../controllers/configController';

class PublicLayout extends Component {

    constructor(props) {
        super(props);
        this.state = { loading: true, key: Math.random() };
    }

    componentWillMount() {
        LanguageController.register(this.forceRerender.bind(this));
        LoadingController.register(this.showLoading.bind(this), this.hideLoading.bind(this));
    }

    forceRerender() {
        this.setState({ key: Math.random() });
    }

    componentDidMount() {
        ConfigController.loadConfiguration((result) => {
            if (!result) {
                this.props.history.push("/login");  /* /reconnect */
            }

            LoadingController.hide();
        });
    }

    showLoading() {
        this.setState({ loading: true });
    }

    hideLoading() {
        this.setState({ loading: false });
    }

    render() {

        var result = [];

        result.push(

            <div className="app-content pb-2 pb-xl-4" key={this.state.key}>
                <ReactNotification />
                <LoadingComponent loading={this.state.loading}>
                    <NavigationComponent />
                    <div className="apo-content">
                        <div className="m-0 p-0">
                            {/* <BannerComponent /> */}
                            <AdvertisementComponent />
                            {this.props.children}
                        </div>
                    </div>
                    <FooterComponent></FooterComponent>
                </LoadingComponent>
            </div>

        )

        if ( ConfigController.isPharmacyEdition())
        {
            result.push(
                <div class="apo-fixed-container">
                    <div class="apo-logo-icon-fixed"></div>
                    <div class="apo-background-logo-1"></div>
                    <div class="apo-background-logo-2"></div>
                    <div class="apo-background-logo-3"></div>
                </div>
            )
        }

        if ( ConfigController.isHealthcareEdition())
        {
            result.push(
                <div class="pflege-fixed-container">
                    <div class="pflege-logo-icon-fixed"></div>
                    <div class="pflege-background-logo"></div>
                </div>
            )
        }

        return result;
    }

}
export default withRouter(PublicLayout);