// a88888b.    .d88888b         a88P d8888b.  a8888a  d8888b. d8888b. Y88o  
// d8'   `88    88.    "'       d8'       `88 d8' ..8b     `88     `88   `8b 
// 88           `Y88888b.       88    .aaadP' 88 .P 88 .aaadP' .aaadP'    88 
// 88                 `8b       88    88'     88 d' 88 88'     88'        88 
// Y8.   .88 dP d8'   .8P dP    Y8.   88.     Y8'' .8P 88.     88.       .8P 
//  Y88888P' 88  Y88888P  88     Y88b Y88888P  Y8888P  Y88888P Y88888P d88Y  
//
// C.S. (2022)
//
import ApiController, { callbackWrapper } from "./apiController";
import LoadingController from "./loadingContoller";
import LanguageController, { _ } from "./languageController";
import NodeRSA from 'node-rsa';

const ConfigController = {

    loadConfiguration(callback) {
        ApiController.retrieveItems((response) => {

            if (response.status === 200 && response.data.result) {
                this.configuration = response.data.result;

                // debug
                //console.log("DEBUG - this.configuration.cashierSystem")
                //console.table(this.configuration.cashierSystems);

                // CS 2/9/21: prepare and order all jobTypes right after loading them so we only have to do it once
                this.orderJobTypes();
        
                callback(true);
                return;
            }

            callback(false);
        });
    },


    //   ___  __    ___    __       
    //  |__  |  \ |  |  | /  \ |\ | 
    //  |___ |__/ |  |  | \__/ | \| 
    //                              

    // returns true if the current solution has been configured for the HEALT CARE PROFESSIONALS
    isHealthcareEdition()
    {
        return window['runtimeConfig'].edition === 2;
    },

    // returns true if the current solution has been configured for the HEALT CARE PROFESSIONALS
    isPharmacyEdition()
    {
        return window['runtimeConfig'].edition == null || window['runtimeConfig'].edition === 1;
    },

    retrieveConfiguration() {
        return this.configuration;
    },

    retrieveAvailableCountries() {
        return this.retrieveConfiguration().countries;
    },

    // CS 16/3/22: fixed problem that original x.item value has been translated but been used to search by (e.g. uploadImageComponent) so we added original value as x.code
    retrieveDocumentTypes() {
        return this.retrieveConfiguration().documentTypes.map(x => { return { ...x, item: _(x.item), code:x.item } }).sort((a, b) => a.item.localeCompare(b.item));
    },

    // CS 2/9/21: prepare and order all jobTypes right after loading them so we only have to do it once
    // CS 16/9/21: changed and introduced deep cloning of array objects to be translated into 
    // CS 20/4/22: adapted changes to new healthcare edition which uses different names (e.g. jobTypes.health.other)
    // separate array this.orderedJobTypes
    orderJobTypes(){

        // CS 16/9/21: deep clone original array and all objects within
        var list = [...this.configuration.jobTypes];

        list = list.map(x => x = JSON.parse(JSON.stringify(x))); // object deep clone

        // find jobTypes.other which need to push at the end of the list
        var jobOther = list.find(x => x.item.includes(".other")); // find the 'other' item
        if ( jobOther != null)
        {
            // remove 'other' from the list so we can push it to the the end later
            list = list.filter(x => !x.item.includes(".other")); // remove 'other' from list

            // translate .item
            if ( jobOther != null ) {
                jobOther.code = jobOther.item; // store 'other' code
                jobOther.item = _(jobOther.item); // translate 'other'
            }
        }

        // replace .item now with the matching translation
        list = list.map(x => { return { ...x, code: x.item, item: _(x.item)} }).sort((a, b) => a.item.localeCompare(b.item));

        // 6/9/22: only PHARMACY edition will get the job type OTHER
        if ( this.isPharmacyEdition() && jobOther != null ) list.push(jobOther);
        this.orderedJobTypes = list;
    },

    // CS 16/3/22: added proper filtering of country related items
    // CS 25/3/22: added extra support for explicit country to work even for other country profiles
    retrieveJobTypes(country = null) {

        // CS 16/3/22: get current country and filter out non related items
        // CS 25/3/22: added extra suppoprt for explicit country to work even for other country profiles
        if ( country == null) country = LanguageController.determineLanguage();

        // CS 25/3/22: assert that country is lower case
        country = country.toLowerCase();   

        var countryJobTypes = this.orderedJobTypes.filter(x => x.country == null || x.country.toLowerCase().includes(country));
        
        // translate for requested country
        countryJobTypes = countryJobTypes.map(x => { return { ...x, item: _(x.code, country) } });

        // sort after translating
        countryJobTypes = countryJobTypes.sort((a, b) => a.item.localeCompare(b.item));

        return countryJobTypes;

    },

    // CS 6/10/21: new function to retrieve only the native result from the matching jobType 
    // which contains the complete list of available jobs, not only the once matching the current users country
    // CS 23/11/22: added new optional parameter which translates the returned item for the requested countryCode and runs the automatic translation
    // CS 24/11/22: added deep cloning so cached items are not being overwritten (issue when switching between countries during tests)
    retrieveNativeJobType(jobTypeID, country = null ) {

        // CS 24/11/22: deep clone original cached array and all objects within
        var list = [...this.configuration.jobTypes];
        list = list.map(x => x = JSON.parse(JSON.stringify(x))); // object deep clone

        var data = list.find( x => x.id == jobTypeID);   // get native record where x.item contains the code
        // store item into code and translate item into requested (or current) country language
        data.code = data.item;
        data.item = _(data.item, country);

        //console.log(`>> retrieveNativeJobType: country='${country}', code='${data.code}', item='${data.item}'`)

        return data;
    },

    changeOfLanguage(newLanguage) {
        this.orderJobTypes();
    },

    //
    // CS 8/3/22: added parameter suppport to leave shift type out if necessary (by default all shift types are included)
    //
    retrieveShifts(includeNightShift = true) {
        //return this.retrieveConfiguration().shifts.map(x => { return { ...x, item: _(x.item), raw: x.item } }).sort((a, b) => b.raw.localeCompare(a.raw));
        var data = this.retrieveConfiguration().shifts.map(x => { return { ...x, item: _(x.item), raw: x.item } });

        //
        // CS 20/1/22:
        // Because of the ridiculous data model and database layer we have to sort them here by a hardcoded logical mean
        //
        // 0: Morning shift
        // 1: Afternoon shift
        // 2: Full Day
        // 3: Not available
        // 4: Night shift
        //
        // We are going to sort them the following way so they make any sense at all:
        //
        // Not available, Full day, Morning, Afternoon, Night
        //

        var sortedList = [];
        sortedList.push(data.filter(x => x.raw.toLowerCase() === 'shifts.3')[0]);  // not available entry
        sortedList.push(data.filter(x => x.raw.toLowerCase() === 'shifts.2')[0]);  // full day
        sortedList.push(data.filter(x => x.raw.toLowerCase() === 'shifts.0')[0]);  // morning
        sortedList.push(data.filter(x => x.raw.toLowerCase() === 'shifts.1')[0]);  // afternoon
        
        // CS 8/3/22: only include NIGHT if permitted
        // NIGHT SHIFT (OPTIONAL, only in healtcare edition)
        //sortedList.push(data.filter(x => x.raw.toLowerCase() === 'shifts.4')[0]);  // night shift
        // CS 10/3/22: PHARMACY edition doesn't suppoprt NIGHT shift types
        if ( includeNightShift && this.isPharmacyEdition() == false)
        {
            var item = data.filter(x => x.raw.toLowerCase() === 'shifts.4');
            if ( item.length > 0 ) sortedList.push(item[0]);  // night shift
        }

        return sortedList;
    },

    retrieveLanguages() {
        return this.retrieveConfiguration().languages.map(x => { return { ...x, item: _(x.item) } }).sort((a, b) => a.item.localeCompare(b.item));
    },

    retrieveCashierSystems() {
        // var language = LanguageController.determineLanguage();
        // return this.retrieveConfiguration().cashierSystems
        //     .filter(x => x.item.split("|")[1].indexOf(language) !== -1)
        //     .map(x => { return { ...x, item: _(x.item.split("|")[0]) + "|" + x.item.split("|")[1] } })
        //     .sort((a, b) => a.item.split("|")[0].localeCompare(b.item.split("|")[0]));

        // CS 6/9/21: new properties Category and Countries, Item does not provide the country codes anymore
        var language = LanguageController.determineLanguage();
        return this.retrieveConfiguration().cashierSystems
            .filter(x => x.countries.indexOf(language) !== -1)
            .map(x => { return { ...x, item: _(x.item), category: x.category } })
            .sort((a, b) => a.item.localeCompare(b.item));
    },

    retrieveCashierSystemsAll() {
        return this.retrieveConfiguration().cashierSystems
            .map(x => { return { ...x, item: _(x.item), category: x.category } })
            .sort((a, b) => a.item.localeCompare(b.item));
        // return this.retrieveConfiguration().cashierSystems
        //     .map(x => { return { ...x, item: _(x.item.split("|")[0]) + "|" + x.item.split("|")[1] } })
        //     .sort((a, b) => a.item.split("|")[0].localeCompare(b.item.split("|")[0]));
    },

    retrieveCashierSystemsOther(lang) {
        var language = LanguageController.determineLanguage();
        return this.retrieveConfiguration().cashierSystems
            .filter(x => x.countries.indexOf(language) === -1 && x.countries.indexOf(lang) !== -1)
            .map(x => { return { ...x, item: _(x.item), category: x.category } })
            .sort((a, b) => a.item.localeCompare(b.item));
        // var language = LanguageController.determineLanguage();
        // return this.retrieveConfiguration().cashierSystems
        //     .filter(x => x.item.split("|")[1].indexOf(language) === -1 && x.item.split("|")[1].indexOf(lang) !== -1)
        //     .map(x => { return { ...x, item: _(x.item.split("|")[0]) + "|" + x.item.split("|")[1] } })
        //     .sort((a, b) => a.item.split("|")[0].localeCompare(b.item.split("|")[0]));
    },

    retrieveCashierSystemLanguages() {

        var language = LanguageController.determineLanguage();
        var languages = this.retrieveConfiguration().cashierSystems.map(x => { return x.countries });

        var result = [];
        languages.forEach(element => {
            element.split(",").forEach(lang => {
                /* console.log(lang); */
                if (lang !== language && result.indexOf(lang) === -1) { result.push(lang); }
            });
        });
        return result.map(x => _(x)).sort((a, b) => a.localeCompare(b));
    },

    retrieveCustomerSkills() {
        return this.retrieveConfiguration().skillsCustomer.map(x => { return { ...x, item: _(x.item) } }).sort((a, b) => a.item.localeCompare(b.item));
    },

    retrieveManufacturSkills() {
        return this.retrieveConfiguration().skillsManufactur.map(x => { return { ...x, item: _(x.item) } }).sort((a, b) => a.item.localeCompare(b.item));
    },

    retrieveAdminSkills() {
        return this.retrieveConfiguration().skillsAdmin.map(x => { return { ...x, item: _(x.item) } }).sort((a, b) => a.item.localeCompare(b.item));
    },

    retrieveLogisticSkills() {
        return this.retrieveConfiguration().skillsLogistic.map(x => { return { ...x, item: _(x.item) } }).sort((a, b) => a.item.localeCompare(b.item));
    },

    retrieveMarketingSkills() {
        return this.retrieveConfiguration().skillsMarketing.map(x => { return { ...x, item: _(x.item) } }).sort((a, b) => a.item.localeCompare(b.item));
    },

    retrieveNationalities() {
        return this.retrieveConfiguration().nationalities.map(x => { return { ...x, item: _(x.item) } }).sort((a, b) => a.item.localeCompare(b.item));
    },

    retrievePublicKey() {
        if (this.key) {
            return this.key;
        }

        let parser = new DOMParser().parseFromString(atob(this.retrieveConfiguration().publicKey), "text/xml");
        this.key = new NodeRSA().importKey({
            n: Buffer.from(parser.getElementsByTagName("Modulus")[0].childNodes[0].nodeValue, "base64"),
            e: Buffer.from(parser.getElementsByTagName("Exponent")[0].childNodes[0].nodeValue, "base64")
        }, "components-public");

        return this.key;
    }

};

export default ConfigController