import React, { Component } from 'react';
import { _ } from '../../controllers/languageController';
import ConfigController from '../../controllers/configController';

export default class LoadingItemComponent extends Component {

    render() {
        return (
            <div className="w-100">
                <div className="d-flex justify-content-center pt-2 animated infinite flash slower delay-1s">
                    {ConfigController.isPharmacyEdition() && 
                        <img style={{ height: "2rem" }} src={process.env.PUBLIC_URL + "/assets/images/apo-loading-icon.svg?v=2.0"} alt="" />
                    }
                    {ConfigController.isHealthcareEdition() && 
                        <img style={{ height: "2rem" }} src={process.env.PUBLIC_URL + "/assets/images/pflege-loading-icon.svg?v=1.0"} alt="" />
                    }
                    <h5 className="pl-1 my-auto text-muted">{_("loading")}</h5>
                </div>
            </div>
        );
    }

}