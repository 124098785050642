import React, { Component, Fragment } from 'react';
import AuthenticationController from '../../../controllers/authenticationController';
import ApiController, { callbackWrapper } from '../../../controllers/apiController';
import { Formik, Field } from 'formik';
import * as Yup from "yup";
import { withRouter } from 'react-router-dom';
import { Form, FormGroup, Button } from 'reactstrap';
import { _ } from '../../../controllers/languageController';
import { InputForm } from '../inputFormComponent';
import AccordionItemComponent from '../accordionItemComponent';
import NotificationController from '../../../controllers/notificationController';
import swal from 'sweetalert';  // https://sweetalert.js.org/guides/

//
// CS: 8/7/20
// control for searching users (either persons or pharmacy) with displaying matching entries
//
class UserSearchComponent extends Component {

    constructor(props) {
        super(props);
        // <... userType={"Person" || "Pharmacy"}/>
        // <... mostRecentDays={14}/>
        // <... accordionTitle={14}/>
        this.state = {
            userList: null,
            searchTerm: "",  // search term being used for filtering
        };

        this.cardHeaderStyle={
            padding:'.5rem 1rem', 
            textTransform: "uppercase",
            backgroundColor: '#f8f8f8',
            border: '1px solid #ddd',
            fontSize: '1.2em'
        };
        this.cardBodyStyle={
            padding:'.5rem 1rem', 
            paddingBottom: '1rem'
        };
    }

    // default values for possibly missing props
    static defaultProps = {
        userType: "person",  // type of user to search for (person or pharmacy) - default to person
        mostRecentDays: 14, // count of days which qualify for initial most recent user list
        showOpen: false,    // controls if accordion should be displayed initially as open or closed
        accordionTitle: ""  // title shown in the accordion
    };

    // CS: make sure user is really logged on as admin
    componentDidMount() {
        if (!AuthenticationController.isAdmin()) {
            this.props.history.push("/");
        }

        this.loadData();
    }

/*     
    componentDidUpdate(prevProps, prevState) {
        //if ( this.props.onCountChange){this.props.onCountChange(1234);}
    }
 */
    loadData()
    {
        if ( this.props.userType === "person")
        {
            ApiController.adminRetrieveAllPersons(callbackWrapper((response) => 
            {
                this.setState({ userList: response.data.result});
            }));
        }
        else if ( this.props.userType === "pharmacy")
        {
            ApiController.adminRetrieveAllPharmacies(callbackWrapper((response) => 
            {
                this.setState({ userList: response.data.result});
            }));
        }
    }

    renderUsers() {

        // no data available?
        if (this.state.userList === null || this.state.userList.length === 0) return;

        if ( this.state.searchTerm === "")
        {
             return this.renderMostRecentUsers();
        }
        return this.renderMatchingUsers(this.state.searchTerm);
    }

    renderMatchingUsers(searchTermList) {

        // no data available?
        if (this.state.userList === null || this.state.userList.length === 0) return;
        
        var data = this.state.userList.map((x) => x); // clone array
        var keywords = searchTermList.split(' ');
        var props_cache = this.props

        // run filter for each keyword provided (AND search)
        keywords.forEach(function(item,index){
            
            var regex = new RegExp( item, "i");

            if ( props_cache.userType === "person")
                data = data.filter(x => regex.test(x.firstname) || regex.test(x.lastname) || regex.test(x.account.email) || regex.test(x.account.phone));
            else if ( props_cache.userType === "pharmacy")
                data = data.filter(x => regex.test(x.name) || regex.test(x.address) || regex.test(x.account.email) || regex.test(x.account.phone) || regex.test(x.postalArea.code) || regex.test(x.postalArea.name));
    
        });

        if ( this.props.userType === "person")
        {
            return this.renderPersons(data, `${data.length} gefunden`);
        }
        else if ( this.props.userType === "pharmacy")
        {
            return this.renderPharmacies(data, `${data.length} gefunden`);
        }
    }

    renderMostRecentUsers() {

        // no data available?
        if (this.state.userList === null || this.state.userList.length === 0) return;

        // calculate fromDate
        var fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - this.props.mostRecentDays);

        if ( this.props.userType === "person")
        {
            //var data = this.state.userList.filter()
            var data = this.state.userList.filter(x => new Date(Date.parse(x.account.creationDate)) >= fromDate).sort((a, b) => b.account.creationDate.localeCompare(a.account.creationDate));
            return this.renderPersons(data, `Neuer als ${this.props.mostRecentDays} Tage`);
        }
        else if ( this.props.userType === "pharmacy")
        {
            var data = this.state.userList.filter(x => new Date(Date.parse(x.account.creationDate)) >= fromDate).sort((a, b) => b.account.creationDate.localeCompare(a.account.creationDate));
            return this.renderPharmacies(data, `Neuer als ${this.props.mostRecentDays} Tage`);
        }
    }

    /* 
        CS: 21/12/2021
        initiates the impersonation of an account (person or pharmacy)
    */
    impersonateAccount = (e, accountId, accountName) =>
    {
        e.preventDefault();

        swal(`Sind sie sicher wie wollen '${accountName}' administrieren?`, {
            title: 'Impersonate?',
            buttons: ["Abbruch", "Ja, impersonate!"],
            dangerMode: true  /* sets focus on cancel */
        })
        .then((value) => {
        //console.log("swal value = " + value);
        if ( value === true)
        {
            /* swal("Der Eintrag wurde erfolgreich gelöscht!", {icon: "success",}); */
            this.confirmedImpersonateAccount(accountId, accountName);
        }
        });
    }

    /* 
       after user confirmed impersonation this routine will kick it off (person or pharmacy)
    */
    confirmedImpersonateAccount = (accountId, accountName) =>
    {
        ApiController.adminImpersonateAccount(accountId, callbackWrapper((response) => {

        if (response.data.error) {
            NotificationController.pushError(_(response.data.error));
            return;
        }

        // response.data.result holds the token
        var token = response.data.result;

        NotificationController.pushSuccess(`${_("success.impersonationActive")} '${accountName}'`);
        // set current token
        AuthenticationController.setToken(token);

        this.props.history.push("/");

        }).bind(this));
    }    
   
    
    /* 
        initiates the deletion of an account (person or pharmacy) 
        by asking for confirmation first 
    */
    deleteAccount = (e, accountId) =>
    {
        e.preventDefault();

        swal("Sind sie sicher?", {
            icon: 'warning',
            title: 'Account Löschen',
            buttons: ["Abbruch", "Ja, löschen!"],
            dangerMode: true  /* sets focus on cancel */
        })
        .then((value) => {
        //console.log("swal value = " + value);
        if ( value === true)
        {
            /* swal("Der Eintrag wurde erfolgreich gelöscht!", {icon: "success",}); */
            this.confirmedDeleteAccount(accountId);
        }
        });
    }

    /* 
       after user confirmed this routine will physically delete the account (person or pharmacy)
    */
    confirmedDeleteAccount = (accountId) =>
    {
       ApiController.adminDeleteAccount(accountId, callbackWrapper((response) => {

           if (response.data.error) {
               NotificationController.pushError(_(response.data.error));
               return;
           }

           /* reload data for this page again */
           this.loadData();

           NotificationController.pushSuccess(_("success.someAccountDeleted"));

           this.props.history.push("/");

       }).bind(this));
    }    

    /* 
        initiates the activation of an inactive account (person or pharmacy) 
        by asking for confirmation first 
    */
    activateAccount = (e, accountId, accountName) =>
    {
        e.preventDefault();

        swal(`Sind sie sicher wie wollen '${accountName}' aktivieren?`, {
            icon: 'warning',
            title: 'KONTO AKTIVIEREN',
            buttons: ["Abbruch", "Ja, aktivieren!"],
            dangerMode: true  /* sets focus on cancel */
        })
        .then((value) => {
        //console.log("swal value = " + value);
        if ( value === true)
        {
            /* swal("Der Eintrag wurde erfolgreich gelöscht!", {icon: "success",}); */
            this.confirmedActivateAccount(accountId);
        }
        });
    }
    
    /* 
       after user confirmed this routine will activate an inactive account (person or pharmacy)
    */
    confirmedActivateAccount = (accountId) =>
    {
       ApiController.adminActivateAccount(accountId, callbackWrapper((response) => {

           if (response.data.error) {
               NotificationController.pushError(_(response.data.error));
               return;
           }

           /* reload data for this page again */
           this.loadData();

           NotificationController.pushSuccess(_("success.someAccountActivated"));

           this.props.history.push("/");

       }).bind(this));
    }    

    renderPersons(data, header) {

        if ( data === null || data.length === 0) return;

        var result = [];

        if (header !== undefined)
        {
            var headerElement = <div key={Math.random()} className="btn btn-sm btn-light w-100 mb-2">{header}</div>
            result.push(headerElement); // add on top
        }

        var items = data.map(x=> 
        (
            //var creationDate = new Date(Date.parse(x.account.creationDate));
            <div key={Math.random()} className="card text-left hover-shadow grow-2">
                <h4 className="card-header" style={this.cardHeaderStyle}>
                    {x.gender === 1 ? "Fr. " : x.gender === 2 ? "Hr. " : ""}
                    {x.firstname} {x.lastname}
                </h4>
                <div className="card-body" style={this.cardBodyStyle}>
                    <p className="card-text">
                        <div className="user-detail-grid">
                            <span className="material-icons">today</span>
                            <span>{this.format_date(x.account.creationDate)}</span>

                            <span className="material-icons">email</span>
                            <a href={`mailto:${x.account.email}`}>{x.account.email}</a>

                            {x.account.phone && x.account.phone.trim() != "" && <span className="material-icons text-xs">phone</span>}
                            {x.account.phone && x.account.phone.trim() != "" && <span>+{x.account.phoneCountryCode} {x.account.phone}</span>}
                        </div>
                    </p>
{/* 
                    <a href="#" 
                        onClick={(e) => this.onClickViewPersonAccount(e, x.account.id) }
                        className="btn btn-sm btn-secondary">View profile</a>
 */}
                 </div>
                 <div className="card-footer d-flex justify-content-between align-content-center">
                    <a href="#" onClick={(e) => this.deleteAccount(e, x.account.id) } className="action-delete grow-10">Delete</a>
                    {x.account.active == false && 
                        <a href="#" onClick={(e) => this.activateAccount(e, x.account.id, x.firstname + " " + x.lastname) } className="action-activate grow-10">Activate: {_(x.job)}</a>
                    }
                    <a href="#" onClick={(e) => this.impersonateAccount(e, x.account.id, x.firstname + " " + x.lastname) } className="action-impersonate grow-10">Impersonate</a>
                 </div>
            </div>
        ));

        // wrap the items in special div.user-search-grid
        result.push(
            <div className="user-search-grid">
                {items}
            </div>
        );

        return result;
    }

    renderPharmacies(data, header) {

        if ( data === null || data.length === 0) return;

        var result = [];

        if (header !== undefined)
        {
            var headerElement = <div key={Math.random()} className="btn btn-sm btn-light w-100 mb-2">{header}</div>
            result.push(headerElement); // add on top
        }

        //console.log(data);

        var items = data.map(x=> 
        (
            <div key={Math.random()} className="card text-left hover-shadow grow-2">
                <h4 className="card-header" style={this.cardHeaderStyle}>
                    {x.name}
                </h4>
                <div className="card-body" style={this.cardBodyStyle}>
                    <p className="card-text text-xs">
                        <div className="user-detail-grid">
                            <span className="material-icons">today</span>
                            <span>{this.format_date(x.account.creationDate)}</span>
                            {x.account.phone && x.account.phone.trim() != "" && <span className="material-icons text-xs">phone</span>}
                            {x.account.phone && x.account.phone.trim() != "" && <span>+{x.account.phoneCountryCode} {x.account.phone}</span>}
                            <span className="material-icons">email</span>
                            <a href={`mailto:${x.account.email}`}>{x.account.email}</a>
                            <span className="material-icons">place</span>
                            <span>{x.address}, {x.postalArea.code}-{x.postalArea.name}</span>
                        </div>
                    </p>
{/*                     
                    <a href="#" 
                        onClick={(e) => this.onClickViewPharmacyAccount(e, x.account.id) }
                        className="btn btn-sm btn-secondary">
                        View profile
                    </a>
*/}
                </div>
                <div className="card-footer d-flex justify-content-between align-content-center">
                    <a href="#" onClick={(e) => this.deleteAccount(e, x.account.id) } className="action-delete grow-10">Delete</a>
                    {x.account.active == false && 
                        <a href="#" onClick={(e) => this.activateAccount(e, x.account.id, x.name) } className="action-activate grow-10">Activate: {_(x.job)}</a>
                    }
                    <a href="#" onClick={(e) => this.impersonateAccount(e, x.account.id, x.name) } className="action-impersonate grow-10">Impersonate</a>
                 </div>
            </div>
        ));

        // wrap the items in special div.user-search-grid
        result.push(
            <div className="user-search-grid">
                {items}
            </div>
        );

        return result;
    }    

    // reformats the provided .NET date in json into a string
    format_date = (jsonDate) => {
        var d = new Date(Date.parse(jsonDate));

        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)        

        return `${da}-${mo}-${ye}`;
    }

    handleInputChange(event) {

        let enteredText = event.target.value;

        // ignore everything with less than 3 characters
        if ( enteredText.length < 3 ) {
            if ( this.state.searchTerm !== "") this.setState({searchTerm: ""});
            return; 
        }

        //console.log(`Name:${event.target.name} = ${enteredText}`);
        this.setState({searchTerm: enteredText});
    }    

    render() {
/*         
        if (this.state.loading) {
            return (<LoadingItemComponent />);
        }
 */
        var titleCount = this.state.userList !== null ? this.state.userList.length : 0;
        var titleStr = `${this.props.accordionTitle} (${titleCount})`;

        return (
            <Fragment>
                <AccordionItemComponent title={titleStr} show={this.props.showOpen}>

                    <Formik render={(props) => (
                        <FormGroup className="mb-2">
                            <Field placeholder="Suche..." name="searchUser" component={InputForm} onChange={this.handleInputChange.bind(this)}/>
                        </FormGroup>
                    )}/>
                    {/* renders the most recent users only */}
                    <div id="admin-page">
                        {this.renderUsers()}
                    </div>

                </AccordionItemComponent>

            </Fragment>
        );
    }

}

export default withRouter(UserSearchComponent);