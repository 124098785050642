import React, { Component } from 'react';
import LoadingController from '../../controllers/loadingContoller';
import NotificationController from '../../controllers/notificationController';
import ApiController, { callbackWrapper } from '../../controllers/apiController';
import { _ } from '../../controllers/languageController';

export default class Verify extends Component {

    componentWillMount() {
        LoadingController.show();

        if (!this.props.match.params.token) {
            NotificationController.pushError(_("invalid.token"), _("error.verify"));
            LoadingController.hide();
            this.props.history.push("/");
            return;
        }

        ApiController.verify(this.props.match.params.token, callbackWrapper(this.callback.bind(this)).bind(this));
    }

    callback(response) {
        if (response.data.error) {
            NotificationController.pushError(_(response.data.error.message), _("error.verify"));
            this.props.history.push("/");
            return;
        }

        NotificationController.pushSuccess(_("success.verify"));
        this.props.history.push("/");
    }

    render() {
        return null;
    }

}