import React, { Component } from 'react';
import CenteredComponent from '../centeredComponent';
import AccordionItemComponent from '../accordionItemComponent';
import { withRouter } from 'react-router-dom';
import LanguageController, { _ } from '../../../controllers/languageController';
import { Form, Button, FormGroup, Input } from 'reactstrap';
import { Formik } from 'formik';
import AuthenticationController from '../../../controllers/authenticationController';

import Slider from "rc-slider";
import 'rc-slider/assets/index.css';
import SelectInputComponent, { optionFilter } from '../selectInputComponent';
import ConfigController from '../../../controllers/configController';
import InputComponent from '../inputComponent';
import ProfileAvailabilityComponent from './profileAvailabilityComponent';

class ManageProfileComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            submitted: false,
            type: AuthenticationController.isPerson() ? "person" : "pharmacy",
            isPerson: AuthenticationController.isPerson(),
            alternateWeekdaysOpen: false,
            // CS: added early the profiles country so that data can be loaded correctly from now on
            jobCountryCode: this.props.job.postalCountryCode,
            jobID: this.props.job.ID,
            job: this.props.job || {
                jobType: null, // will be determined later in willMount
                distance: 0
            },
            selection: this.props.selection || {
                other: {},
                otherText: {},
                selected: {
                    language: {},
                    cashierSystem: {},
                    customer: {},
                    manufactur: {},
                    admin: {},
                    logistic: {},
                    marketing: {}
                }
            },
            homeCountryName: _("country."+LanguageController.determineLanguage()),  // CS 10/9/21
        };

        //console.log({ job: this.state.job, selection: this.state.selection });
    }

    componentWillMount() {

        //
        // here we determine if the alternate weekdays need to be open or collapsed by default
        //
        var altWeekOpen = false;

        if ( this.state.job.altMonday && this.state.job.altMonday.id != this.state.job.monday.id) altWeekOpen = true;
        if ( this.state.job.altTuesday && this.state.job.altTuesday.id != this.state.job.tuesday.id) altWeekOpen = true;
        if ( this.state.job.altWednesday && this.state.job.altWednesday.id != this.state.job.wednesday.id) altWeekOpen = true;
        if ( this.state.job.altThursday && this.state.job.altThursday.id != this.state.job.thursday.id) altWeekOpen = true;
        if ( this.state.job.altFriday && this.state.job.altFriday.id != this.state.job.friday.id) altWeekOpen = true;
        if ( this.state.job.altSaturday && this.state.job.altSaturday.id != this.state.job.saturday.id) altWeekOpen = true;
        if ( this.state.job.altSunday && this.state.job.altSunday.id != this.state.job.sunday.id) altWeekOpen = true;
        
        this.setState({ alternateWeekdaysOpen: altWeekOpen });

        // CS 23/11/22: load the whole job type into the state using the already set joBID and countryCode from the props
        this.state.job.jobType = ConfigController.retrieveNativeJobType(this.state.jobID, this.state.jobCountryCode);
    }

    onSubmit() {

        //console.log(`submitted this.state.job.remarks = ${this.state.job.remarks}`)
        //return;

        if (!this.state.submitted) this.setState({ submitted: true });

        // no jobtype selected?
        if (!this.state.job.jobType) return;

        //
        // CS 28/1/22
        // if state.alternateWeekdaysOpen has been turned off make sure all alternate weekdays match the std weekdays
        //
        if ( this.state.alternateWeekdaysOpen == false)
        {
            this.state.job.altMonday = this.state.job.monday;
            this.state.job.altTuesday = this.state.job.tuesday;
            this.state.job.altWednesday = this.state.job.wednesday;
            this.state.job.altThursday = this.state.job.thursday;
            this.state.job.altFriday = this.state.job.friday;
            this.state.job.altSaturday = this.state.job.saturday;
            this.state.job.altSunday = this.state.job.sunday;
        }
        else{
            // CS 25/3/22: SUNDAY / altSunday
            // clean up default values for Sunday (pharmacy edition doesn't support sunday so they have to be set)
            if ( this.state.job.altSunday == null ) this.state.job.altSunday = this.state.job.sunday;
        }

        // CS 25/3/22: for Pharmacy edition set Sunday and altSunday to NotAvailable by default
        if ( ConfigController.isPharmacyEdition())
        {
            var notAvailableForWork = this.retrieveShifts()[0];

            this.state.job.sunday = notAvailableForWork;
            this.state.job.altSunday = notAvailableForWork;
        }

        //
        // CS 10/2/22
        // clean up availability - time periods
        //
        if (this.state.job.availabilities)
        {
            var availabilityList = this.state.job.availabilities;
            // remove all items with missing values
            availabilityList = availabilityList.filter(x => x.periodStart != '' && x.periodEnd != '');
            // remove all items where periodEnd <= today
            availabilityList = availabilityList.filter(x => new Date(x.periodEnd) > new Date());
    
            // update state
            this.state.job.availabilities = availabilityList;
            // if temp job has not been selected - return empty list and ignore what has been entered
            if ( this.state.job.replacement.id != true) this.state.job.availabilities = [];
        }

        if (this.props.submit) {
            this.props.submit({
                job: this.state.job,
                postalArea: this.state.selectedPostalArea,
                selection: this.state.selection
            });
        }
    }

    onBack() {
        this.props.history.push("/profile");
    }

    selectJobType(item) {

        var fullDayWork = this.retrieveShifts()[1];    // default shift type is FULL DAY
        var notAvailableForWork = this.retrieveShifts()[0];      // not available for work

        if (this.state.job.jobType.id === item.id) return;

        let job = Object.assign({}, this.state.job);
        job.jobType = item;
        //job.hoursPerWeek = item.maximumHoursPerWeek;
        job.hoursPerWeek = item.defaultHoursPerWeek;

        job.monday = fullDayWork;
        job.tuesday = fullDayWork;
        job.wednesday = fullDayWork;
        job.thursday = fullDayWork;
        job.friday = fullDayWork;
        job.saturday = fullDayWork;
        job.sunday = notAvailableForWork;

        // CS 24/1/22: added new alternate week set (1-Monday, 21 - alternate week Monday)
        job.altMonday = fullDayWork;
        job.altTuesday = fullDayWork;
        job.altWednesday = fullDayWork;
        job.altThursday = fullDayWork;
        job.altFriday = fullDayWork;
        job.altSaturday = fullDayWork;
        job.altSunday = notAvailableForWork;

        job.nightlyShift = this.retrieveBoolDecisions()[0];
        //job.replacement = this.retrieveBoolDecisions()[0];
        // CS 7/4/22: default replacement to true for health care edition
        job.replacement = ConfigController.isHealthcareEdition() ? this.retrieveBoolDecisions()[1] : this.retrieveBoolDecisions()[0];


        this.setState({ job: job });
    }

    selectDistance(value) {
        let job = Object.assign({}, this.state.job);
        job.distance = Math.ceil((value * value) / 5) * 5;

        this.setState({ job: job });
    }

    retrieveDistance() {
        if (this.state.job.distance <= 0)
            return _("distance.0");
        else if (this.state.job.distance >= 1000)
            return _("distance.1000");
            
        return this.state.job.distance + " " + _("km");
    }

    retrieveJobTypes() {

        if (this.jobTypes) return this.jobTypes;
        
        // CS 23/11/22: use new coutnry override call to get all jobtypes
        this.jobTypes = ConfigController.retrieveJobTypes(this.state.jobCountryCode);

        return this.jobTypes;
    }

    retrieveHoursPerWeek() {
        if ( ConfigController.isPharmacyEdition())
            return `${(this.state.job.hoursPerWeek || this.retrieveJobType().maximumHoursPerWeek)} ${_("text.hour")} / ${(this.state.job.hoursPerWeek || this.retrieveJobType().maximumHoursPerWeek) / 4} ${_("text.tenth")}`;

        return `${(this.state.job.hoursPerWeek || this.retrieveJobType().maximumHoursPerWeek)} ${_("text.hour")}`;
    }

    retrieveAvailableIn() {
        return `${(this.state.job.availableIn || 0)} ${_("text.week")} / ${(this.state.job.availableIn || 0) / 4} ${_("text.month")}`;
    }

    retrieveJobType() {
        return this.state.job.jobType;
    }

    retrieveShifts() {

        // CS 8/3/22: job type pharmacist can NOT SELECT NIGHT shift type
        // var includeNightShiftType = this.state.job.jobType.code != "jobTypes.pharmacist";
        // if (!this.shifts) this.shifts = ConfigController.retrieveShifts(includeNightShiftType);
        // CS 10/3/22: shifts with NIGHT if not pharmacy edition
        if (!this.shifts) this.shifts = ConfigController.retrieveShifts();
        return this.shifts;
    }

    retrieveBoolDecisions() 
    {
        if (this.decision) return this.decision;

        this.decision = [{ id: false, item: _("false") }, { id: true, item: _("true") }]
        return this.decision;
    }

    // CS 29/9/22: toggle function for the ridiculous bool/bit converter functions
    toggleBoolDecision(current){

        if ( current == null || current.id === false ) return this.retrieveBoolDecisions()[1];
        return this.retrieveBoolDecisions()[0];
    }

    selectHoursPerWeek(value) {
        this.setState(prevState => ({job: {...prevState.job, hoursPerWeek: value}}));
    }
    selectAvailableIn(value) {
        this.setState(prevState => ({job: {...prevState.job, availableIn: value}}));
    }
    // 
    // Weekdays
    //
    selectMonday(value) {

        if ( this.state.job.altMonday == null || this.state.job.monday.id === this.state.job.altMonday.id)
            this.setState(prevState => ({job: {...prevState.job, monday: value, altMonday: value}}));
        else
            this.setState(prevState => ({job: {...prevState.job, monday: value}}));
    }
    selectTuesday(value) {
        if ( this.state.job.altTuesday == null || this.state.job.tuesday.id === this.state.job.altTuesday.id)
            this.setState(prevState => ({job: {...prevState.job, tuesday: value, altTuesday: value}}));
        else
            this.setState(prevState => ({job: {...prevState.job, tuesday: value}}));
    }
    selectWednesday(value) {
        if ( this.state.job.altWednesday == null || this.state.job.wednesday.id === this.state.job.altWednesday.id)
            this.setState(prevState => ({job: {...prevState.job, wednesday: value, altWednesday: value}}));
        else
            this.setState(prevState => ({job: {...prevState.job, wednesday: value}}));
    }
    selectThursday(value) {
        if ( this.state.job.altThursday == null || this.state.job.thursday.id === this.state.job.altThursday.id)
            this.setState(prevState => ({job: {...prevState.job, thursday: value, altThursday: value}}));
        else
            this.setState(prevState => ({job: {...prevState.job, thursday: value}}));
    }
    selectFriday(value) {
        if ( this.state.job.altFriday == null || this.state.job.friday.id === this.state.job.altFriday.id)
            this.setState(prevState => ({job: {...prevState.job, friday: value, altFriday:value}}));
        else
            this.setState(prevState => ({job: {...prevState.job, friday: value}}));
    }
    selectSaturday(value) {
        if ( this.state.job.altSaturday == null || this.state.job.saturday.id === this.state.job.altSaturday.id)
            this.setState(prevState => ({job: {...prevState.job, saturday: value, altSaturday:value}}));
        else
            this.setState(prevState => ({job: {...prevState.job, saturday: value}}));
    }
    selectSunday(value) {
        if ( this.state.job.altSunday == null || this.state.job.sunday.id === this.state.job.altSunday.id)
            this.setState(prevState => ({job: {...prevState.job, sunday: value, altSunday: value}}));
        else
            this.setState(prevState => ({job: {...prevState.job, sunday: value}}));
    }
    //
    // Alternate weekday selector functions
    //
    selectAlternateMonday(value) {
        this.setState(prevState => ({job: {...prevState.job, altMonday: value}}));
    }
    selectAlternateTuesday(value) {
        this.setState(prevState => ({job: {...prevState.job, altTuesday: value}}));
    }
    selectAlternateWednesday(value) {
        this.setState(prevState => ({job: {...prevState.job, altWednesday: value}}));
    }
    selectAlternateThursday(value) {
        this.setState(prevState => ({job: {...prevState.job, altThursday: value}}));
    }
    selectAlternateFriday(value) {
        this.setState(prevState => ({job: {...prevState.job, altFriday: value}}));
    }
    selectAlternateSaturday(value) {
        this.setState(prevState => ({job: {...prevState.job, altSaturday: value}}));
    }
    selectAlternateSunday(value) {
        this.setState(prevState => ({job: {...prevState.job, altSunday: value}}));
    }


    selectNightlyShift(value) {
        this.setState(prevState => ({job: {...prevState.job, nightlyShift: value}}));
    }

    selectReplacement(value) {
        this.setState(prevState => ({job: {...prevState.job, replacement: value}}));
    }

    selectOverpay(value) {
        this.setState(prevState => ({job: {...prevState.job, availableForOverpay: value}}));
    }

    selectAccomodation(value) {
        this.setState(prevState => ({job: {...prevState.job, accomodation: value}}));
    }

    selectManagement(value) {
        this.setState(prevState => ({job: {...prevState.job, management: value}}));
    }

    selectLicenceHolder(value) {
        this.setState(prevState => ({job: {...prevState.job, licenceHolder: value}}));
    }

    selectCovidTester(value) {
        this.setState(prevState => ({job: {...prevState.job, covidTester: value}}));
    }

    selectLindaFlag(value) {
        this.setState(prevState => ({job: {...prevState.job, lindaFlag: value}}));
    }

    toggleFlexibleCalenderWeek() {

        var newState = this.toggleBoolDecision(this.state.job.flexibleCalendarWeek);
        var turnedOn = newState.id === true;

        if ( turnedOn)
        {
            // user turned flexible week on
            // turn on and alternate week off (mutually exclusive)
            this.setState(prevState => ({alternateWeekdaysOpen: false, job: {...prevState.job, flexibleCalendarWeek: newState}}));
        }
        else
            this.setState(prevState => ({job: {...prevState.job, flexibleCalendarWeek: newState}}));
    }

    toggleAlternateWeekdaysOpen() {

        // this.setState({ alternateWeekdaysOpen: !this.state.alternateWeekdaysOpen })

        var newState = !this.state.alternateWeekdaysOpen;
        var turnedOn = newState;

        var valFalse = this.retrieveBoolDecisions()[0];

        if ( turnedOn)
        {
            // user turned flexible week on
            // turn on and alternate week off (mutually exclusive)
            this.setState(prevState => ({alternateWeekdaysOpen: newState, job: {...prevState.job, flexibleCalendarWeek: valFalse}}));
        }
        else
            this.setState(prevState => ({alternateWeekdaysOpen: newState}));
    }

    retrieveLanguages() {
        if (this.languages) return this.languages;

        this.languages = ConfigController.retrieveLanguages();
        return this.languages;
    }

    retrieveCashierSystems() {
        if (this.cashierSystems) return this.cashierSystems;

        this.cashierSystems = ConfigController.retrieveCashierSystems().map(x => { return { id: x.id, item: x.item, category: x.category } });
        return this.cashierSystems;
    }

    retrieveCustomerSkills() {
        if (this.customerSkills) return this.customerSkills;

        this.customerSkills = ConfigController.retrieveCustomerSkills();
        return this.customerSkills;
    }

    retrieveManufacturSkills() {
        if (this.manufacturSkills) return this.manufacturSkills;

        this.manufacturSkills = ConfigController.retrieveManufacturSkills();
        return this.manufacturSkills;
    }

    retrieveLogisticSkills() {
        if (this.logisticSkills) return this.logisticSkills;

        this.logisticSkills = ConfigController.retrieveLogisticSkills();
        return this.logisticSkills;
    }

    retrieveAdminSkills() {
        if (this.adminSkills) return this.adminSkills;

        this.adminSkills = ConfigController.retrieveAdminSkills();
        return this.adminSkills;
    }

    retrieveMarketingSkills() {
        if (this.marketingSkills) return this.marketingSkills;

        this.marketingSkills = ConfigController.retrieveMarketingSkills();
        return this.marketingSkills;
    }

    selectItem(name, value, status) {
        let currentSelection = Object.assign({}, this.state.selection);
        let currentSelected = currentSelection.selected;

        if (status)
            currentSelected[name][value.id] = value;
        else
            delete currentSelected[name][value.id];

        this.setState({ selection: currentSelection });
    }

    toggleOther(name, status) {
        let currentSelection = Object.assign({}, this.state.selection);

        currentSelection.other[name] = status;
        this.setState({ selection: currentSelection });
    }

    changeOther(name, value) {
        let currentSelection = Object.assign({}, this.state.selection);

        currentSelection.otherText[name] = value;
        this.setState({ selection: currentSelection });
    }

    changeRemarks(value) {

        // console.log(`changeRemarks::value = ${value}`)
        this.state.job.remarks = value;
        this.setState({ job: this.state.job });
    }

    buildMap(name, items, countryName) {
        let id = Math.random();

        var isPharmacyEdition = ConfigController.isPharmacyEdition();
        var isHealthcareEdition = ConfigController.isHealthcareEdition();

        return (
            <div>
                {/* <h4 className="mb-2 text-center">{_(`text.temp.${name}`)}</h4> */}
                {isPharmacyEdition && 
                    <h4 className="mb-2 text-center">{_(`text.temp.${name}`)} {countryName}</h4>
                }
                {isHealthcareEdition && 
                    <h4 className="mb-2 text-center">{_(`text.temp.health.${name}`)} {countryName}</h4>
                }
                {items.map(x => (
                    <div key={x.id} className="custom-control custom-checkbox mb-3">
                        <input className="custom-control-input" id={x.id} type="checkbox" onChange={(e) => this.selectItem(name, x, e.target.checked)} checked={this.state.selection.selected[name][x.id]} />
                        <label className="custom-control-label noselect side-by-side" htmlFor={x.id}>
                            {_(x.item)}
                            {x.category == "CommissioningSystem" && <span className="apo-badge-system ml-2">{_('cashierSystems.commissioningSystem')}</span>}
                        </label>
                    </div>
                ))}
                <div className={`custom-control custom-checkbox ${this.state.selection.other[name] ? "mb-1" : "mb-3"}`}>
                    <input className="custom-control-input" id={id} type="checkbox" onChange={(e) => this.toggleOther(name, e.target.checked)} checked={this.state.selection.other[name]} />
                    <label className="custom-control-label noselect" htmlFor={id}>{_("other")}</label>
                </div>
                {this.state.selection.other[name] && <InputComponent className="mb-2" placeholder={_("other")} type="text" value={this.state.selection.otherText[name]} onChange={(e) => this.changeOther(name, e.target.value)} />}
            </div>
        );
    }

    //
    // CS 29/4/22: special buildMap version for health care edition admin skills (e.g. Krages (Methox)) which
    // * uses different label translation [text.temp.health]
    // * shows the OTHER input field right away without label and w/o the need to toggle
    // * removed special handling for CommissioningSystem which only exist for pharmacy edition
    //
    buildHealthcareAdminMap(name, items, countryName) {
        let id = Math.random();

        return (
            <div>
                {/* <h4 className="mb-2 text-center">{_(`text.temp.${name}`)}</h4> */}
                <h4 className="mb-2 text-center">{_(`text.temp.health.${name}`)} {countryName}</h4>
                {items.map(x => (
                    <div key={x.id} className="custom-control custom-checkbox mb-3">
                        <input className="custom-control-input" id={x.id} type="checkbox" onChange={(e) => this.selectItem(name, x, e.target.checked)} checked={this.state.selection.selected[name][x.id]} />
                        <label className="custom-control-label noselect side-by-side" htmlFor={x.id}>
                            {_(x.item)}
                        </label>
                    </div>
                ))}
                <InputComponent className="mb-2" placeholder={_("other")} type="text" value={this.state.selection.otherText[name]} onChange={(e) => this.changeOther(name, e.target.value)}/>
            </div>
        );
    }

    buildMapForeignCashierSystems(title, items) {
        let name = "cashierSystem";
        return (
            <div>
                <h4 className="mb-2 text-center">{title}</h4>
                {items.map(x => (
                    <div key={x.id} className="custom-control custom-checkbox mb-3">
                        <input className="custom-control-input" id={x.id} type="checkbox" onChange={(e) => this.selectItem(name, x, e.target.checked)} checked={this.state.selection.selected[name][x.id]} />
                        <label className="custom-control-label noselect side-by-side" htmlFor={x.id}>
                            {_(x.item)}
                            {x.category == "CommissioningSystem" && <span className="apo-badge-system ml-2">{_('cashierSystems.commissioningSystem')}</span>}
                        </label>
                    </div>
                ))}
            </div>
        );
    }

    renderOtherCashierSystems() {
        let systems = ConfigController.retrieveCashierSystemLanguages();
        //return systems.map(x => this.buildMapCashierSystems(_("nationalities." + x), ConfigController.retrieveCashierSystemsOther(x).map(x => { return { id: x.id, item: x.item.split("|")[0] } })));
        return systems.map(x => this.buildMapForeignCashierSystems(_("nationalities." + x), ConfigController.retrieveCashierSystemsOther(x).map(x => { return { id: x.id, item: x.item, category: x.category } })));
    }

    retrieveDistanceNormalized() {
        if (!this.state.job.distance || this.state.job.distance <= 0) {
            return 0;
        }

        for (let i = 0; i <= 32; i++) {
            if ((Math.ceil((i * i) / 5) * 5) == this.state.job.distance) {
                return i;
            }
        }

        return 32;
    }

    // CS 9/2/22: bound event to new ProfileTimePeriodsComponent
    onChangeAvailability(data){
        let newJob = Object.assign({}, this.state.job);
        newJob.availabilities = data ?? [];
        this.setState({job: newJob})
        // console.table("data: ", data)
        // console.table("availabilities: ", newJob.availabilities.length)
    }

    renderForPharmacyEdition() {

        var isPerson = this.state.isPerson;
        var isPharmacist = !isPerson;

        // flag if the selected job type is pharmacist
        var selectedPharmacist = this.state.job.jobType.code == "jobTypes.pharmacist";
        //console.log(`selectedPharmacist = ${selectedPharmacist}`)
        // if not pharmacist - reset the two pharmacist flags
        if ( !selectedPharmacist)
        {
            this.state.job.management = false;
            this.state.job.licenceHolder = false;
        }

        // default FULL DAY shift 
        var fullDayWork = this.retrieveShifts()[1];
        var notAvailableForWork = this.retrieveShifts()[0];

        // get the proper text for the linda flag (depends on if user is job-seeker or pharmacist)
        var lindaFlagText = isPerson ? _("placeholder.lindaExperience") : _("placeholder.lindaMember");
        var showLindaFlag = this.state.job.postalCountryCode.toUpperCase() == "DE";

        return (
            <CenteredComponent size={9} className="animated fadeIn">
                <div className="my-3">
                    <Formik onSubmit={this.onSubmit.bind(this)}
                        render={(props) => (
                            <Form noValidate className="col-12 mb-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>

                                <AccordionItemComponent title={_("text.profile.jobType")} show>
                                    <FormGroup className="mb-2">
                                        <SelectInputComponent
                                            onSelect={this.selectJobType.bind(this)}
                                            onFilter={optionFilter(this.retrieveJobTypes())}
                                            submitted={this.state.submitted}
                                            placeholder={_(`placeholder.jobTypes.${this.state.type}`)}
                                            default={this.retrieveJobType()} />
                                    </FormGroup>

                                    <FormGroup className="py-2 mb-0 noselect">
                                        <Slider className="mb-1" min={0} max={32} step={1} onChange={this.selectDistance.bind(this)}
                                            defaultValue={this.retrieveDistanceNormalized()} />
                                        <h6 className="text-left mb-0">{_("distance.radius")} {this.retrieveDistance()}</h6>
                                    </FormGroup>
                                </AccordionItemComponent>

                                {/* AVAILABILITY SECTION including weekdays, alternate weekdays, availability time periods */}
                                <AccordionItemComponent title={_("text.profile.availability")}>
                                    {/* Job type, Area, available in */}
                                    <div className="two-columns-grid">
                                        {/* CS: 3/9/21 - adjusted layout from 3 sept request */}
                                        {/* CS: 19/10/21 - adjusted layout - using dynamic grid with media adjustment */}
                                        <FormGroup className="noselect">
                                            <Slider className="mb-1"
                                                min={this.retrieveJobType().minimumHoursPerWeek == this.retrieveJobType().maximumHoursPerWeek ? 0 : this.retrieveJobType().minimumHoursPerWeek}
                                                max={this.retrieveJobType().maximumHoursPerWeek}
                                                disabled={this.retrieveJobType().maximumHoursPerWeek == this.retrieveJobType().minimumHoursPerWeek}
                                                defaultValue={this.state.job.hoursPerWeek || this.retrieveJobType().maximumHoursPerWeek}
                                                step={1}
                                                onChange={this.selectHoursPerWeek.bind(this)} />

                                            <h6 className="text-left mb-0">{_("text.hoursPerWeek")}: {this.retrieveHoursPerWeek()}</h6>
                                        </FormGroup>

                                        <FormGroup className="noselect">
                                            <Slider className="mb-1" min={0} max={24} step={1} onChange={this.selectAvailableIn.bind(this)}
                                                defaultValue={this.state.job.availableIn || 0} />
                                            <h6 className="text-left mb-0">{_("text.availableIn")}: {this.retrieveAvailableIn()}</h6>
                                        </FormGroup>
                                    </div>

                                    {/* Standard Weekdays */}
                                    <div key={this.retrieveJobType().id} className="two-columns-grid">

                                        <span className='span-grid-row title'>{_("placeholder.titleWeekdays")}</span>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectMonday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.monday")}
                                                default={this.state.job.monday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectTuesday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.tuesday")}
                                                default={this.state.job.tuesday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectWednesday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.wednesday")}
                                                default={this.state.job.wednesday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectThursday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.thursday")}
                                                default={this.state.job.thursday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectFriday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.friday")}
                                                default={this.state.job.friday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectSaturday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.saturday")}
                                                default={this.state.job.saturday || fullDayWork} />
                                        </FormGroup>
                                        {/* SUNDAY not for PHARMACY edition */}
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectSunday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.sunday")}
                                                default={this.state.job.sunday || notAvailableForWork} />
                                        </FormGroup>

                                    </div> {/* div.two-columns */}
                                    
                                    {/* CS:24/1/22 ALTERNATE WEEK TOGGLE */}
                                    <div className="two-columns-grid" style={{padding: ".2em 0"}}>
                                        <span className='span-grid-row title left-right-spaced'>
                                            {/* FLEXIBLE WEEK only for PHARMACY */}
                                            {ConfigController.isPharmacyEdition() == true && 
                                                <FormGroup className="m-0 mb-1 row">
                                                    <label className="custom-toggle my-auto">
                                                        <input type="checkbox" checked={this.state.job.flexibleCalendarWeek.id === true} 
                                                                onChange={this.toggleFlexibleCalenderWeek.bind(this)} />
                                                        <span className="custom-toggle-slider rounded-circle"></span>
                                                    </label>
                                                    <h5 className="col-auto m-0">{_("placeholder.titleFlexibleCalendarWeek")}</h5>
                                                </FormGroup>
                                            }
                                            <FormGroup className="m-0 mb-1 row">
                                                <label className="custom-toggle my-auto">
                                                    <input  type="checkbox" checked={this.state.alternateWeekdaysOpen} 
                                                            //onChange={() => this.setState({ alternateWeekdaysOpen: !this.state.alternateWeekdaysOpen })} />
                                                            onChange={this.toggleAlternateWeekdaysOpen.bind(this)} />
                                                    <span className="custom-toggle-slider rounded-circle"></span>
                                                </label>
                                                <h5 className="col-auto m-0">{_("placeholder.titleAlternateWeekdays")}</h5>
                                            </FormGroup>
                                        </span>
                                    </div>

                                    {/* { this.state.alternateWeekdaysOpen && <div className="display-contents"> */}
                                    { this.state.alternateWeekdaysOpen && <div key={this.retrieveJobType().id+1} className="two-columns-grid">

                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectAlternateMonday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.altMonday")}
                                                // default={this.state.job.altMonday || fullDayWork}
                                                // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                                value={this.state.job.altMonday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectAlternateTuesday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.altTuesday")}
                                                // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                                value={this.state.job.altTuesday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectAlternateWednesday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.altWednesday")}
                                                // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                                value={this.state.job.altWednesday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectAlternateThursday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.altThursday")}
                                                // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                                value={this.state.job.altThursday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectAlternateFriday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.altFriday")}
                                                // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                                value={this.state.job.altFriday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectAlternateSaturday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.altSaturday")}
                                                // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                                value={this.state.job.altSaturday || fullDayWork} />
                                        </FormGroup>

                                    </div> }

                                    {/* CS:25/1/22 OTHER questions */}
                                    <div key={this.retrieveJobType().id+2} className="two-columns-grid">

                                        {/* <span className='span-grid-row title'>{_("placeholder.titleOtherAvailability")}</span> */}

                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectNightlyShift.bind(this)}
                                                onFilter={optionFilter(this.retrieveBoolDecisions())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().suitableForNightlyShifts}
                                                placeholder={_("placeholder.nightlyShifts")}
                                                default={this.state.job.nightlyShifts || this.retrieveBoolDecisions()[0]} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectReplacement.bind(this)}
                                                onFilter={optionFilter(this.retrieveBoolDecisions())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().suitableForReplacments}
                                                placeholder={_("placeholder.replacement")}
                                                default={this.state.job.replacement || this.retrieveBoolDecisions()[0]} />
                                        </FormGroup>

                                        {/* AVAILABILITY TIME PERIODS - only if temp job availability has been selected */}
                                        {/* CS 9/2/22: added new component for handling new featured availability time periods */}
                                        {this.state.job.replacement.id == true && 
                                            <div className="span-grid-row my-2">
                                                <ProfileAvailabilityComponent data={this.state.job.availabilities} onChange={this.onChangeAvailability.bind(this)} ></ProfileAvailabilityComponent>
                                            </div>
                                        }

                                        {/* OVERPAY */}
                                        {/* CS 16/10/21: only show for pharmacists */}
                                        { isPharmacist && 
                                            <FormGroup>
                                                <SelectInputComponent
                                                    onSelect={this.selectOverpay.bind(this)}
                                                    onFilter={optionFilter(this.retrieveBoolDecisions())}
                                                    submitted={this.state.submitted}
                                                    // disabled={!this.retrieveJobType().suitableForNightlyShifts}
                                                    placeholder={_("placeholder.overpay")}
                                                    default={this.state.job.availableForOverpay || this.retrieveBoolDecisions()[0]} />
                                            </FormGroup>
                                        }
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectAccomodation.bind(this)}
                                                onFilter={optionFilter(this.retrieveBoolDecisions())}
                                                submitted={this.state.submitted}
                                                // disabled={!this.retrieveJobType().suitableForReplacments}
                                                placeholder={_("placeholder.accomodation")}
                                                default={this.state.job.accomodation || this.retrieveBoolDecisions()[0]} />
                                        </FormGroup>
                                        {/* CS 24/9/21: added two new properties Management and LicenceHolder */}
                                        {/* CS 4/10/21: only for pharmacists */}
                                        { selectedPharmacist && 
                                            <FormGroup>
                                                <SelectInputComponent
                                                    onSelect={this.selectManagement.bind(this)}
                                                    onFilter={optionFilter(this.retrieveBoolDecisions())}
                                                    submitted={this.state.submitted}
                                                    // disabled={!this.retrieveJobType().suitableForNightlyShifts}
                                                    placeholder={_("placeholder.management")}
                                                    default={this.state.job.management || this.retrieveBoolDecisions()[0]} />
                                            </FormGroup>
                                        }
                                        { selectedPharmacist && 
                                            <FormGroup>
                                                <SelectInputComponent
                                                    onSelect={this.selectLicenceHolder.bind(this)}
                                                    onFilter={optionFilter(this.retrieveBoolDecisions())}
                                                    submitted={this.state.submitted}
                                                    // disabled={!this.retrieveJobType().suitableForReplacments}
                                                    placeholder={_("placeholder.licenceHolder")}
                                                    default={this.state.job.licenceHolder || this.retrieveBoolDecisions()[0]} />
                                            </FormGroup>
                                        }
                                        {/* CS 15/10/21: added new field CovidTester */}
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectCovidTester.bind(this)}
                                                onFilter={optionFilter(this.retrieveBoolDecisions())}
                                                submitted={this.state.submitted}
                                                // disabled={!this.retrieveJobType().suitableForNightlyShifts}
                                                placeholder={_("placeholder.covidTester")}
                                                default={this.state.job.covidTester || this.retrieveBoolDecisions()[0]} />
                                        </FormGroup>

                                    </div> {/* div.two-columns */}


                                </AccordionItemComponent>

                                <AccordionItemComponent title={_("text.profile.knowledge")}>
                                    <div className="row m-0 text-left">
                                        <div className="col-lg-6">
                                            {this.buildMap("language", this.retrieveLanguages())}
                                        </div>
                                        <div className="col-lg-6">
                                            {this.buildMap("cashierSystem", this.retrieveCashierSystems(), this.state.homeCountryName)}
                                            <AccordionItemComponent title={_(`text.otherCashierSystems`)}>
                                                {this.renderOtherCashierSystems()}
                                            </AccordionItemComponent>
                                        </div>
                                    </div>
                                </AccordionItemComponent>

                                <AccordionItemComponent title={_("text.profile.skills")}>
                                    <div className="row m-0 text-left">
                                        <div className="col-lg-6">
                                            {this.buildMap("customer", this.retrieveCustomerSkills())}
                                            {this.buildMap("admin", this.retrieveAdminSkills())}
                                        </div>
                                        <div className="col-lg-6">
                                            {this.buildMap("manufactur", this.retrieveManufacturSkills())}
                                            {this.buildMap("logistic", this.retrieveLogisticSkills())}
                                            {this.buildMap("marketing", this.retrieveMarketingSkills())}
                                        </div>
                                    </div>
                                </AccordionItemComponent>

                                {/* CS 11/7/22: placed new location for new linda flag */}
                                {showLindaFlag && 
                                    <FormGroup>
                                        <div className="linda-selector mt-4">

                                            <img src="/assets/images/linda-logo.svg" alt="" />

                                            <SelectInputComponent
                                                onSelect={this.selectLindaFlag.bind(this)}
                                                onFilter={optionFilter(this.retrieveBoolDecisions())}
                                                submitted={this.state.submitted}
                                                disabled={false}
                                                placeholder={lindaFlagText}
                                                default={this.state.job.lindaFlag || this.retrieveBoolDecisions()[0]} />

                                        </div>
                                    </FormGroup>
                                }

                                {/* CS 28/9/21: REMARKS */}
                                {/* CS 28/9/21: added remarks to the profile model */}
                                <div className="form-group">
                                    <label for="txtRemarks">{_("remarks")}</label>
                                    <textarea id="txtRemarks" className="form-control my-2" placeholder={_("remarks")} rows="6" onChange={(e) => this.changeRemarks(e.target.value)}>
                                        {this.state.job.remarks}
                                    </textarea>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <Button className="col-12" color="danger" onClick={this.onBack.bind(this)}><i className="fas fa-angle-left"></i> {_("back")}</Button>
                                    </div>
                                    <div className="col-md-6 mb-2 order-first order-md-2 text-right">
                                        <Button type="submit" className="col-12" color="success">{_("save")} <i className="fas fa-angle-right"></i></Button>
                                    </div>
                                </div>

                            </Form>
                        )} />

                </div>
            </CenteredComponent>
        );
    }

    renderForHealthcareEdition() {

        this.state.job.management = false;
        this.state.job.licenceHolder = false;

        // default FULL DAY shift 
        var fullDayWork = this.retrieveShifts()[1];
        var notAvailableForWork = this.retrieveShifts()[0];

        return (
            <CenteredComponent size={9} className="animated fadeIn">
                <div className="my-3">
                    <Formik onSubmit={this.onSubmit.bind(this)}
                        render={(props) => (
                            <Form noValidate className="col-12 mb-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>

                                <AccordionItemComponent title={_("text.profile.jobType")} show>
                                    <FormGroup className="mb-2">
                                        <SelectInputComponent
                                            onSelect={this.selectJobType.bind(this)}
                                            onFilter={optionFilter(this.retrieveJobTypes())}
                                            submitted={this.state.submitted}
                                            placeholder={_(`placeholder.jobTypes.${this.state.type}`)}
                                            default={this.retrieveJobType()} />
                                    </FormGroup>

                                    <FormGroup className="py-2 mb-0 noselect">
                                        <Slider className="mb-1" min={0} max={32} step={1} onChange={this.selectDistance.bind(this)}
                                            defaultValue={this.retrieveDistanceNormalized()} />
                                        <h6 className="text-left mb-0">{_("distance.radius")} {this.retrieveDistance()}</h6>
                                    </FormGroup>
                                </AccordionItemComponent>

                                {/* AVAILABILITY SECTION including weekdays, alternate weekdays, availability time periods */}
                                <AccordionItemComponent title={_("text.profile.availability")}>
                                    {/* Job type, Area, available in */}
                                    <div className="two-columns-grid">
                                        {/* CS: 3/9/21 - adjusted layout from 3 sept request */}
                                        {/* CS: 19/10/21 - adjusted layout - using dynamic grid with media adjustment */}
                                        <FormGroup className="noselect">
                                            <Slider className="mb-1"
                                                min={this.retrieveJobType().minimumHoursPerWeek == this.retrieveJobType().maximumHoursPerWeek ? 0 : this.retrieveJobType().minimumHoursPerWeek}
                                                max={this.retrieveJobType().maximumHoursPerWeek}
                                                disabled={this.retrieveJobType().maximumHoursPerWeek == this.retrieveJobType().minimumHoursPerWeek}
                                                defaultValue={this.state.job.hoursPerWeek || this.retrieveJobType().maximumHoursPerWeek}
                                                step={1}
                                                onChange={this.selectHoursPerWeek.bind(this)} />

                                            <h6 className="text-left mb-0">{_("text.hoursPerWeek")}: {this.retrieveHoursPerWeek()}</h6>
                                        </FormGroup>

                                        <FormGroup className="noselect">
                                            <Slider className="mb-1" min={0} max={24} step={1} onChange={this.selectAvailableIn.bind(this)}
                                                defaultValue={this.state.job.availableIn || 0} />
                                            <h6 className="text-left mb-0">{_("text.availableIn")}: {this.retrieveAvailableIn()}</h6>
                                        </FormGroup>
                                    </div>

                                    {/* Standard Weekdays */}
                                    <div key={this.retrieveJobType().id} className="two-columns-grid">

                                        <span className='span-grid-row title'>{_("placeholder.titleWeekdays")}</span>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectMonday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.monday")}
                                                default={this.state.job.monday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectTuesday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.tuesday")}
                                                default={this.state.job.tuesday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectWednesday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.wednesday")}
                                                default={this.state.job.wednesday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectThursday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.thursday")}
                                                default={this.state.job.thursday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectFriday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.friday")}
                                                default={this.state.job.friday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectSaturday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.saturday")}
                                                default={this.state.job.saturday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectSunday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.sunday")}
                                                default={this.state.job.sunday || notAvailableForWork} />
                                        </FormGroup>

                                    </div> {/* div.two-columns */}
                                    
                                    {/* CS:24/1/22 ALTERNATE WEEK TOGGLE */}
                                    <div className="two-columns-grid" style={{padding: ".2em 0"}}>
                                        <span className='span-grid-row title'>
                                            <FormGroup className="m-0 mb-1 row">
                                                <label className="custom-toggle my-auto">
                                                    <input  type="checkbox" checked={this.state.alternateWeekdaysOpen} 
                                                            onChange={() => this.setState({ alternateWeekdaysOpen: !this.state.alternateWeekdaysOpen })} />
                                                    <span className="custom-toggle-slider rounded-circle"></span>
                                                </label>
                                                <h5 className="col-auto m-0">{_("placeholder.titleAlternateWeekdays")}</h5>
                                            </FormGroup>
                                        </span>
                                    </div>

                                    {/* { this.state.alternateWeekdaysOpen && <div className="display-contents"> */}
                                    { this.state.alternateWeekdaysOpen && <div key={this.retrieveJobType().id+1} className="two-columns-grid">

                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectAlternateMonday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.altMonday")}
                                                // default={this.state.job.altMonday || fullDayWork}
                                                // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                                value={this.state.job.altMonday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectAlternateTuesday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.altTuesday")}
                                                // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                                value={this.state.job.altTuesday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectAlternateWednesday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.altWednesday")}
                                                // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                                value={this.state.job.altWednesday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectAlternateThursday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.altThursday")}
                                                // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                                value={this.state.job.altThursday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectAlternateFriday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.altFriday")}
                                                // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                                value={this.state.job.altFriday || fullDayWork} />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectAlternateSaturday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.altSaturday")}
                                                // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                                value={this.state.job.altSaturday || fullDayWork} />
                                        </FormGroup>
                                        {/* NO ALTERNATIVE SUNDAY PHARMACY edition */}
                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectAlternateSunday.bind(this)}
                                                onFilter={optionFilter(this.retrieveShifts())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().canAdjustShifts}
                                                placeholder={_("placeholder.altSunday")}
                                                // CS 25/1/22: the new props VALUE is live and reflects changes, default doesn't
                                                value={this.state.job.altSunday || notAvailableForWork} />
                                        </FormGroup>                                            

                                    </div> }

                                    {/* CS:25/1/22 OTHER questions */}
                                    <div key={this.retrieveJobType().id+2} className="two-columns-grid">

                                        {/* <span className='span-grid-row title'>{_("placeholder.titleOtherAvailability")}</span> */}

                                        <FormGroup>
                                            <SelectInputComponent
                                                onSelect={this.selectReplacement.bind(this)}
                                                onFilter={optionFilter(this.retrieveBoolDecisions())}
                                                submitted={this.state.submitted}
                                                disabled={!this.retrieveJobType().suitableForReplacments}
                                                placeholder={_("placeholder.health.replacement")}
                                                default={this.state.job.replacement || this.retrieveBoolDecisions()[0]} />
                                        </FormGroup>

                                        {/* AVAILABILITY TIME PERIODS - only if temp job availability has been selected */}
                                        {/* CS 9/2/22: added new component for handling new featured availability time periods */}
                                        {this.state.job.replacement.id == true && 
                                            <div className="span-grid-row my-2">
                                                <ProfileAvailabilityComponent data={this.state.job.availabilities} onChange={this.onChangeAvailability.bind(this)} ></ProfileAvailabilityComponent>
                                            </div>
                                        }

                                    </div> {/* div.two-columns */}


                                </AccordionItemComponent>

                                <AccordionItemComponent title={_("text.profile.knowledge")}>
                                    <div className="row m-0 text-left">
                                        <div className="col-lg-6">
                                            {this.buildMap("language", this.retrieveLanguages())}
                                        </div>
                                        <div className="col-lg-6">
                                            {this.buildMap("customer", this.retrieveCustomerSkills())}
                                        </div>
                                        <div className="col-lg-6">
                                            {this.buildHealthcareAdminMap("admin", this.retrieveAdminSkills())}
                                        </div>

                                    </div>
                                </AccordionItemComponent>

                                <div className="form-group">
                                    <label for="txtRemarks">{_("health.remarks")}</label>
                                    <textarea id="txtRemarks" className="form-control my-2" placeholder={_("health.remarks")} rows="6" onChange={(e) => this.changeRemarks(e.target.value)}>
                                        {this.state.job.remarks}
                                    </textarea>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <Button className="col-12" color="danger" onClick={this.onBack.bind(this)}><i className="fas fa-angle-left"></i> {_("back")}</Button>
                                    </div>
                                    <div className="col-md-6 mb-2 order-first order-md-2 text-right">
                                        <Button type="submit" className="col-12" color="success">{_("save")} <i className="fas fa-angle-right"></i></Button>
                                    </div>
                                </div>

                            </Form>
                        )} />

                </div>
            </CenteredComponent>
        );
    }

    render(){

        if (ConfigController.isPharmacyEdition()) return this.renderForPharmacyEdition();
        if (ConfigController.isHealthcareEdition()) return this.renderForHealthcareEdition();

        return(
            <h1>!!! UNKNOWN EDITION !!!</h1>
        )

    }

}

export default withRouter(ManageProfileComponent);

export var mapToCommand = (data) => {
    return {
        /* leaving postalAreaId from data will not overwrite the existing value */
        /* postalAreaId: data.postalArea.id, */
        jobId: data.job.jobType.id,
        maximumDistancetoWork: Math.min(1000, data.job.distance),
        hoursPerWeek: data.job.hoursPerWeek,
        availableInWeeks: data.job.availableIn,
        availableForNightlyShifts: data.job.nightlyShift.id,
        availableAsReplacement: data.job.replacement.id,
        availableForOverpay: data.job.availableForOverpay.id,
        accomodation: data.job.accomodation.id,
        management: data.job.management.id,
        licenceHolder: data.job.licenceHolder.id,
        covidTester: data.job.covidTester.id,
        lindaFlag: data.job.lindaFlag.id,
        flexibleCalendarWeek: data.job.flexibleCalendarWeek.id,
        remarks: data.job.remarks,
        otherLanguage: data.selection.otherText.language || "",
        otherCashierSystem: data.selection.otherText.cashierSystem || "",
        otherAdminSkill: data.selection.otherText.admin || "",
        otherCustomerSkill: data.selection.otherText.customer || "",
        otherLogisticSkill: data.selection.otherText.logistic || "",
        otherManufacturSkill: data.selection.otherText.manufactur || "",
        otherMarketingSkill: data.selection.otherText.marketing || "",
        shifts: [
            { dayOfWeek: 0, shiftId: data.job.monday.id }, { dayOfWeek: 1, shiftId: data.job.tuesday.id },
            { dayOfWeek: 2, shiftId: data.job.wednesday.id }, { dayOfWeek: 3, shiftId: data.job.thursday.id },
            { dayOfWeek: 4, shiftId: data.job.friday.id }, { dayOfWeek: 5, shiftId: data.job.saturday.id },
            { dayOfWeek: 6, shiftId: data.job.sunday.id },
            // alternate weekdays
            { dayOfWeek: 20, shiftId: data.job.altMonday.id }, { dayOfWeek: 21, shiftId: data.job.altTuesday.id },
            { dayOfWeek: 22, shiftId: data.job.altWednesday.id }, { dayOfWeek: 23, shiftId: data.job.altThursday.id },
            { dayOfWeek: 24, shiftId: data.job.altFriday.id }, { dayOfWeek: 25, shiftId: data.job.altSaturday.id },
            { dayOfWeek: 26, shiftId: data.job.altSunday.id }
        ],
        languages: Object.keys(data.selection.selected.language || {}),
        cashierSystems: Object.keys(data.selection.selected.cashierSystem || {}),
        adminSkills: Object.keys(data.selection.selected.admin || {}),
        customerSkills: Object.keys(data.selection.selected.customer || {}),
        logisticSkills: Object.keys(data.selection.selected.logistic || {}),
        manufacturSkills: Object.keys(data.selection.selected.manufactur || {}),
        marketingSkills: Object.keys(data.selection.selected.marketing || {}),

        // CS 9/2/22: added new feature set of time periods per profile
        availabilities: data.job.availabilities || []
    };
}

const mappers = {

    mapJobType(id) {
        return ConfigController.retrieveJobTypes().filter(x => x.id === id)[0];
    },

    mapDecision(id) {
        return { id: id, item: _(id.toString()) };
    },

    mapShift(id) {
        return ConfigController.retrieveShifts().filter(x => x.id === id)[0];
    },

    mapDayOfWeek(id) {

        // CS 25/1/22: if values at 20 or above the weekdays stand for alternate weekdays (20 - alternate Monday, 26 - alternate Sunday)
        if ( id >= 20 ) return ["altMonday", "altTuesday", "altWednesday", "altThursday", "altFriday", "altSaturday", "altSunday"][id-20];
        return ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"][id];
    },

    mapSelection(list, mapper) {
        let result = {};

        list.forEach(element => {
            result[element] = mapper(element);
        });

        return result;
    },

    mapLanguage(id) {
        return ConfigController.retrieveLanguages().filter(x => x.id === id)[0];
    },

    mapCashierSystem(id) {
        return ConfigController.retrieveCashierSystemsAll().filter(x => x.id === id)[0];
    },

    mapAdminSkill(id) {
        return ConfigController.retrieveAdminSkills().filter(x => x.id === id)[0];
    },

    mapCustomerSkill(id) {
        return ConfigController.retrieveCustomerSkills().filter(x => x.id === id)[0];
    },

    mapLogisticSkill(id) {
        return ConfigController.retrieveLogisticSkills().filter(x => x.id === id)[0];
    },

    mapManufacturSkill(id) {
        return ConfigController.retrieveManufacturSkills().filter(x => x.id === id)[0];
    },

    mapMarketingSkill(id) {
        return ConfigController.retrieveMarketingSkills().filter(x => x.id === id)[0];
    }

};

export var mapFromCommand = (data) => {

    let job = {
        // CS 23/11/22 added missing important ID of the job
        ID: data.jobID,
        jobType: mappers.mapJobType(data.jobID),
        // CS 25/3/22: expanded postal information due to new API changes so we have info per profile, not like before only per account
        postalCode: data.postalCode,
        postalCountryCode: data.postalCountryCode,
        postalName: data.postalName,
        distance: data.maximumDistanceToWork,
        //availabilities: data.availabilities.map(x => { return { periodStart: x.periodStart, periodEnd: x.periodEnd } }),
        availabilities: data.availabilities,
        hoursPerWeek: data.hoursPerWeek,
        availableIn: data.availableInWeeks,
        nightlyShift: mappers.mapDecision(data.availableForNightlyShifts),
        replacement: mappers.mapDecision(data.availableAsReplacement),
        availableForOverpay: mappers.mapDecision(data.availableForOverpay),
        accomodation: mappers.mapDecision(data.accomodation),
        management: mappers.mapDecision(data.management),
        licenceHolder: mappers.mapDecision(data.licenceHolder),
        covidTester: mappers.mapDecision(data.covidTester),
        lindaFlag: mappers.mapDecision(data.lindaFlag),
        flexibleCalendarWeek: mappers.mapDecision(data.flexibleCalendarWeek),
        remarks: data.remarks
    };

    data.shifts.forEach(element => {
        job[mappers.mapDayOfWeek(element.dayOfWeek)] = mappers.mapShift(element.shiftID);
    });

    let selection = {
        other: {
            language: (data.otherLanguage || "").trim().length > 0,
            cashierSystem: (data.otherCashierSystem || "").trim().length > 0,
            admin: (data.otherAdminSkill || "").trim().length > 0,
            customer: (data.otherCustomerSkill || "").trim().length > 0,
            logistic: (data.otherLogisticSkill || "").trim().length > 0,
            manufactur: (data.otherManufacturSkill || "").trim().length > 0,
            marketing: (data.otherMarketingSkill || "").trim().length > 0
        },
        otherText: {
            language: data.otherLanguage,
            cashierSystem: data.otherCashierSystem,
            admin: data.otherAdminSkill,
            customer: data.otherCustomerSkill,
            logistic: data.otherLogisticSkill,
            manufactur: data.otherManufacturSkill,
            marketing: data.otherMarketingSkill
        },
        selected: {
            language: mappers.mapSelection(data.languages, mappers.mapLanguage),
            cashierSystem: mappers.mapSelection(data.cashierSystems, mappers.mapCashierSystem),
            customer: mappers.mapSelection(data.customerSkills, mappers.mapCustomerSkill),
            manufactur: mappers.mapSelection(data.manufacturSkills, mappers.mapManufacturSkill),
            admin: mappers.mapSelection(data.adminSkills, mappers.mapAdminSkill),
            logistic: mappers.mapSelection(data.logisticSkills, mappers.mapLogisticSkill),
            marketing: mappers.mapSelection(data.marketingSkills, mappers.mapMarketingSkill),
        }
    };

    return { job, selection };
}