import React, { Component } from 'react';
import { FormGroup, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import SelectInputComponent, { optionFilter } from '../selectInputComponent';
import CenteredComponent from '../centeredComponent';
import { _ } from '../../../controllers/languageController';
import ConfigController from '../../../controllers/configController';
import ApiController from '../../../controllers/apiController';

class UpdateNationalityComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false
        };
    }

    hasWorkPermission(value) {
        this.setState({ canWork: value });
    }

    selectNationality(value) {
        this.setState({ nationality: value, canWork: value.ewr });
    }

    retrieveNationalities() {
        if (this.nationalities) {
            return this.nationalities;
        }

        this.nationalities = ConfigController.retrieveNationalities();
        return this.nationalities;
    }

    submit() {
        if (!this.state.submitted) {
            this.setState({ submitted: true });
        }

        if (!this.state.nationality || !this.state.canWork) {
            return;
        }

        ApiController.mandatoryChange(this.props.id, {
            nationalityID: this.state.nationality.id,
            hasWorkPermission: this.state.canWork
        }, this.props.onSubmit());
    }

    render() {
        return (
            <CenteredComponent size={12} className="animated fadeIn">
                <h1 className="mb-2">{_("text.updateNationality.header")}</h1>
                <h3 className="mb-4 offset-lg-2 col-lg-8">{_("text.updateNationality.subtitle")}</h3>
                <div className="offset-lg-3 col-lg-6 mb-0">

                    <FormGroup className="mb-2">
                        <SelectInputComponent
                            onSelect={this.selectNationality.bind(this)}
                            onFilter={optionFilter(this.retrieveNationalities())}
                            submitted={this.state.submitted}
                            placeholder={_("placeholder.nationality")} />
                    </FormGroup>

                    {this.state.nationality && !this.state.nationality.ewr && (
                        <div className="custom-control custom-checkbox mb-2 text-left">
                            <input className="custom-control-input" id="nationality" type="checkbox" onChange={(e) => this.hasWorkPermission(e.target.checked)} value={this.state.canWork} />
                            <label className={`custom-control-label noselect ${this.state.submitted && !this.state.canWork && "text-warning"}`} htmlFor="nationality">{_("hasWorkPermission")}</label>
                        </div>
                    )}

                    <div className="row">
                        <div className="offset-md-6 col-md-6 text-right">
                            <Button type="submit" className="col-12" color="success" onClick={this.submit.bind(this)}>{_("save")} <i className="fas fa-angle-right"></i></Button>
                        </div>
                    </div>
                </div>
            </CenteredComponent>
        );
    }

}

export default withRouter(UpdateNationalityComponent);