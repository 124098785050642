import { store } from 'react-notifications-component';
import { _ } from './languageController';
var AWS = require('aws-sdk');

const NotificationController = {

    push(type, message, title) {
        store.addNotification({
            title,
            message,
            type,
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
            }
        });
    },

    pushError(message, title) {
        this.push("danger", message, title || _("notification.error"));
    },

    pushSuccess(message, title) {
        this.push("success", message, title || _("notification.success"));
    },

    pushInfo(message, title) {
        this.push("info", message, title || _("notification.info"));
    },

    // CS September 2024
    // uses AWS-SNS to push a SMS message onto the users phone
    sendSMS(phone, message){

        // console.log(`notificationController.sendSMS('${phone}','${message}')`)

        // override with test number set in runtime-config.js
        if (window['runtimeConfig'].smsTestingWithSmsNumber) {
            phone = window['runtimeConfig'].smsTestingWithSmsNumber;
            console.log(`!!! SMS notification override ('${phone}','${message}')`)
        }

        AWS.config.update({
            accessKeyId: "AKIAT6R57SMGNFK2OMMR",
            secretAccessKey: "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",
            region:"eu-central-1"
          });        

          var params = {
            Message: message.toString(),
            PhoneNumber: phone,
            MessageAttributes: {
                'AWS.SNS.SMS.SenderID': {
                    'DataType': 'String',
                    'StringValue': 'APO'
                }
            }
        };
    
        var publishTextPromise = new AWS.SNS({ apiVersion: '2010-03-31' }).publish(params).promise();
    
        publishTextPromise.then(
            function (data) {
                // console.log(`Sent SMS response:${data.MessageId}`)
                // res.end(JSON.stringify({ MessageID: data.MessageId }));
            }).catch(function (err) {
                console.log(`Sent SMS error:${err}`)
                // res.end(JSON.stringify({ Error: err }));
            });
    
    },

    generateRandomNumber(digits) {

        if (digits < 1) {
          throw new Error("Number of digits must be at least 1");
        }
      
        // Generate the first digit (1-9)
        const firstDigit = Math.floor(Math.random() * 9) + 1;
      
        // Generate the remaining digits (0-9)
        let remainingDigits = '';
        for (let i = 1; i < digits; i++) {
          remainingDigits += Math.floor(Math.random() * 10);
        }
      
        // Combine the first digit with the remaining digits
        return Number(firstDigit + remainingDigits);
    }

}

export default NotificationController;